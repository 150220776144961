export default {
  generalError: 'An error has occurred',
  update: 'Update',
  showMore: 'Show more +',
  showLess: 'Show less -',
  markAs: 'Mark as',

  genericText: {},

  //Atoms
  avatarMatch: {
    match: 'Match'
  },

  regularButton: {
    apply: 'Apply'
  },

  regularSelect: {
    select: 'Select'
  },

  modalHeader: {
    planning: 'Planning'
  },

  //Molecules
  modalDeleteUser: {
    removeUser: 'Remove',
    enterCandidateName: 'To confirm enter the candidate name',
    writeCandidateName: 'Enter the candidate name',
    filterProfiles: 'Filter candidates',
    remove: 'Remove'
  },

  modalUpdateBlacklist: {
    addToBlacklist: 'Add to blacklist: ',
    removeFromBlacklist: 'Remove from blacklist: ',
    enterCandidateName: 'To confirm enter the candidate name',
    writeCandidateName: 'Enter the candidate name',
    filterProfiles: 'Filter candidates',
    add: 'Add',
    remove: 'Remove'
  },

  modalChangeStatus: {
    changeStatus: 'Change status',
    selectStatus: 'Select a status',
    select: 'Select',
    suitable: 'Eligible',
    not_suitable: 'Ineligible',
    to_be_confirmed: 'To be confirmed',
    confirmed: 'Confirmed',
    not_interested: 'Not interested',
    reschedule: 'Reschedule',
    hired: 'Hired',
    booking: 'On hold',
    not_attend: 'Not attend',
    cited_phase2: 'Scheduled stage 2',
    discarded: 'Rejected',
    descarded_phase2: 'Rejected stage 2',
    not_interested_phase2: 'Not interested stage 2',
    not_attend_phase2: 'Not attend stage 2',
    pending_update: 'Pending update',
    in_process: 'In process',
    hiredDate: 'Start date',
    in_progress: 'In progress',
    doc_required: 'Doc required',
    doc_received: 'Doc received',
  },

  modalChangeTags: {
    title: 'Change labels',
    select: 'Update',
  },

  favoritesTable: {
    date: 'Date',
    markAsRead: 'Mark as read',
    changeState: 'Change status',
    markFavorite: 'Marck favorite',
    removeFavorites: 'Remove favorites',
    fav: 'Favorite',
    export: 'Export',
    profile: 'Scoring profile',
    skills: 'Scoring skills',
    matching: 'Matching',
    status: 'Status',
    evaluation: 'Evaluation',
    personalData: 'Personal data'
  },

  favoritesProcessTable: {
    title: 'Process favourites'
  },

  favoritesOnboardingTable: {
    title: 'Onboarding favourites'
  },

  processesTable: {
    title: 'Inbox Processes',
    id: 'Process',
    name: 'Name and surname',
    prominent: 'Starred',
    date: 'Date and time',
    department: 'Department',
    category: 'Category',
    type: 'Type',
    contact: 'Email',
    tel: 'Phone',
    status: 'Status',
    markFavorite: 'Mark as favorite',
    removeFavorites: 'Remove as favorite',
    print: 'Print',
    markAsRead: 'Mark as read',
    unmarkAsRead: 'Unmark as read',
    filterProcesses: 'Filter processes',
    changeStatus: 'Change status',
    changeTags: 'Change tags',
    export: 'Export',
    all: 'All',
    offerCandidatureState: 'Interest',
    talent_acquisition: 'Selected candidatures',
    on_board: 'Selected employees',
    welcome:'Selected employees',
    employee_line:'Selected employees',
    feedback:'Selected employees',
    exit_interview:'Selected employeess',
    offer_sent: 'Offers sent',
  },

  offerModal: {
    title: 'Create push offer',
    name: 'Offer Name',
    name_placeholder: 'Delivery man London',
    description: 'Description',
    description_placeholder: 'We need a person who has 2 years of experience and with a vehicle for the London area',
    date_range: 'Offer start/end date',
    areUSure: {
      description: 'You are going to notify {num} person(s) about this offer, are you sure?',
      sendCompleted: 'Offer Sent'
    }
  },

  process: {
    title: 'Processes',
    category: 'Category',
    department: 'Department',
    service: 'Service',
    process: 'Process',
    comments: 'Comments',
    date: 'Date',
    time: 'Time',
    comment: 'Comments',
    author: 'Author',
    documentation: 'Files attached',
    previewCV: 'Candidate CV',
    downloadDocs: 'Download docs',
    deleteDocs: 'Delete selection',
    uploadDocs: 'Upload docs',
    createComment: 'Create comment',
    noPreview: 'Preview not disable',
    text: 'Text',
    commentName: 'Comment',
    dayHourTransaction: 'Transaction date and hour',
    area: 'Area',
    centerProvince: 'Province Center',
    centerType: 'Center Type',
    statesTypes: 'Suggestion by states',
    registerData: 'Registration date',
    noDocuments: 'No documents',
    modifiedStatus: 'Modified status',
    deleteSuccess: 'Process removed',
    deleteModalTitle: '¡Caution!',
    deleteModalText: 'Are you sure you want to delete this item?',
    remove: 'Delete'
  },

  exit_interview: {
    exit_reasons: "Motivos de salida",
    exit_factors: "Factores de salida",
    other_data: "Otros datos",
    position: "Puesto",
    leaving_date: "Fecha de salida",
    male_or_female: "Género",
    male: "Hombre",
    female: "Mujer",
    category: "Categoría",
    email: "Email",
    dni: "DNI",
    phone: "Tel",
    Motivo1: 'Motivo de salida',
    Motivo2: 'Submotivo de salida',
    Motivo3: 'Observaciones motivo de salida',
    Clima1: "La relacion con el responsable ha sido",
    Clima2: "El clima de trabajo ha sido satisfactorio",
    Concilia1: "¿Me han dado facilidades de cambio de turnos?",
    Concilia2: "¿Cómo crees que podríamos mejorar?",
    Desarrollo1: "¿La empresa le ha dado opciones de desarrollo?",
    Desarrollo2: "¿La formación recibida ha cumplido sus expectativas?",
  },

  feedback: {
    title: 'Inbox Items',
    filter: 'Filter Items',
    date: 'Date',
    questions: 'Questions',
    proposal: 'Item',
    category: 'Category',
    email: 'Email',
    dni: 'DNI',
    phone: 'Phone',
  },

  modalAnnouncement: {
    quoteCall: 'Schedule a meeting',
    editQuoteCall: 'Meeting',
    date: 'Send date',
    citationStatus: 'Response',
    citationDate: 'Citation date',
    citationHour: 'Citation hour',
    interviewer: 'Contact',
    location: 'Location',
    select: 'Select',
    announcement_details: 'Details',
    writeDetails: 'Specify the details of the citation',
    writeDetailsIntegration: "Specify the details of the citation (Won't be possible to edit it later)",
    body: 'Body',
    writeBody: 'Type on the body message',
    defaultMessages: 'Default message',
    sendCitation: 'Send',
    updateCitation: 'Update',
    writeLocation: 'Enter location',
    writeInterviewer: 'Enter the contact name',
    new_event: "Subject",
    invite_people: "Optional attendees",
    invite_people_placeholder: "Include the mails separated by ;",
    to_time: "to",
    online_meeting: "Online meeting",
    attendees: "Attendees",
    optionals: "Optionals",
    accepted: "Accepted",
    declined: "Declined",
    none: "None",
    organizer: "Organizer",
    notes: "Notes",
    confirmation: {
      description_create_individual: 'You are going to create an individual meeting, are you sure?',
      description_create_group: 'You are going to create a group meeting for {num} people, are you sure?',
      description_update: 'You are going to edit the meeting for {num} person/s, the original meetings will be overwritten, are you sure?',
      description_update_one: 'There is already an existing meeting, the data will be overwritten, are you sure?',
    },
    filterBlockCitation: "Citation",
    filterBlockGeneral: "General",
    responsible: "Responsible",
  },

  modalRequireDoc: {
    requireDoc: 'Documentation request',
    limitDate: 'Documentation submission deadline',
    select: 'Select',
    infoToUpload: 'Information to upload',
    writeInfoToUpload: 'Specify the information to upload by the candidature',
    body: 'Body',
    writeBody: 'Write the message body',
    defaultMessages: 'Default messages',
    sendRequireDoc: 'Send',
  },

  modalMailCustom: {
    quoteCall: 'send notification',
    editQuoteCall: 'Edit meeting',
    subject: 'Subject',
    writeSubject: 'Enter the subject',
    message: 'Message',
    writeMessage: 'Enter a message',
    body: 'Body',
    writeBody: 'Type on the body message',
    defaultMessages: 'Default messages',
    send: 'Send a notification',
    sendCompleted: 'Notification was sent',
    sendsCompleted: 'Notifications were sent'
  },

  modalFilter: {
    search: 'Search',
    notResults: 'No results',
    filterProfiles: 'Filter',
    name: 'Name',
    namePlaceholder: 'Name',
    date: 'Date',
    fromDate: 'From',
    toDate: 'To',
    profile: 'Profile',
    skills: 'Skills',
    recruitmentSource: 'Source',
    recruitmentPlaceholder: 'Infojobs, Linkedin...',
    from: 'From',
    to: 'To',
    inputTextHelper: 'search multiple coincidences. E.g.: "London;Spain"',
    matching: 'Matching',
    workingDay: 'Working hours',
    workingDayPlaceholder: '10 hours, 20 hours...',
    select: 'Select',
    availability: 'Availability',
    availabilityPlaceholder: 'Total, limited...',
    actualStatus: 'Current status',
    actualStatusPlaceholder: 'Eligible, Ineligible...',
    position: 'Position',
    positionPlaceholder: 'Sales associate, waiter...',
    academicFormation: 'Education',
    academicFormationPlaceholder: 'Bachelors, Master...',
    experience: 'Experience',
    experiencePlaceholder: '1 year, 2 years...',
    lastJob: 'Last job',
    lastJobPlaceholder: 'Sales associate, waiter...',
    cityOfResidence: 'Address',
    cityOfResidencePlaceholder: 'Madrid, Barcelona...',
    desiredLocation: 'Additional info',
    desiredLocationPlaceholder: 'Madrid, Majadahonda...',
    desiredArea: 'Others',
    desiredAreaPlaceholder: 'Centro, Usera...',
    saveSearch: 'Save search',
    status: 'Status',
    planningFilters: 'Meeting filters',
    candidatureFilters: 'Candidature filters'
  },

  boxOtherInfo: {
    otherData: 'Others',
    nearbyPlaces: 'Nearby places'
  },

  boxEditableInfo: {
    title: 'Editable information',
    edit: 'Edit',
    cancel: 'Cancel',
    accept: 'Accept',
    success: 'Info updated successfully',
    error: 'Error updating info',
  },

  editFieldAction: {
    title: 'Edit field',
    empty: '(No value)',
  },

  citations: {
    citationCreated: 'Meeting send',
    citationUpdated: 'Meeting updated',
    citations_sent: 'Meeting sent',
    candidatures: 'Candidates',
  },

  citationStatus: {
    rejected: 'Rejected',
    accepted: 'Accepted',
    replan: 'Reschedule',
    pending: 'Unanswered',
    tentative: 'Tentative',
  },

  requireDoc: {
    docRequired: 'Documentation request sent',
    docRequest: 'Requested documentation',
  },

  interviewerNotes: {
    titleTab: 'Interviewer notes',
    edit: 'Edit notes',
    textareaPlaceholder: 'Notes',
    showOnPrint: 'Show when printing',
    updatedAt: 'Modified by {author}, on {date} at {hour}',
  },

  headerProfileButtons: {
    back: 'Back',
    actions: 'Actions',
    previous: 'Previous',
    following: 'Following',
    downloadCV: 'Download resume',
    downloadVideo: 'Download video',
    markAsRead: 'Mark as read',
    quoteCall: 'Schedule a meeting',
    editQuoteCall: 'Meeting',
    changeState: 'Change status',
    changeTags: 'Change tags',
    markFavorite: 'Mark favorite',
    removeFavorites: 'Remove favorites',
    exportFavorites: 'Download resume',
    remove: 'Remove',
    print: 'Print',
    userDeleted: 'User deleted',
    files: 'Attachments',
    mailCustom: 'Send notification',
    docs:'Attachments',
    backToApplications:'Back to candidature',
    add_to_blacklist: 'Add to blacklist',
    remove_from_blacklist: 'Remove from blacklist',
    added_to_blacklist: 'Added to blacklist',
    removed_from_blacklist: 'Pulled from blacklist',
    backToProcess: 'Back to process',
  },

  modalDownloadFiles: {
    title: 'Attachments'
  },

  modalFilesPreview: {
    no_preview: 'Preview not available'
  },

  modalCreateReport: {
    createReport: 'Create a report',
    name: 'Name',
    reportName: 'Report name',
    date: 'Date',
    fromDate: 'From',
    toDate: 'To',
    profile: 'Profile',
    skills: 'Skills',
    recruitmentSource: 'Source',
    matching: 'Matching',
    workingDay: 'Working hours',
    availability: 'Availability',
    actualStatus: 'Current status',
    position: 'Position',
    positionPlaceholder: 'Sales associate, waiter...',
    academicFormation: 'Education',
    academicFormationPlaceholder: 'Bachelors, Master...',
    experience: 'Experience',
    lastJob: 'Last job',
    lastJobPlaceholder: 'Sales associate, waiter...',
    cityOfResidence: 'Address',
    cityOfResidencePlaceholder: 'Madrid, Barcelona...',
    desiredLocation: 'Additional info',
    desiredLocationPlaceholder: 'Madrid, Majadahonda...',
    desiredArea: 'Others',
    desiredAreaPlaceholder: 'Centro, Usera...',
    removeUser: 'Remove user',
    enterCandidateName: 'Enter the name to comfirm',
    writeCandidateName: 'Type the name',
    filterProfiles: 'Filter',
    from: 'From',
    to: 'To',
    include: 'Include',
    selectAll: 'Select all'
  },

  modalChangeAvatar: {
    title: 'Change avatar',
    info: 'Drag your photo or click to search',
    submit: 'Accept',
    dropFilesHere: 'Drag your photo here or select a file...',
    dropFilesActive: 'Image correct, drop to continue'
  },

  modalChangePass: {
    title: 'Change password',
    forceTitle: 'Please, change the password to continue',
    info: 'Enter your new password and click accept',
    submit: 'Accept',
    oldPass: 'Old password',
    newPass: 'New password',
    repPass: 'Repeat password',
    errors: {
      passNotMatch: 'Passwords don\'t match',
      passNotStrongEnough: "The password must be at least 12 characters long and must include a combination of uppercase, lowercase, numbers, and special characters."
    }
  },

  boxProfileCite: {
    currentState: 'Current status',
    citationDate: 'Date meeting',
    hiringDate: 'Start date',
    citationManager: 'Meeting sender',
    recruitmentSource: 'Source',
    tags: 'Tags',
    offers: 'Offer sent',
    disponibilidad: "Hour availability:"

  },

  boxInfoHeader: {
    currentState: 'Current status',
    citationDate: 'Meeting date',
    citationManager: 'Meeting sender'
  },

  modules: {
    title: 'Solutions',
    module1: 'Talent Acquisition',
    module2: 'On Boarding',
    module3: 'Welcome Pack',
    module4: 'Employee hotline',
    module5: 'Absenteeism',
    module6: 'Surveys',
    module7: 'Virtual tutor',
    module8: 'Exit interview'
  },

  myProfile: {
    title: 'My profile',
    logOut: 'Logout',
    name: 'Name',
    surname: 'Surname',
    email: 'Email',
    id: 'Id',
    phone: 'Phone',
    profileUpdated: 'Profile updated',
    avatarUpdated: 'Avatar updated',
    updatePass: 'Update password',
    update: 'Update',
    passUpdated: 'Password updated',
    passUpdatedError: 'Your password is not updated. Please, check your password.'
  },

  simpleDialog: {
    defaultMessages: 'Default messages',
    close: 'Close'
  },

  saveSearchesTable: {
    actions: 'Actions',
    delete: 'Delete',
    title: 'Tittle',
    date: 'Date',
    fields: 'Fields',
    backToSaveSearches: 'Back to Save Searches'
  },

  pullConfig: {
    title: "Candidates' pull",
    candidatures: "Candidates notified",
  },

  pullConfigTable: {
    actions: 'Actions',
    delete: 'Delete',
    title: 'Title',
    is_active: 'Active',
    renotify_answered: 'Renotify',
    interval_in_days: 'Periodicity',
    days: 'days',
    last_time_execution: 'Last sent',
    filters: 'Filters',
    from: 'From',
    to: 'to',
  },

  reportsTable: {
    actions: 'Actions',
    delete: 'Delete',
    title: 'Tittle',
    date: 'Date',
    fields: 'Fields'
  },

  profileStats: {
    profile: 'Profile',
    skills: 'Skills'
  },

  pda: {
    match: 'Match'
  },

  pdaKeys: {
    r: 'Risk',
    e: 'Extroversion',
    p: 'Patience',
    n: 'Rules',
    a: 'Self-control',
  },

  modalSaveSearch: {
    title: 'Name for your saved search',
    back: 'Back to filter',
    enterName: 'Enter a name',
    saveSearch: 'Save search'
  },

  planningTable: {
    markAsRead: 'Mark as read',
    quoteCall: 'Schedule a meeting',
    editQuoteCall: 'Edit meeting',
    changeState: 'Change state',
    markFavorite: 'Mark favorite',
    removeFavorites: 'Remove favorites',
    exportFavorites: 'Export',
    remove: 'Remove',
    print: 'Print',
    actions: 'Actions',
    personalData: 'Personal data',
    fav: 'Favorite',
    export: 'Export',
    matching: 'Matching',
    citation: 'Scheduled',
    interviewer: 'Interviewer',
    technical: 'Contact',
    phase: 'Phase',
    statusPhase: 'Status',
    citationStatus: 'Response',
    status: 'Status',
    attendees: 'Attendees',
    location: 'Location',
    date: 'Creation',
    availability: 'Availability',
    reason: 'Reason',
    groupal: 'Groupal',
    view: 'View',
  },

  peopleTable: {
    markAsRead: 'Mark as read',
    markAsNotRead: 'Mark as unread',
    quoteCall: 'Schedule a meeting',
    editQuoteCall: 'Edit meeting',
    changeState: 'Change state',
    markFavorite: 'Mark favorite',
    removeFavorites: 'Remove favorites',
    exportFavorites: 'Resume',
    remove: 'Remove',
    print: 'Print',
    actions: 'Actions',
    personalData: 'Personal data',
    fav: 'Favorite',
    export: 'Download resume',
    exportCsv: 'Export Report',
    requireDoc: 'Request documentation',
  },

  landingOffer: {
    title: 'Hello',
    body: 'We want to inform you that we have a new offer and we hope you are interested ',
    offerInfo: 'Offer Information',
    interested: 'I\'m interested',
    notInterested: 'I\'m not interested',
    notExist: 'The offer does not exist',
    expiredDescription: 'Sorry, this offer has already ended. We will keep you in mind for future opportunities. <br>Thank you very much for your interest.',
    endDescription: 'Perfect! We confirm that we have received your answer. <br> Thank you very much for participating in our selection process'
  },

  landingPull: {
    title: 'Hello',
    interested: 'I\'m interested',
    notInterested: 'I\'m not interested',
    notExist: 'Page not found',
    endDescription: 'Perfect! We confirm that we have received your answer. <br> Thank you very much'
  },

  landingDocRequest: {
    title: 'In order to continue in the <b>selection process</b> you must provide the documentation described below, before the following date: ',
    limit_date: 'Limit date: ',
    notExist: 'Page not found',
    expiredDescription: 'We are sorry, the time limit for submitting the documentation has already expired.  <br>Thank you for your interest.',
    endDescription: 'Perfect! We confirm that we have received your documents. Thank you!',
  },

  dropzone: {
    dragOrBrowse: "Drag files or click to select",
    file_max_size: "File max size",
    addFile: "Add file",
    send: "Send",
    error: "Error",
    done: "Sent",
  },

  observationsTable: {
    today: 'Today',
    now: 'Now',
    title: 'Changes log',
    date: 'Date',
    time: 'Time',
    change: 'Change',
    author: 'Author',
    addPlaceholder: 'Enter a comment here',
    add: 'Add',
    interviewDate:'Invertiew date',
    addObservation:'Add observations'
  },

  pushOfferTable: {
    title: 'Push Offers',
    name: 'Name',
    description: 'Description',
    userName: 'User',
    createdAt: 'Created at',
    state: 'Status',
  },

  offerCard: {
    title: 'Push Offers',
    interested_candidatures: ' interested candidatures',
    updated_at: 'Date',
    empty: 'There is no push offer available',
    showOffers: 'Show only my push offers',
  },

  //Organism
  appBar: {
    searchPeople: 'Search',
    filterPeople: 'Filter',
    filterPlanning: 'Filter',
    saveSearchs: 'Save search',
    saveSearch: 'Save search',
    noResults: 'No results',
    errorNoTitle: 'Tittle is compulsory',
    filters: 'Filters',
    successSave: 'Save search',
    cloneSearch: 'Clone search',
  },

  profileInfo: {
    profile: 'Profile',
    availableFrom: 'Available from:',
    createdAt: 'Interviewed at',
    updatedAt: 'Updated at',
    pullSentAt: 'Pull sent at',
    zone: 'Zone',
    formation: 'Education',
    exp: 'Experience',
    lastJob: 'Last job',
    city: 'Address',
    desiredLocation: 'Additional info',
    desiredZone: 'Others',
    years: 'Years',
    cv: 'Resume',
    video: 'Video',
    attached: 'Attached',
    evaluation: 'Evaluation',
    selectionStatus: 'Selection status'
  },

  drawer: {
    inboxPeople: 'Inbox people',
    inbox: 'Inbox',
    savedSearches: 'Saved searches',
    planning: 'Planning',
    favorites: 'Favorites',
    reports: 'Reports',
    statistics: 'Statistics',
    modules: 'Modules',
    clients: 'Clients',
    employeeLine: 'Employee hotline',
    talent: 'Talent Acquisition',
    pushOffer: 'Push Offers',
    pullConfig: 'Pull configuration',
    onboarding: 'Onboarding',
    exit_interview: 'Exit interview',
    integrations: 'Integrations',
    my_profile: 'My profile',
    user_manual: 'User manual',
    phases_states: 'Phases and states',
    evaluativeTests: 'Video interviews',
  },

  statisticsItems: {
    checkAll: 'Mark all',
    year: 'Year',
    month: 'Month',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'Octuber',
    november: 'November',
    december: 'December',
    interviewed: 'Interviewed candidates',
    suitables: 'Eligible Vs interviewed',
    recruitmentSource: 'Candidates per source',
    activity: 'Activity interviewed candidates',
    startDate: 'From',
    endDate: 'To',
    candidatures: 'Candidatures',
    name: 'Name',
    surname: 'Surname',
    dni: 'DNI',
    numberIdentity: 'Employee Nº',
    hiringDate: 'Hiring date',
    leavingDate: 'Leaving date',
    employeesWithoutPhase1: 'Employees who have not completed phase 1',
    employeesHaveNotDoneExit: 'Employees who has not done the exit interview',
    candidaturesSuitables: 'Eligible candidates',
    hiredVsSuitables: 'Hired Vs Eligible',
    notInterestedVsSuitables: 'Not interested Vs Eligible',
    tableTitle: 'Positions',
    onboarding_phases: 'Onboarding Phases',
    phases: 'Phases',
    zone: 'Zone',
    whatsappCounter: {
      title: "WhatsApp counter",
      bot_starter: "Interview invitations",
      citations: "Citations",
      offers: "Push offers",
      pulls: "Candidate pull",
      section: "Section",
      sent: "Sent",
      voucher: "Voucher",
    },
  },

  //Page
  inboxPeople: {
    title: 'Inbox people',
    filtersSearch: 'Searches for'
  },

  offerCandidates: {
    title: 'Offer: ',
    offers_sent: 'Offers sent',
    unique_candidatures: 'Unique candidates',
    email: 'Email',
    sms: 'SMS',
    whatsapp: 'Whatsapp'
  },

  favorites: {
    title: 'Favorites'
  },

  planning: {
    title: 'Planning'
  },

  reports: {
    title: 'Reports',
    reportCreated: 'Report created',
    reportDeleted: 'Report deleted',
    modalConfirmationTitle: 'Generate report',
    modalConfirmationButton: 'Accept',
    modalConfirmationButtonCancel: 'Cancel',
    reportReceiversTitle: "Introduce the report's receiver. This process may take a few minutes. You will receive an email as soon as it's ready. You can add many receivers separated by <b>;</b>'"
  },

  savedSearches: {
    title: 'Saved searches'
  },

  statistic: {
    title: 'Statistics',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday'
  },

  errorPage: {
    title: 'Uuuups! site not found'
  },

  login: {
    login: 'Login',
    sigin: 'Access',
    email: 'Email address',
    password: 'Password',
    recover: 'Recover',
    password_recover: 'Password recover',
    password_recover_success: 'A recovery password has been sent to the indicated email address.',
    password_recover_error: 'There has been an error sending the recovery email.',
    mandatoryField: 'Mandatory field',
    invalidEmail: 'Invalid email',
    noValidRole: 'Invalid user',
    noValidCredentials: 'User or password incorrect'
  },

  tableLocalization: {
    pagination: {
      labelDisplayedRows: '{from}-{to} de {count}',
      labelRowsSelect: 'elements',
      labelRowsPerPage: 'Elements per site',
      firstAriaLabel: 'Begin',
      firstTooltip: 'Go home',
      previousAriaLabel: 'Back',
      previousTooltip: 'Back',
      nextAriaLabel: 'Next',
      nextTooltip: 'Next',
      lastAriaLabel: 'End',
      lastTooltip: 'Go to end'
    },
    body: {
      emptyDataSourceMessage: 'No data'
    },
    toolbar: {
      searchTooltip: 'Search',
      searchPlaceholder: 'Search',
    }
  },

  errors: {
    internalServerError: 'Operation could not be completed'
  },

  landingEmail: {
    title: 'An error has ocurred',
    body: 'Operation could no be completed',
    loading: 'Loading',
    hi: 'Hello',
    citation_header: "We would like to inform you that after evaluating your profile, we would like to move forward with you to the next phase of the selection process for the position of",
    citation_info: "Call information",
    address: "Address:",
    contact_person: "Contact person:",
    interview_date: "Interview date:",
    interview_hour: "Interview hour:",
    give_answer: 'Please send us your response to this call by selecting one of the following options:',
    confirmed: 'I will attend',
    reschedule: 'I will not attend, new date',
    not_interested: 'Not interested',
    citation_not_exist: 'No citation exists',
  },

  landingReschedule: {
    title: 'What slot time is suitable to attend to an interview?',
    noDates: 'There is no available dates',
    successSend: 'Your request has sent correctly',
    putAvailability: 'Enter your availability',
    send: 'Send',
    reasonPlaceholder: 'Enter a reason'
  },

  landingNotInterested: {
    title: 'Could you let us know why you are not interested in? ',
    error: 'An error has occurred',
    successSend: 'Your request has sent correctly',
    putReason: 'Enter a reason',
    send: 'Send'
  },



  peopleTableFilters: {
    toManage: 'To manage',
    inProgress: 'In progress',
    finalists: 'Finalists',
    others: 'Others'
  },

  noCvInfo: 'Candidates selected do not have a resume attached',

  exitInterviewStatisticsItems: {
    satisfactionSurvey: {
      exitFactors: 'Exit factors',
      superAgree: 'Super agree',
      agree: 'Agree',
      littleDisagree: 'Little disagree',
      disagree: 'Disagree',
      accessibleManager: 'Accessible manager',
      successRecognized: 'Success recognition',
      errorTolerance: 'Error tolerance',
      flexibility: 'Flexibility',
      conciliation: 'Conciliation',
      meritocracy: 'Meritocracy',
      clima1: "Good climate in the company",
      clima2: "Good weather with your team",
      concilia1: "Allows conciliation with the family",
      concilia2: "Reconcile with studies",
      desarrollo1: "Paid according to work",
      desarrollo2: "Offers professional development"
    },
    exits: "Exits",
    exit_completed: "Completed",
    recommends: "Yes, recommends",
    reason: "Exit reason",
  },

  processesStatisticsItems: {
    processesTransaccionalRecived: 'Transactional processes received',
    suitables: 'Processed Vs total',
    numberOfPolls: 'Polls answered',
    averagePolls: 'NPS',
    processesTransaccionalGroup: 'Transactional processes per category',
    processesConsultivoGroup: 'Consultation processes per category',
    processesCollectiveAgreementGroup: 'Collective agreement processes',
    startDate: 'From',
    endDate: 'To',
    candidatures: 'Candidatures',
    name: 'Name',
    processes: 'Processes',
    recived: 'Received',
    total: 'Total',
    suitablesVsRecibidos: 'In progress Vs Total',
    tableTitle: 'Transactional processes'
  },
  tableFilters: {
    toManage: 'To manage',
    inProgress: 'In progress',
    finalists: 'Finalist',
    others: 'Others'
  },
  onboardingInbox: {
    title: 'Inbox Onboarding'
  },
  onboarding: {
    deleteSuccess: 'Element removed'
  },


  onboardingStatisticsItems: {
    leaving: 'Leaves',
    hiring: 'Incorporaciones',
    inprogress: 'Onboardings en progreso',
    ratio: 'Ratio',
    phase: 'Phase',
    hot_issues_title: "Hot issues",
    hot_issues: {
      formacion_online : "Formación online",
      uniforme: "Uniforme disponible",
      epi: "EPI disponible",
      contrato: "Contrato entregado",
      rm: "Reconocimiento médico",
      relacion_equipo: "Mala relación con el equipo",
      formacion_riesprof_hito3: "Formación prevención de riesgos",
      mutua_mc_mutual_hito3: "Protocolo de actuación en caso de accidentes",
      formacion_compliance_hito3: "Formación Compliance",
      reconocimiento_medico_hito3: "Reconocimiento médico",
      welcomepack_hito3: "Welcome Pack",
      employeeapp_hito3: "Employee App",
      protocol_bajamed_hito4: "Protocolo baja médica",
      reunion_manager_hito5: "Reunión Feedback",
    }
  },
  status: {
    received: 'Received',
    canceled: 'Canceled',
    in_process: 'In Process',
    pending_info: 'Pending info',
    processed: 'Processed',
    accepted: 'Accepted',
    rejected: 'Rejected',
    declined: 'Rejected',
    none: 'Not answered',
    postponed: 'Postponed',
    developing: 'Developing',
    implemented: 'Implemented',
    1: 'Best match',
    2: 'Available',
    3: 'To be confirmed',
    4: 'Confirmed',
    5: 'Not interested',
    6: 'Reprogram',
    7: 'Hired',
    8: 'Reserve',
    9: 'Does not attend',
    10: 'Cited phase 2',
    11: 'Rejected',
    12: 'Rejected stage 2',
    13: 'Not interested stage 2',
    14: 'Not attend stage 2',
    15: 'In process',
    16: 'Doc requested',
    17: 'Doc received',
    18: 'Pending update',
    19: 'Cited'
  },
  dialog: {
    success: 'Aceptar',
    decline: 'Cancelar',
    save: 'Guardar'
  },
  feedbackStatisticsItems: {
    Received: 'Suggestions Received',
    suitables: 'Suggestions suitable Vs total',
    category: 'Suggestions by category',
    department: 'Suggestions by department',
    centerType: 'Suggestions by center',
    statesTypes: 'Suggestion by state',
    endDate: 'Until',
    name: 'Name',
    recived: 'Received',
    total: 'Total',
  },

  offerStates: {
    unanswered: 'Unanswered',
    interested: 'Interested',
    not_interested: 'Not interested',
  },

  integrations: {
    connect: "Connect",
    disconnect: "Disconnect",
  },

  userManual: {
    title: 'User manual',
  },
  
  phases_states: {
    descriptionPhasesStates: "From here you can create, edit or delete the phases and statuses of the applications.",
    titlePhase: "Phases",
    titleState: "States",
    descriptionPhase: "The phases represent the different steps a candidate goes through during the selection process. The predefined phases are: Received and Hired.",
    descriptionTwoPhase: "In addition to these predefined phases, the application gives you the flexibility to create custom phases based on your organization's specific needs.",
    descriptionState: "The states in a phase represent a candidate's level of progress within a specific stage of the selection process, which in turn simplifies the identification and tracking of the candidate's progress in that phase. The predefined statuses are: Enrolled and Hired.",    
    descriptionTwoState: "In addition to the already established states, the application allows you to customize states to perfectly fit the particularities of your organization.",
    labelPhase: "Phase",
    labelState: "State",
    labelCurrentState: "Current status",
    labelNewState: "New status",
    errorOnDeleteStatus: "You cannot delete a state that is in use, it has {candidatures} candidates that you must first move to another state.",
    placeholderPhase: "Enter the phase name",
    placeholderState: "Enter the state name",
    placeholderSelectPhase: "Select a phase",
    titleButtonPhase: "Add phase",
    titleButtonState: "Add state",
    assignStateToPhase: "Assign state to phase",
    buttonSave: "Save",   
    buttonAllSave: "Save changes",
    buttonCancel: "Cancel",
    buttonMove: "Move",
  },
  
  evaluativeTests: {
    title: "Video Interviews",
    descriptionSection: "Here you will find all your active and inactive campaigns",
    titleNewTest: "New campaign",
    titleEditTest: "Edit campaign",
    subtitleNewTest: "Campaigns Settings",
    descriptionNewTest: "Configure the campaign, according to the needs of the selection process",
    labelPickerStartDate: "Start Date",
    labelPickerEndDate: "End date",
    nameTest: "Test name",
    placeholderNameTest: "Enter the name of the test",
    urlBot: "bot URL",
    placeholderUrl: "https://...",
    buttonGenerateUrl: "Generate URL",
    selectionQuestions: "Selection Questions",
    subtitleTest: "Perform a more complete filter of the candidates.",
    subtitleTest2: "Create at least one question and focus on candidates who answered correctly.",
    placeholderInputQuestion: "Question #1",
    buttonAddQuestion: "Add question",
    buttonSave: "Save",
    buttonCancel: "Cancel",
    buttonRegenerateUrl: "Regenerate URL",
    noResults: "No results were found for the selected date.",
    errorFetchTests: "An error occurred while fetching the evaluative tests",
    buttonCreateTest: "Create test",
    singleQuestion: "Question",
    multipleQuestions: "Questions",
    successDeleteTest: "Campaign successfully deleted",
    successDuplicateTest: "Successfully duplicated Campaign",
    successCreateTest: "Campaign created successfully",
    successUpdateTest: "Campaign updated successfully",
    useSampleQuestion: "Use sample question",
    activesTest: "Active",
    inactivesTest: "Inactive",
    titleBoxProfileInbox: "Video Interviews",  
    statusVideoInterview: "Status of the video interview",
    statusVideoInterviewPending: "Pending",
    statusVideoInterviewDone: "Done",
    statusVideoInterviewReviewed: "Reviewed",  
    errorUploadVideo: "An error occurred while uploading the video",
  },
}
