import React, {useState} from 'react'
import compose from 'recompose/compose'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Modal from '@material-ui/core/Modal'
import {withI18n} from '../../hocs'
import {InputField, ModalHeader, RegularButton} from '../../atoms'
import {ReactComponent as ModalIcon} from '../../../assets/images/user-white.svg'
import {ModalStyled} from './styled'
import Tooltip from '@material-ui/core/Tooltip'

const ModalChangePass = ({ open, handleClose, i18n, onSubmit, forceResetPassword }) => {
  const [oldPass, setOldPass] = useState('')
  const handleChangeOldPass = value => setOldPass(value)

  const [newPass, setNewPass] = useState('')
  const handleChangeNewPass = value => setNewPass(value)

  const [repPass, setRepPass] = useState('')
  const handleChangeRepPass = value => setRepPass(value)

  const onClickSubmit = e => {
    e.preventDefault()

    onSubmit && newPass && onSubmit({ newPass, oldPass })
    setNewPass('')
    setRepPass('')
    setOldPass('')
  }


  let validatePass = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$€%^&*(),.?\":{}|<>_-])(?=.{12,})")
  let validated = !_.isEmpty(oldPass) && !_.isEmpty(newPass) && repPass === newPass && newPass.match(validatePass)
  let passError = !_.isEmpty(repPass) && repPass !== newPass ? i18n.modalChangePass.errors.passNotMatch : ''
  let strongPasswordError = !_.isEmpty(newPass) && !newPass.match(validatePass) ? i18n.modalChangePass.errors.passNotStrongEnough : ''

  return (
    <div>
      <Modal
        style={customStyles}
        className="modal-wrapper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <ModalStyled>
          <ModalHeader Icon={<ModalIcon />} title={forceResetPassword ? i18n.modalChangePass.forceTitle : i18n.modalChangePass.title} closeClick={handleClose} />
          <div className="modal-body">
            <form>
              <Grid container alignItems="center" spacing={3}>
                <Grid item sm={12}>
                  <label>{i18n.modalChangePass.info}</label>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Grid container alignItems="center" spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <label>{i18n.modalChangePass.oldPass}*</label>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <InputField
                        name="oldPass"
                        type="password"
                        placeholder={i18n.modalChangePass.oldPass}
                        handleOnChange={handleChangeOldPass}
                        value={oldPass}
                      />
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center" spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <Tooltip title={i18n.modalChangePass.errors.passNotStrongEnough} placement="right" arrow>
                        <label>{i18n.modalChangePass.newPass}*</label>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <InputField
                        name="newPass"
                        type="password"
                        placeholder={i18n.modalChangePass.newPass}
                        handleOnChange={handleChangeNewPass}
                        value={newPass}
                      />
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center" spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <label>{i18n.modalChangePass.repPass}*</label>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <InputField
                        name="repPass"
                        type="password"
                        placeholder={i18n.modalChangePass.repPass}
                        handleOnChange={handleChangeRepPass}
                        value={repPass}
                      />
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center" spacing={3}>
                    <Grid item xs={12} sm={4}></Grid>
                    <Grid item xs={12} sm={8}>
                      {passError && <label className="pass-error">{passError}</label>}
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center" spacing={3}>
                    <Grid item xs={12} sm={4}></Grid>
                    <Grid item xs={12} sm={8}>
                      {strongPasswordError && <label className="pass-error">{strongPasswordError}</label>}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <div className="button-wrapper">
                <RegularButton title={i18n.modalChangePass.submit} handleOnClick={onClickSubmit} disabled={!validated} />
              </div>
            </form>
          </div>
        </ModalStyled>
      </Modal>
    </div>
  )
}

const customStyles = {
  overflow: 'scroll'
}

ModalChangePass.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  i18n: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
}

const enharce = compose(withI18n)

export default enharce(ModalChangePass)
