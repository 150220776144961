export default {
  generalError: 'Ha ocurrido un error',
  update: 'Actualizar',
  showMore: 'Mostrar más +',
  showLess: 'Mostrar menos -',
  markAs: 'Marcar como',

  genericText: {},

  modalGeneric: {
    cancel: 'Cancelar',
    send: 'Enviar'
  },

  //Atoms
  avatarMatch: {
    match: 'Match'
  },

  regularButton: {
    apply: 'Aplicar'
  },

  regularSelect: {
    select: 'Seleccionar'
  },

  modalHeader: {
    planning: 'Planificación'
  },

  //Molecules
  modalDeleteUser: {
    removeUser: 'Eliminar',
    enterCandidateName: 'Introduce el nombre del candidato para confirmar la acción',
    writeCandidateName: 'Escribe el nombre de la persona',
    filterProfiles: 'Filtrar perfiles',
    remove: 'Eliminar'
  },

  modalUpdateBlacklist: {
    addToBlacklist: 'Añadir a la lista negra a ',
    removeFromBlacklist: 'Eliminar de la lista negra a ',
    enterCandidateName: 'Introduce el nombre del candidato para confirmar la acción',
    writeCandidateName: 'Escribe el nombre de la persona',
    filterProfiles: 'Filtrar perfiles',
    add: 'Añadir',
    remove: 'Eliminar'
  },

  modalChangeStatus: {
    changeStatus: 'Cambiar estado',
    selectStatus: 'Selecciona un estado',
    selectOffer: 'Selecciona un job',
    incomplete_form: 'Necesitas seleccionar el estado y el job para poder realizar esta acción',
    select: 'Seleccionar',
    suitable: 'Apto',
    not_suitable: 'No apto',
    not_evaluated: 'Sin evaluar',
    to_be_confirmed: 'Por confirmar',
    confirmed: 'Confirmado',
    not_interested: 'No interesado',
    reschedule: 'Reprogramar',
    hired: 'Contratado',
    booking: 'Reserva',
    not_attend: 'No asiste',
    cited_phase2: 'Citado fase 2',
    pending_update: 'Actualización pendiente',
    discarded: 'Descartado',
    descarded_phase2: 'Descartado fase 2',
    not_interested_phase2: 'No interesado fase 2',
    not_attend_phase2: 'No asiste fase 2',
    in_process: 'En proceso',
    hiredDate: 'Fecha de alta ',
    in_progress: 'En progreso',
    doc_required: 'Doc solicitada',
    doc_received: 'Doc recibida'
  },

  modalChangeTags: {
    title: 'Cambiar etiquetas'
  },

  favoritesTable: {
    date: 'Fecha',
    markAsRead: 'Marcar como leído',
    changeState: 'Cambiar estado',
    markFavorite: 'Marcar favorito',
    removeFavorites: 'Eliminar favoritos',
    fav: 'Favorito',
    export: 'Exportar',
    profile: 'Scoring profile',
    skills: 'Scoring skills',
    matching: 'Matching total',
    status: 'Estado',
    evaluation: 'Evaluación',
    personalData: 'Datos personales'
  },

  favoritesProcessTable: {
    title: 'Procesos favoritos'
  },

  favoritesOnboardingTable: {
    title: 'Onboarding favoritos'
  },

  processesTable: {
    title: 'Inbox procesos',
    id: 'Proceso',
    name: 'Nombre y apellidos',
    prominent: 'Destacado',
    date: 'Fecha y hora',
    department: 'Departamento, área',
    category: 'Categoría',
    type: 'Tipo',
    contact: 'Email',
    tel: 'Teléfono',
    status: 'Estado',
    markFavorite: 'Marcar favorito',
    removeFavorites: 'Eliminar favorito',
    print: 'Imprimir',
    markAsRead: 'Marcar como leído',
    unmarkAsRead: 'Marcar como no leído',
    filterProcesses: 'Filtrar procesos',
    changeStatus: 'Cambiar estado',
    changeTags: 'Cambiar etiquetas',
    offer: 'Enviar oferta',
    assignProcess: 'Asignar a proceso',
    offerCandidatureState: 'Interés',
    export: 'Crear Informe',
    all: 'Todos',
    talent_acquisition: 'candidaturas seleccionadas',
    on_board: 'empleados seleccionados',
    welcome: 'empleados seleccionados',
    employee_line: 'empleados seleccionados',
    feedback: 'empleados seleccionados',
    exit_interview: 'empleados seleccionados',
    offer_sent: 'Ofertas enviadas',
    statusChanged: 'Estado modificado correctamente'
  },

  offerModal: {
    title: 'Crear oferta push',
    name: 'Nombre de la oferta',
    name_placeholder: 'Repartidor Aranjuez',
    description: 'Descripción',
    description_placeholder: 'Necesitamos una persona que tenga 2 años de experiencia y con vehiculo para la zona de Aranjuez',
    date_range: 'Fecha inicio/fin de la oferta',
    areUSure: {
      description: 'Vas a notificar a {num} persona/s sobre esta oferta, ¿Estás seguro?',
      sendCompleted: 'Oferta Enviada'
    }
  },

  assignJobOfferModal: {
    title: 'Asignar job',
    offer: "Job",
    offer_placeholder: "Selecciona el job al que quieres asignar",
    button_title: "Asignar job",
    assign: "Asignar",
    successfully_assigned: "Se han asignado los candidatos al job indicado correctamente",
    areUSure: {
      description: 'Vas a asignar a {num} candidato/s a este job, ¿Estás seguro?',
      sendCompleted: 'Job asignado'
    },
  },

  assignedModal: {
    title: 'Asignar a proceso',
    assign: 'Asignar',
    subModalTitle: '¿Confirma la asignación a proceso?',
    subModalSubTitle: 'Candidaturas:',
    subModalSubDescription: 'Proceso seleccionado:',
    latest: 'Recientes',
    placeholder: 'Buscar por nombre de proceso'
  },

  process: {
    title: 'Procesos',
    category: 'Categoría',
    department: 'Departamento',
    service: 'Servicio',
    process: 'Proceso',
    comments: 'Comentarios',
    date: 'Fecha',
    time: 'Hora',
    comment: 'Comentario',
    author: 'Autor',
    documentation: 'Documentación aportada',
    previewCV: 'CV del candidato',
    noPreview: 'Preview no disponible',
    downloadDocs: 'Descargar seleccionados',
    deleteDocs: 'Eliminar seleccionados',
    uploadDocs: 'Subir documentos',
    createComment: 'Crear comentario',
    text: 'Texto',
    commentName: 'Comentario',
    dayHourTransaction: 'Fecha y hora transacción',
    area: 'Área',
    centerProvince: 'Centro Provincia',
    centerType: 'Tipo de centro',
    registerData: 'Fecha de alta',
    noDocuments: 'No hay documentos',
    modifiedStatus: 'Estado modificado',
    deleteSuccess: 'El proceso se ha eliminado con éxito',
    deleteModalTitle: 'Atención!',
    deleteModalText: '¿Estás seguro de que quieres eliminar este elemento?',
    remove: 'Eliminar',
    previewVideo: 'Vídeos'
  },

  modalAnnouncement: {
    quoteCall: 'Citar convocatoria',
    editQuoteCall: 'Convocatoria',
    date: 'Fecha de envío',
    citationStatus: 'Respuesta',
    citationDate: 'Fecha de citación',
    citationHour: 'Hora de citación',
    interviewer: 'Entrevistador',
    location: 'Ubicación',
    select: 'Seleccionar',
    announcement_details: 'Detalles',
    writeDetails: 'Especifica los detalles de la convocatoria',
    writeDetailsIntegration: 'Especifica los detalles de la convocatoria (no podrá ser modificado una vez creado el evento)',
    body: 'Cuerpo',
    writeBody: 'Escribe un cuerpo del mensaje',
    defaultMessages: 'Mensajes predeterminados',
    sendCitation: 'Enviar Citación',
    updateCitation: 'Actualizar',
    writeLocation: 'Escribe el lugar',
    writeInterviewer: 'Escribe el nombre del entrevistador',
    new_event: 'Asunto',
    invite_people: 'Asistentes opcionales',
    invite_people_placeholder: 'Incluye los correos separados por ;',
    to_time: 'hasta',
    online_meeting: 'Reunión online',
    attendees: 'Asistentes',
    optionals: 'Opcionales',
    accepted: 'Aceptada',
    declined: 'Rechazada',
    rejected: 'Rechazada',
    none: 'Sin respuesta',
    pending: 'Sin respuesta',
    tentative: 'Provisional',
    organizer: 'Organizador',
    notes: 'Notas',
    confirmation: {
      description_create_individual: 'Vas a crear una convocatoria individual, ¿Estás seguro?',
      description_create_group: 'Vas a crear una convocatoria grupal para {num} personas, ¿Estás seguro?',
      description_update: 'Vas a editar convocatoria para {num} persona/s, las convocatorias originales se sobrescribirán, ¿Estás seguro?',
      description_update_one: 'Ya hay una convocatoria existente, los datos se sobrescribirá, ¿Estás seguro?'
    },
    filterBlockCitation: 'Citación',
    filterBlockGeneral: 'General',
    responsible: 'Responsable'
  },

  modalRequireDoc: {
    requireDoc: 'Solicitar documentación',
    limitDate: 'Fecha límite para aportar la documentación',
    select: 'Seleccionar',
    infoToUpload: 'Información a aportar',
    writeInfoToUpload: 'Especifica la información a aportar por el candidato',
    body: 'Cuerpo',
    writeBody: 'Escribe un cuerpo del mensaje',
    defaultMessages: 'Mensajes predeterminados',
    sendRequireDoc: 'Enviar'
  },

  modalMailCustom: {
    quoteCall: 'Enviar notificación',
    editQuoteCall: 'Editar convocatoria',
    subject: 'Asunto',
    writeSubject: 'Escribe el asunto',
    message: 'Mensaje',
    writeMessage: 'Escribe un mensaje',
    body: 'Cuerpo',
    writeBody: 'Escribe un cuerpo del mensaje',
    defaultMessages: 'Mensajes predeterminados',
    send: 'Enviar Notificación',
    sendCompleted: 'Notificación enviada',
    sendsCompleted: 'Notificaciones enviadas'
  },

  modalFilter: {
    search: 'Buscar',
    notResults: 'No hay resultados',
    filterProfiles: 'Filtrar perfiles',
    name: 'Nombre',
    candidature: 'Puesto',
    namePlaceholder: 'Nombre del candidato',
    date: 'Fecha',
    fromDate: 'Desde',
    toDate: 'Hasta',
    profile: 'Perfil',
    skills: 'Skills',
    recruitmentSource: 'Fuente de reclutamiento',
    recruitmentPlaceholder: 'Infojobs, Linkedin...',
    from: 'Desde',
    to: 'Hasta',
    inputTextHelper: 'busca mútiples concidencias. Ej: "Madrid;Barcelona"',
    matching: 'Matching',
    workingDay: 'Jornada',
    workingDayPlaceholder: '10 horas, 20 horas...',
    select: 'Seleccionar',
    availability: 'Disponibilidad',
    availabilityPlaceholder: 'Total, parcial...',
    actualStatus: 'Estado actual',
    actualStatusPlaceholder: 'Apto, No apto...',
    position: 'Posición',
    positionPlaceholder: 'Dependiente, Camarero...',
    academicFormation: 'Formación académica',
    academicFormationPlaceholder: 'Diplomado, Licenciado...',
    experience: 'Experiencia',
    experiencePlaceholder: '1 año, 2 años...',
    lastJob: 'Último puesto',
    lastJobPlaceholder: 'Dependiente, Camarero...',
    cityOfResidence: 'Dirección',
    cityOfResidencePlaceholder: 'Madrid, Barcelona...',
    desiredLocation: 'Datos adicionales',
    desiredLocationPlaceholder: 'Madrid, Majadahonda...',
    desiredArea: 'Otros datos',
    desiredAreaPlaceholder: 'Centro, Usera...',
    saveSearch: 'Guardar búsqueda filtrada',
    status: 'Estado',
    planningFilters: 'Filtros de Planificación',
    candidatureFilters: 'Filtros del Candidato'
  },

  boxOtherInfo: {
    otherData: 'Otros datos',
    additionalInformation: 'Información complementaria',
    nearbyPlaces: 'Centros más cercanos',
  },

  boxIAGen: {
    title: 'Evaluación IA',
    matchTitle: 'Puntuación',
    scoresTitle: 'Puntuación IA',
    summaryTitle: "Resumen:"
  },

  boxEditableInfo: {
    title: 'Información editable',
    edit: 'Editar',
    cancel: 'Cancelar',
    accept: 'Aceptar',
    success: 'Campos actualizados correctamente',
    error: 'Error actualizando campos'
  },

  editFieldAction: {
    title: 'Editar campo',
    empty: '(Sin valor)'
  },

  videoSentiments: {
    title: 'Analisis del video',
    titleValores: 'Sentimientos detectados',
    anger: 'Ira',
    contempt: 'Desprecio',
    disgust: 'Asco',
    fear: 'Miedo',
    happiness: 'Felicidad',
    neutral: 'Neutralidad',
    sadness: 'Tristeza',
    surprise: 'Sorpresa'
  },


  iaFake: {
    title: 'Resumen generado por IA',
    description: 'Tras analizar el video del candidato, se observó una destacada capacidad comunicativa. Su presentación fue clara y estructurada, demostrando confianza y dominio del lenguaje.\nExplicó con precisión su experiencia en roles similares al solicitado, detallando tareas y logros relevantes. La fluidez de su discurso y su lenguaje corporal positivo reflejan una gran profesionalidad y empatía. Su conocimiento profundo del sector y su enfoque proactivo refuerzan su idoneidad para el puesto. En resumen, el candidato muestra no solo una sólida experiencia, sino también una comunicación efectiva y un entusiasmo que lo convierten en una opción excelente para nuestra empresa.'
  },

  citations: {
    citationCreated: 'Citación creada',
    citationUpdated: 'Citación actualizada',
    citationError: 'Error al crear la citación',
    citations_sent: 'Citationes enviadas',
    candidatures: 'Candidatos'
  },

  citationStatus: {
    rejected: 'Rechazado',
    accepted: 'Aceptado',
    replan: 'Replanificar',
    pending: 'Sin respuesta',
    tentative: 'Provisional'
  },

  requireDoc: {
    docRequired: 'Solicitud de documentación enviada',
    docRequest: 'Documentación solicitada',
    errorDocRequire: 'Error al solicitar documentación'
  },
  interviewerNotes: {
    titleTab: 'Notas de la entrevista',
    edit: 'Editar notas',
    textareaPlaceholder: 'Notas',
    showOnPrint: 'Mostrar al imprimir',
    updatedAt: 'Modificado por {author}, el {date} a las {hour}'
  },

  headerProfileButtons: {
    export: 'Exportar',
    back: 'Atrás',
    actions: 'Acciones',
    previous: 'Anterior',
    following: 'Siguiente',
    downloadCV: 'Descarga CV',
    downloadVideo: 'Vídeo adjunto',
    markAsRead: 'Marcar como leído',
    quoteCall: 'Citar convocatoria',
    editQuoteCall: 'Convocatoria',
    changeState: 'Cambiar estado',
    changeTags: 'Cambiar etiquetas',
    markFavorite: 'Marcar favorito',
    removeFavorites: 'Eliminar favoritos',
    exportFavorites: 'Exportar excel',
    remove: 'Eliminar',
    print: 'Imprimir',
    userDeleted: 'Usuario eliminado',
    files: 'Adjuntos',
    mailCustom: 'Enviar notificación',
    docs: 'Adjuntos',
    backToApplications: 'Volver a candidatura',
    add_to_blacklist: 'Añadir a lista negra',
    remove_from_blacklist: 'Sacar de lista negra',
    added_to_blacklist: 'Añadido a lista negra',
    removed_from_blacklist: 'Sacado de lista negra',
    backToProcess: 'Volver al proceso',
    backToEvaluativeTests: 'Volver a videoentrevistas',
    statusChanged: 'Estado modificado correctamente',
    personDeleted: 'Persona eliminada correctamente',
    deletePersonError: 'Error al eliminar la persona'
  },

  modalDownloadFiles: {
    title: 'Adjuntos'
  },

  modalFilesPreview: {
    no_preview: 'Preview no disponible'
  },

  modalCreateReport: {
    createReport: 'Crear Informe',
    name: 'Nombre',
    reportName: 'Nombre del informe',
    date: 'Fecha',
    fromDate: 'Desde',
    toDate: 'Hasta',
    profile: 'Perfil',
    skills: 'Skills',
    recruitmentSource: 'Fuente de reclutamiento',
    matching: 'Matching',
    workingDay: 'Jornada',
    availability: 'Disponibilidad',
    actualStatus: 'Estado actual',
    position: 'Posición',
    positionPlaceholder: 'Dependiente, Camarero...',
    academicFormation: 'Formación académica',
    academicFormationPlaceholder: 'Diplomado, Licenciado...',
    experience: 'Experiencia',
    lastJob: 'Último puesto',
    lastJobPlaceholder: 'Dependiente, Camarero...',
    cityOfResidence: 'Dirección',
    cityOfResidencePlaceholder: 'Madrid, Barcelona...',
    desiredLocation: 'Datos adicionales',
    desiredLocationPlaceholder: 'Madrid, Majadahonda...',
    desiredArea: 'Otros datos',
    desiredAreaPlaceholder: 'Centro, Usera...',
    removeUser: 'Eliminar Usuario',
    enterCandidateName: 'Introduce el nombre del candidato para confirmar la acción',
    writeCandidateName: 'Escribe el nombre del candidato',
    filterProfiles: 'Filtrar perfiles',
    from: 'De',
    to: 'A',
    include: 'Incluir',
    selectAll: 'Seleccionar todo'
  },

  modalChangeAvatar: {
    title: 'Cambiar avatar',
    info: 'Arrastra una imagen o pulsa en la caja para buscarla',
    submit: 'Aceptar',
    dropFilesHere: 'Arrastra una imágen aquí, o pulsa para seleccionar archivos...',
    dropFilesActive: 'Imágen válida, suelta para continuar'
  },

  modalChangePass: {
    title: 'Cambiar contraseña',
    forceTitle: 'Para continuar, cambie la contraseña',
    info: 'Introduce la nueva contraseña y pulsa en Aceptar',
    submit: 'Aceptar',
    oldPass: 'Contraseña actual',
    newPass: 'Nueva contraseña',
    repPass: 'Repite la contraseña',
    errors: {
      passNotMatch: 'Las contraseñas no coinciden',
      passNotStrongEnough: 'La contraseña debe tener mínimo 12 caracteres y debe incluir una combinación de mayúsculas, minúsculas, números y caracteres especiales.'
    }
  },

  boxProfileCite: {
    currentState: 'Estado actual',
    citationDate: 'Fecha citación',
    hiringDate: 'Fecha de contratación',
    citationManager: 'Responsable citación',
    recruitmentSource: 'Fuente de reclutamiento',
    tags: 'Etiquetas',
    offers: 'Ofertas enviadas',
    disponibilidad: 'Disponibilidad horaria:'
  },

  boxInfoHeader: {
    currentState: 'Estado actual',
    citationDate: 'Fecha citación',
    citationManager: 'Responsable citación'
  },

  modules: {
    title: 'Módulos',
    module1: 'Talent Acquisition',
    module2: 'On Boarding',
    module3: 'Welcome Pack',
    module4: 'Línea de empleado',
    module5: 'Absentismo',
    module6: 'Surveys',
    module7: 'Tutor visual',
    module8: 'interview interview'
  },

  module_names: {
    talent_acquisition: 'Captación de talento',
    employee_line: 'Línea de empleado',
    on_board: 'On boarding',
    welcome: 'Bienvenida',
    feedback: 'Feedback',
    abseentism: 'Absentismo',
    survey: 'Encuesta',
    visual: 'Visual',
    exit_interview: 'Exit Interview'
  },

  myProfile: {
    title: 'Mi perfil',
    logOut: 'Cerrar la sesion',
    name: 'Nombre',
    surname: 'Apellidos',
    email: 'Email',
    id: 'Id',
    client: 'ID cliente',
    phone: 'Teléfono',
    profileUpdated: 'Perfil actualizado',
    avatarUpdated: 'Avatar actualizado',
    updatePass: 'Actualizar contraseña',
    update: 'Actualizar',
    passUpdated: 'Contraseña actualizada',
    passUpdatedError: 'No se ha actualizado la contraseña. Por favor, Compruebe que la contraseña es correcta.',
    changeLanguage: 'Cambiar idioma',
    languages: [
      { value: 'es', name: 'Español' },
      { value: 'en', name: 'Inglés' },
      { value: 'ca', name: 'Catalán' },
      { value: 'pt', name: 'Portugues' },
      { value: 'fr', name: 'Francés' }
    ]
  },

  simpleDialog: {
    defaultMessages: 'Mensajes predeterminados',
    close: 'Cerrar'
  },

  saveSearchesTable: {
    actions: 'Acciones',
    delete: 'Eliminar',
    title: 'Título',
    date: 'Fecha',
    fields: 'Campos de filtrado',
    backToSaveSearches: 'Volver a Búsquedas Guardadas'
  },

  pullConfig: {
    title: 'Pull de candidatos',
    candidatures: 'Candidatos notificados'
  },

  pullConfigTable: {
    actions: 'Acciones',
    delete: 'Eliminar',
    title: 'Título',
    is_active: 'Activo',
    renotify_answered: 'Renotificar',
    interval_in_days: 'Periodicidad',
    days: 'días',
    last_time_execution: 'Último envío',
    filters: 'Campos de filtrado',
    from: 'Desde',
    to: 'hasta'
  },

  reportsTable: {
    actions: 'Acciones',
    delete: 'Eliminar',
    title: 'Título',
    date: 'Fecha',
    fields: 'Campos de filtrado'
  },

  profileStats: {
    profile: 'Profile',
    skills: 'Skills'
  },

  pda: {
    match: 'Match'
  },

  pdaKeys: {
    r: 'Riesgo',
    e: 'Extroversión',
    p: 'Paciencia',
    n: 'Normas',
    a: 'Autocontrol'
  },

  modalSaveSearch: {
    title: 'Nombre para la búsqueda guardada',
    back: 'Volver al filtrado',
    enterName: 'Escribe un nombre',
    saveSearch: 'Guardar búsqueda filtrada'
  },

  modalSearch: {
    filters: 'Filtros aplicados:',
    title: 'Filtrar procesos',
    chipGroupLocation: 'Ubicación',
    chipGroupProcess: 'Proceso',
    chipGroupPreferences: 'Preferencias',
    workCenter: 'Centro de trabajo',
    processStatus: 'Estado del proceso',
    applicant: 'Solicitante',
    validator: 'Validador',
    creationDate: 'Fecha de creación',
    expectedDateOfIncorporation: 'Fecha prevista de incorporación',
    position: 'Puesto',
    typeOfContract: 'Tipo de contrato',
    workingDay: 'Jornada',
    reason: 'Motivo',
    brand: 'Marca',
    shift: 'Turno',
    placeholderSelect: 'Seleccionar',
    from: 'Desde',
    to: 'Hasta',
    find: 'Buscar',
    clearFilter: 'Limpiar filtros'
  },

  planningTable: {
    markAsRead: 'Marcar como leído',
    quoteCall: 'Citar convocatoria',
    editQuoteCall: 'Editar convocatoria',
    changeState: 'Cambiar estado',
    markFavorite: 'Marcar favorito',
    removeFavorites: 'Eliminar favoritos',
    exportFavorites: 'Exportar CSV',
    remove: 'Eliminar',
    print: 'Imprimir',
    actions: 'Acciones',
    personalData: 'Datos personales',
    candidature: 'Candidatura',
    fav: 'Favorito',
    export: 'Exportar',
    matching: 'Matching',
    citation: 'Fecha entrevista',
    interviewer: 'Entrevistador',
    atendees: 'Asistentes',
    phase: 'Fase',
    statusPhase: 'Estado',
    citationStatus: 'Respuesta',
    status: 'Seguimiento',
    location: 'Ubicación',
    technical: 'Organizador',
    date: 'Fecha de envío',
    availability: 'Disponibilidad',
    reason: 'Motivo',
    groupal: 'Grupal',
    view: 'Ver'
  },

  peopleTable: {
    markAsRead: 'Marcar como leído',
    markAsNotRead: 'Marcar como no leído',
    quoteCall: 'Citar convocatoria',
    editQuoteCall: 'Editar convocatoria',
    changeState: 'Cambiar estado',
    markFavorite: 'Marcar favorito',
    removeFavorites: 'Eliminar favoritos',
    exportFavorites: 'Exportar CSV',
    remove: 'Eliminar',
    print: 'Imprimir',
    actions: 'Acciones',
    personalData: 'Datos personales',
    fav: 'Favorito',
    export: 'Exportar cv',
    exportCsv: 'Exportar Informe',
    requireDoc: 'Solicitar documentación'
  },

  landingOffer: {
    title: 'Hola',
    body: 'Tenemos una nueva <b>oferta de trabajo</b> que podría interesarte: ',
    offerInfo: 'Información de la oferta',
    interested: 'Me interesa',
    notInterested: 'No me interesa',
    notExist: 'No existe la oferta',
    expiredDescription: 'Lo sentimos, esta oferta ya ha finalizado. Le tendremos en cuenta para furturas oportunidades. <br>Muchas gracias por su interes.',
    endDescription: '¡Perfecto! Te confirmamos que hemos recibido tu respuesta. <br>Muchas gracias por participar en nuestro proceso de selección'
  },

  landingPull: {
    title: 'Hola',
    interested: 'Me interesa',
    notInterested: 'No me interesa',
    notExist: 'Página no encontrada',
    endDescription: '¡Perfecto! Te confirmamos que hemos recibido tu respuesta. <br>Muchas gracias'
  },

  landingDocRequest: {
    title: 'Adjunta la información solicitada: ',
    limit_date: 'Fecha límite: ',
    notExist: 'Página no encontrada',
    expiredDescription: 'Lo sentimos, el plazo para aportar la información ya ha finalizado. <br>Muchas gracias por su interes.',
    endDescription: '¡Perfecto! Te confirmamos que hemos recibido la información. <br>¡Muchas gracias!'
  },

  dropzone: {
    dragOrBrowse: 'Arrastra ficheros o haz click para seleccionarlos',
    addFile: 'Añadir fichero',
    send: 'Enviar',
    error: 'Error',
    file_too_large: 'Archivo demasiado grande',
    file_max_size: 'Tamaño máximo de archivo',
    done: 'Enviado'
  },

  observationsTable: {
    today: 'Hoy',
    now: 'Ahora',
    title: 'Histórico de cambios',
    date: 'Fecha',
    time: 'Hora',
    change: 'Cambio',
    author: 'Autor',
    addPlaceholder: 'Añadir aquí una nueva observación',
    add: 'Añadir',
    interviewDate: 'Fecha entrevista',
    addObservation: 'Añadir observaciones',
    deleted_user_name: 'Usuario',
    deleted_user_surname: 'borrado',
  },

  pushOfferTable: {
    title: 'Ofertas Push',
    name: 'Nombre',
    description: 'Descripción',
    userName: 'Usuario',
    createdAt: 'Fecha creación',
    state: 'Estado'
  },

  lastCitationsTable: {
    title: 'Últimas citaciones',
    date: 'Fecha de envio',
    interviewDate: 'Fecha entrevista',
    userName: 'Organizador',
    location: 'Ubicación',
    statusAnswer: 'Respuesta',
    editCitation: 'Editar citación',
    attendees: 'Asistentes',
    reason: 'Motivo',
  },

  offerCard: {
    title: 'Ofertas Push',
    interested_candidatures: ' candidatos interesados',
    update_at: 'Fecha',
    empty: 'Actualmente no hay ninguna oferta push',
    showOffers: 'Mostrar sólo mis ofertas push',
    delete: {
      success: 'La oferta se ha borrado correctamente',
      error: 'Ha ocurrido un error al borrar la oferta'
    }
  },

  //Organism
  appBar: {
    searchPeople: 'Buscar perfiles',
    filterPeople: 'Filtrar candidaturas',
    filterPlanning: 'Filtrar planificación',
    saveSearchs: 'Guardar búsquedas',
    saveSearch: 'Guardar búsqueda',
    cloneSearch: 'Clonar búsqueda',
    noResults: 'Sin Resultados',
    errorNoTitle: 'El título no puede estar vacío',
    filters: 'Filtros',
    successSave: 'Búsqueda guardada'
  },

  profileInfo: {
    profile: 'Perfil',
    availableFrom: 'Disponible desde:',
    createdAt: 'Fecha de entrevista',
    updatedAt: 'Última actualización',
    pullSentAt: 'Envío de pull',
    zone: 'Zona geográfica',
    formation: 'Formación académica',
    exp: 'Experiencia',
    lastJob: 'Último puesto',
    city: 'Dirección',
    desiredLocation: 'Datos adicionales',
    desiredZone: 'Zona',
    years: 'años',
    cv: 'CV',
    video: 'Vídeo',
    attached: 'Adjunto',
    evaluation: 'Evaluación',
    selectionStatus: 'Editar estado actual'
  },

  drawer: {
    jobOffers: 'Jobs',
    inboxPeople: 'Inbox candidaturas',
    inbox: 'Inbox',
    savedSearches: 'Busquedas guardadas',
    planning: 'Planificación',
    favorites: 'Favoritos',
    reports: 'Informes',
    statistics: 'Estadísticas',
    dashboard: 'Dashboard',
    modules: 'Módulos',
    clients: 'Clientes',
    employeeLine: 'Linea empleado',
    talent: 'Captación de Talento',
    pushOffer: 'Ofertas Push',
    pullConfig: 'Configuración Pull',
    onboarding: 'Onboarding',
    feedback: 'Sugerencias',
    exit_interview: 'Entrevista de salida',
    integrations: 'Integraciones',
    my_profile: 'Mi perfil',
    userManual: 'Manual de uso',
    phases_states: 'Fases y estados',
    evaluativeTests: 'Videoentrevistas',
    user_management: 'Administración de usuarios',
    tag_management: 'Administración de etiquetas',
    keywords_rpa: 'Fuentes de reclutamiento',
    partners: 'Partners',
    superadministratorManual: 'Manual Administrador ATS',
    iaEvaluation: 'Evaluación IA',
    templateRequestManager: 'Gestor de petición de plantilla',
    validatorTemplateRequest: 'Validación de procesos'
  },

  statisticsItems: {
    checkAll: 'Marcar todos',
    year: 'Año',
    month: 'Mes',
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',
    interviewed: 'Candidatos entrevistados',
    suitables: 'Aptos vs entrevistados',
    offer_interested: 'Interesados en ofertas',
    recovered: 'Candidatos actualizados',
    recruitmentSource: 'Candidatos por fuente de reclutamiento',
    activity: 'Actividad de candidatos entrevistados',
    startDate: 'Desde',
    endDate: 'Hasta',
    candidatures: 'Candidaturas',
    name: 'Nombre',
    surname: 'Apellidos',
    dni: 'DNI',
    numberIdentity: 'Nº Empleado',
    hiringDate: 'Fecha contratación',
    leavingDate: 'Fecha baja',
    employeesWithoutPhase1: 'Personas que no han realizado ninguna fase del onboarding',
    employeesHaveNotDoneExit: 'Empleados que no han realizado la entrevista de salida',
    candidaturesSuitables: 'Candidaturas aptas',
    hiredVsSuitables: 'Contratados vs aptos',
    notInterestedVsSuitables: 'No interesados vs aptos',
    tableTitle: 'Posiciones',
    positions: 'Posiciones',
    onboarding_phases: 'Personas activas en cada fase del onboarding',
    phases: 'Fases',
    zone: 'Zona',
    alta: 'Actualmente de alta',
    baja: 'Dado de baja',
    whatsappCounter: {
      title: 'Contador de WhatsApp',
      section: 'Sección',
      bot_starter: 'Invitaciones entrevista',
      citations: 'Citaciones',
      offers: 'Ofertas push',
      pulls: 'Pull de candidatos',
      sent: 'Enviados',
      voucher: 'Bono'
    }
  },

  //Page
  inboxPeople: {
    title: 'Inbox candidaturas',
    filtersSearch: 'Resultados para'
  },

  offerCandidates: {
    title: 'Oferta: ',
    offers_sent: 'Ofertas enviadas',
    unique_candidatures: 'Candidatos únicos',
    email: 'Email',
    sms: 'SMS',
    whatsapp: 'WhatsApp'
  },

  favorites: {
    title: 'Favoritos'
  },

  planning: {
    title: 'Planificación'
  },

  reports: {
    title: 'Informes',
    reportCreated: 'Informe creado',
    reportDeleted: 'Informe borrado',
    modalConfirmationTitle: 'Generar informe',
    modalConfirmationButton: 'Aceptar',
    modalConfirmationButtonCancel: 'Cancelar',
    reportReceiversTitle:
      'Introduce los destinatarios del informe. Este proceso puede demorarse unos minutos. Te enviaremos un correo cuando este listo. Puedes añadir varios destinatarios separandolos por <b>;</b>',
    reportExportedSuccess: 'Su informe se está creando. Se lo enviaremos por correo lo antes posible',
    reportExportedFailed: 'Hubo un problema al crear su informe. Se ha comunicado la incidencia al equipo de soporte'
  },

  savedSearches: {
    title: 'Búsquedas guardadas'
  },

  exitInterview: {
    title: 'Entrevistas de Salida'
  },

  statistic: {
    title: 'Estadísticas',
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
    sunday: 'Domingo'
  },

  errorPage: {
    title: 'Uuuups! Página no encontrada'
  },

  login: {
    login: 'Login',
    sigin: 'Acceder',
    email: 'Correo electrónico',
    password: 'Contraseña',
    recover: 'Recuperar',
    password_recover: 'Recuperar contraseña',
    password_recover_success: 'Se ha enviado una contraseña de recuperación al email indicado',
    password_recover_error: 'Ha habido un error en el envío del email de recuperación',
    mandatoryField: 'Campo obligatorio',
    invalidEmail: 'Email inválido',
    noValidRole: 'Usuario inválido',
    noValidCredentials: 'Usuario o contraseña incorrectos'
  },

  tableLocalization: {
    pagination: {
      labelDisplayedRows: '{from}-{to} de {count}',
      labelRowsSelect: 'elementos',
      labelRowsPerPage: 'Elementos por página',
      firstAriaLabel: 'Principio',
      firstTooltip: 'Ir al inicio',
      previousAriaLabel: 'Anterior',
      previousTooltip: 'Anterior',
      nextAriaLabel: 'Siguiente',
      nextTooltip: 'Siguiente',
      lastAriaLabel: 'Final',
      lastTooltip: 'Ir al final'
    },
    body: {
      emptyDataSourceMessage: 'Sin datos'
    },
    toolbar: {
      searchTooltip: 'Buscar',
      searchPlaceholder: 'Buscar'
    }
  },

  savedSearchesItemTableLocalization: {
    pagination: {
      labelDisplayedRows: '{from}-{to} de {count}',
      labelRowsSelect: 'elementos',
      labelRowsPerPage: 'Elementos por página',
      firstAriaLabel: 'Principio',
      firstTooltip: 'Ir al inicio',
      previousAriaLabel: 'Anterior',
      previousTooltip: 'Anterior',
      nextAriaLabel: 'Siguiente',
      nextTooltip: 'Siguiente',
      lastAriaLabel: 'Final',
      lastTooltip: 'Ir al final'
    },
    body: {
      emptyDataSourceMessage: 'No se ha encontrado ningún registro para esta búsqueda guardada'
    },
    toolbar: {
      searchTooltip: 'Buscar',
      searchPlaceholder: 'Buscar'
    }
  },

  errors: {
    internalServerError: 'No se ha podido completar la operación.'
  },

  landingEmail: {
    title: 'Ha ocurrido un error',
    body: 'La operación no ha podido completarse',
    loading: 'Cargando',
    hi: 'Hola',
    citation_header: 'Queremos informarte que tras evaluar tu perfil, nos gustaría avanzar contigo a la siguiente fase del proceso de selección para la posición de',
    citation_info: 'Información de la convocatoria',
    address: 'Dirección:',
    contact_person: 'Persona de contacto:',
    interview_date: 'Fecha de la entrevista:',
    interview_hour: 'Hora de la entrevista:',
    give_answer: 'Por favor, envíanos tu respuesta a esta convocatoria, seleccionando una de las siguientes opciones:',
    confirmed: 'Confirmo mi asistencia',
    reschedule: 'No puedo asistir, necesito otra cita',
    not_interested: 'No me interesa',
    citation_not_exist: 'No existe la citación',
  },

  landingReschedule: {
    title: '¿En qué franja horaria podrías acudir a una entrevista?',
    noDates: 'No hay fechas disponibles',
    successSend: 'Tu solicitud se ha enviado correctamente',
    putAvailability: 'Introduce disponibilidad',
    send: 'Enviar',
    reasonPlaceholder: 'Introduce el motivo'
  },

  landingNotInterested: {
    title: '¿Podrías indicarnos por qué no te interesa?',
    error: 'Ha ocurrido un error',
    successSend: 'Tu solicitud se ha enviado correctamente',
    putReason: 'Introduce el motivo',
    send: 'Enviar'
  },

  peopleTableFilters: {
    toManage: 'Por gestionar',
    inProgress: 'En proceso',
    finalists: 'Finalistas',
    others: 'Otros'
  },

  noCvInfo: 'Los candidatos seleccionados no tienen cv asociados',

  exitInterviewStatisticsItems: {
    satisfactionSurvey: {
      exitFactors: 'Factores de salida',
      superAgree: 'Muy de acuerdo',
      agree: 'De acuerdo',
      littleDisagree: 'Poco de acuerdo',
      disagree: 'Nada de acuerdo',
      clima1: 'Buen clima en la empresa',
      clima2: 'Buen clima con tu equipo',
      concilia1: 'Permite conciliar con la familia',
      concilia2: 'Concilia con los estudios',
      desarrollo1: 'Remunerado acorde al trabajo',
      desarrollo2: 'Ofrece desarrollo profesional'
    },
    exits: 'Bajas',
    exit_completed: 'Finalizadas',
    recommends: 'Sí, recomienda',
    reason: 'Motivos de salida'
  },

  processesStatisticsItems: {
    processesTransaccionalRecived: 'Procesos transaccionales recibidos',
    suitables: 'Tramitados vs total',
    numberOfPolls: 'Encuestas respondidas',
    averagePolls: 'NPS',
    processesTransaccionalGroup: 'Procesos transaccionales por tipos',
    processesConsultivoGroup: 'Procesos consultivos por tipos',
    processesCollectiveAgreementGroup: 'Procesos por convenio colectivo',
    startDate: 'Desde',
    endDate: 'Hasta',
    candidatures: 'Candidaturas',
    name: 'Nombre',
    processes: 'Procesos',
    recived: 'Recibidos',
    total: 'Total',
    suitablesVsRecibidos: 'En proceso vs total',
    tableTitle: 'Procesos transaccionales'
  },
  tableFilters: {
    toManage: 'Por gestionar',
    inProgress: 'En proceso',
    finalists: 'Finalistas',
    others: 'Otros'
  },
  onboardingInbox: {
    title: 'Inbox Onboarding'
  },
  onboarding: {
    deleteSuccess: 'El elemento se ha eliminado con éxito',
    notesTitle: 'Notas'
  },
  status: {
    received: 'Recibido',
    canceled: 'Cancelado',
    in_process: 'En proceso',
    pending_info: 'Pendiente de info',
    processed: 'Tramitado',
    accepted: 'Aceptado',
    rejected: 'Rechazado',
    declined: 'Rechazado',
    none: 'Sin respuesta',
    pending: 'Sin respuesta',
    tentative: 'Provisional',
    postponed: 'Aplazado',
    developing: 'En desarrollo',
    implemented: 'Implantado',
    1: 'Apto',
    2: 'No apto',
    3: 'Por confirmar',
    4: 'Confirmado',
    5: 'No interesado',
    6: 'Reprogramar',
    7: 'Contratado',
    8: 'Reserva',
    9: 'No asiste',
    10: 'Citado fase 2',
    11: 'Descartado',
    12: 'Descartado fase 2',
    13: 'No interesado fase 2',
    14: 'No asiste fase 2',
    15: 'En proceso',
    16: 'Doc solicitada',
    17: 'Doc recibida',
    18: 'Actualización pendiente',
    19: 'Citado'
  },
  evaluativeConfig: {
    pending: 'Pendiente',
    in_review: 'En revisión',
    suitable_in_other_process: 'Apto para otros puestos',
    suitable: 'Apto',
    not_suitable: 'Rechazado',
    resignation: 'Renunció',
    no_show: 'No presentado'
  },
  dialog: {
    success: 'Aceptar',
    decline: 'Cancelar',
    save: 'Guardar'
  },

  feedbackStatisticsItems: {
    Received: 'Sugerencias recibidas',
    suitables: 'Sugerencias en curso vs total',
    category: 'Sugerencias por categorías',
    department: 'Sugerencias por áreas',
    centerType: 'Sugerencias por unidad',
    statesTypes: 'Sugerencias por estados',
    endDate: 'Hasta',
    name: 'Nombre',
    recived: 'Recibidos',
    total: 'Total'
  },

  onboardingStatisticsItems: {
    leaving: 'Bajas',
    hiring: 'Incorporaciones',
    inprogress: 'Onboardings en progreso',
    ratio: 'Ratio',
    phase: 'Fase',
    hot_issues_title: 'Hot issues',
    hot_issues: {
      formacion_online: 'Formación online',
      uniforme: 'Uniforme disponible',
      epi: 'EPI disponible',
      contrato: 'Contrato entregado',
      rm: 'Reconocimiento médico',
      relacion_equipo: 'Mala relación con el equipo',
      formacion_riesprof_hito3: 'Formación prevención de riesgos',
      mutua_mc_mutual_hito3: 'Protocolo de actuación en caso de accidentes',
      formacion_compliance_hito3: 'Formación Compliance',
      reconocimiento_medico_hito3: 'Reconocimiento médico',
      welcomepack_hito3: 'Welcome Pack',
      employeeapp_hito3: 'Employee App',
      protocol_bajamed_hito4: 'Protocolo baja médica',
      reunion_manager_hito5: 'Reunión Feedback',
      tarjeta: 'Fichaje',
      hardskill1_p4: 'Alta portal del empleado',
      normativa:"Acceso a la normativa interna",
      contrato_tem: "Contrato firmado",
      funciones: "Funciones y objetivos",
      manual_emergencia: "Manual de emergencias"
}
  },

  offerStates: {
    unanswered: 'Sin contestar',
    interested: 'Interesado',
    not_interested: 'No interesado'
  },

  talent_acquisition: {
    inbox: {
      title: 'Inbox candidaturas'
    },
    favorites: {
      title: 'Favoritos'
    },
    'saved-searches': {
      title: 'Búsquedas guardadas'
    },
    'saved-searches-item': {
      title: 'Búsquedas guardadas'
    },
    'push-offer': {
      title: 'Ofertas Push'
    },
    'offer-candidatures': {
      title: 'Ofertas Push'
    },
    'pull-config': {
      title: 'Pull de candidatos'
    },
    planning: {
      title: 'Planificación'
    },
    reports: {
      title: 'Informes'
    },
    statistics: {
      title: 'Estadísticas'
    },
    'user-manual': {
      title: 'Manual de uso'
    },
    'evaluative-tests': {
      title: 'Pruebas evaluativas'
    }
  },

  on_board: {
    inbox: {
      title: ''
    }
  },

  employee_line: {
    inbox: {
      title: ''
    },
    'saved-searches': {
      title: 'Búsquedas guardadas'
    },
    'saved-searches-item': {
      title: 'Búsquedas guardadas'
    }
  },

  feedback: {
    inbox: {
      title: ''
    },
    title: 'Inbox Items',
    filter: 'Filtrar Items',
    date: 'Fecha',
    questions: 'Preguntas',
    proposal: 'Item',
    category: 'Categoría',
    email: 'Email',
    dni: 'DNI',
    phone: 'Teléfono'
  },

  exit_interview: {
    inbox: {
      title: 'Exit interview'
    },
    exit_reasons: 'Motivos de salida',
    exit_factors: 'Factores de salida',
    other_data: 'Otros datos',
    position: 'Puesto',
    leaving_date: 'Fecha de salida',
    male_or_female: 'Sexo',
    male: 'Hombre',
    female: 'Mujer',
    category: 'Categoría',
    email: 'Email',
    dni: 'DNI',
    phone: 'Tel',
    Motivo1: 'Motivo de salida',
    Motivo2: 'Submotivo de salida',
    Motivo3: 'Observaciones motivo de salida',
    Clima1: 'La relacion con el responsable ha sido',
    Clima2: 'El clima de trabajo ha sido satisfactorio',
    Concilia1: '¿Me han dado facilidades de cambio de turnos?',
    Concilia2: '¿Cómo crees que podríamos mejorar?',
    Desarrollo1: '¿La empresa le ha dado opciones de desarrollo?',
    Desarrollo2: '¿La formación recibida ha cumplido sus expectativas?'
  },

  toast_msg: {
    observation: {
      success: 'Observación agregada correctamente',
      error: 'Ha ocurrido un error'
    },
    requireDoc: {
      success: 'Solicitud de documentación enviada',
      error: 'Error al solicitar documentación'
    },
    changeStatus: {
      success: 'Estado cambiado correctamente',
      error: 'Ha ocurrido un error'
    }
  },

  integrations: {
    outlook: 'Mi calendario de Outlook',
    google: 'Mi Google Calendar',
    connect: 'Conectar',
    disconnect: 'Desvincular'
  },

  userManual: {
    title: 'Manual de uso'
  },

  superadministratorManual: {
    title: 'Manual Administrador ATS'
  },

  phases_states: {
    descriptionPhasesStates: 'Desde aquí podrás crear, editar o eliminar las fases y los estados de las candidaturas.',
    titlePhase: 'Fases',
    titleState: 'Estados',
    descriptionPhase:
      'Las fases representan los diferentes pasos que un candidato atraviesa durante el proceso de selección. Puedes crear hasta un máximo de 8. Las fases predefinidas son: Recibidos y Contratados.',
    descriptionTwoPhase:
      'Además de estas fases predefinidas, la aplicación te brinda la flexibilidad de crear fases personalizadas según las necesidades específicas de tu organización.',
    descriptionState:
      'Los estados en una fase representan el nivel de avance de un candidato dentro de una etapa específica del proceso de selección, lo que a su vez simplifica la identificación y seguimiento del progreso del candidato en esa fase. Los estados predefinidos son: Inscrito y Contratado',
    descriptionTwoState:
      'Sumado a los estados ya establecidos, la aplicación te permite personalizar estados para adecuarse perfectamente a las particularidades de tu organización.',
    labelPhase: 'Fase',
    labelState: 'Estado',
    labelCurrentState: 'Estado actual',
    labelNewState: 'Estado nuevo',
    errorOnDeleteStatus: 'No se puede eliminar un estado que está en uso, tiene {candidatures} candidaturas que deberá mover antes a otro estado.',
    placeholderPhase: 'Introduce el nombre de la fase',
    placeholderState: 'Introduce el nombre del estado',
    placeholderSelectPhase: 'Selecciona una fase',
    titleButtonPhase: 'Añadir fase',
    titleButtonState: 'Añadir estado',
    assignStateToPhase: 'Asignar estado a fase',
    buttonSave: 'Guardar',
    buttonAllSave: 'Guardar cambios',
    buttonCancel: 'Cancelar',
    buttonMove: 'Mover',
    titleFunnel: 'Ilustración del Flujo',
    subtitleFunnel: 'Fases y Estados del proceso de selección',
    titlePhaseFunnel: 'Fases',
    buttonDelete: 'Eliminar',
    selectionFunnel: 'Funnel de Selección',
    currenteState: 'Estado actual',
    labelColor: 'Asignar un color',
    placeholderDescriptionStatus: 'Introduce un mensaje',
    feedbackMessage: 'Mensaje de feedback',
    feedbackMessageRegistered:
      '<p>Agradecemos sinceramente tu interés en unirte a nuestro equipo, y valoramos el tiempo y esfuerzo que has invertido en presentar tu perfil. </p><p><br></p><p>Queremos que sepas que tu candidatura ha sido registrada en nuestro sistema y que estamos revisando cuidadosamente todas las aplicaciones recibidas. </p><p><br></p><p>En estos momentos, estamos evaluando todas las candidaturas y buscando la persona ideal que se ajuste a los requisitos de la posición y a nuestra cultura empresarial. Este proceso puede llevar algún tiempo, ya que estamos comprometidos en realizar una selección minuciosa. </p><p><br></p><p>Te garantizamos que te mantendremos al tanto sobre los siguientes pasos en el proceso de selección tan pronto como tengamos novedades con respecto a una oferta que se alinee con tu perfil y motivación. </p><p><br></p><p>Nuestra intención es ser transparentes y proporcionarte toda la información necesaria para que puedas seguir el desarrollo de tu candidatura. Muchas gracias por tu paciencia y comprensión durante este proceso. </p><p><br></p><p>Apreciamos tu interés en formar parte de nuestro equipo y esperamos tener la oportunidad de conocerte mejor en las siguientes etapas del proceso de selección.</p>',
    feedbackMessageHired:
      '<p>¡Felicidades! Nos complace informarte que has sido seleccionado para formar parte de nuestro equipo. </p><p><br></p><p>Durante el proceso de selección, tu desempeño y habilidades destacaron, y estamos convencidos de que tu experiencia y contribuciones serán muy valiosas para nuestra compañía. </p><p><br></p><p>En los próximos días, nos pondremos en contacto contigo para compartirte los detalles finales del contrato, la documentación necesaria, así como para proporcionarte información sobre la orientación y cualquier preparación necesaria para tu primer día. </p><p><br></p><p>Te damos la enhorabuena una vez más y esperamos lo mejor de ti en esta nueva etapa en tu vida profesional. </p><p><br></p><p>¡Te damos la bienvenida al equipo!</p>',
    feedbackMessageDefaultCustom: 'Estamos revisando tu candidatura, nos pondremos en contacto contigo pronto.',
    selectionStatus: 'Selecciona un estado',
    questionChangeStatus: '¿Quieres realizar un cambio de estado a los candidatos citados?',
    changeStatusCheckApplication: 'Para cambiar el estado del candidato selecciona el job y el estado a cambiar',
    changeStatus: 'Cambiar estado',
    updatedVacancies: 'Vacantes actualizados',
    modalEditPhaseTitle: 'Editar fase',
    modalEditStateTitle: 'Editar estado',
    modalDeletePhaseTitle: 'Eliminar fase',
    modalDeleteStateTitle: 'Eliminar estado',
    chipUnAssigned: 'Sin asignar',
    errorOrderPhasesUpdate: 'Error al actualizar el orden de las fases',
    errorOrderStatusUpdate: 'Error al actualizar el orden de los estados',
    changesSavedSuccessfully: 'Cambios guardados correctamente',
    phaseReceived: 'Recibidos',
    phaseHired: 'Contratados',
    alertCannotAddMoreEightPhases: 'No se puede agregar más de 12 fases',
    alertCreatedPhase: 'Fase creada correctamente',
    alertPhaseExists: 'Ya existe una fase con ese nombre',
    alertErrorCreatePhase: 'Error al crear la fase',
    alertAddNamePhase: 'Debes añadir un nombre a la fase',
    alertCreateStatus: 'Estado creado correctamente',
    alertErrorGetPhases: 'Error al obtener las fases',
    alertStatusExists: 'Ya existe un estado con ese nombre',
    alertErrorCreateStatus: 'Error al crear el estado',
    alertErrorGetStatus: 'Error al obtener los estados',
    alertSelectPhase: 'Seleccione una fase antes de agregar un estado',
    alertTitleCannotDeletePhase: 'No puedes eliminar esta fase',
    messageAlertCannotDeletePhase: 'Tienes estados asociados a esta fase, debes moverlos a otra fase existente o borrarlos para eliminar esta fase.',
    alertThereAreNoStatusesInPhase: 'No hay estados asociados a esta fase',
    stateInscribed: 'Inscrito',
    stateHired: 'Contratado',
    updatedPhase: 'Fase actualizada correctamente',
    errorUpdatedPhase: 'Error al actualizar la fase',
    updatedStatus: 'Estado actualizado correctamente',
    assignedSuccessfully: 'Proceso asignado correctamente',
    errorUpdatedStatus: 'Error al actualizar el estado',
    alertMessageFeedback: 'Debes añadir un mensaje de feedback',
    alertStateNameRequired: 'Debes añadir un nombre al estado',
    questionNotified: '¿Quieres que se envíe un mensaje alertando del cambio en la candidatura a las personas que pasen a este estado?',
    selectNotificationMethods: 'Escoge el canal por el que quieres que se envíen las notificaciones.',
    infoSelectNotificationMethods: 'Recuerda que debes tener una plantilla diseñada para esta comunicación.',
    smsMethod: 'SMS',
    emailMethod: 'Email',
    whatsapMethod: 'WhatsApp'
  },

  evaluativeTests: {
    title: 'Videoentrevistas',
    descriptionSection: 'Aquí encontrarás todas tus videoentrevistas activas e inactivas.',
    titleNewTest: 'Nueva videoentrevista',
    titleEditTest: 'Editar videoentrevista',
    subtitleNewTest: 'Configuración de la videoentrevista',
    descriptionNewTest: 'Realiza la configuración, según la necesidades del proceso de selección',
    labelPickerStartDate: 'Fecha de inicio',
    labelPickerEndDate: 'Fecha de fin',
    nameTest: 'Nombre de la videoentrevista',
    placeholderNameTest: 'Introduce el nombre de la videoentrevista',
    urlBot: 'URL del bot',
    placeholderUrl: 'https://...',
    buttonGenerateUrl: 'Generar URL',
    selectionQuestions: 'Preguntas de selección',
    subtitleTest: 'Realiza un filtro más completo de los candidatos.',
    subtitleTest2: 'Crea al menos una pregunta, asigna un tiempo máximo de respuesta y centrate en los candidatos que respondieron correctamente.',
    placeholderInputQuestion: 'Pregunta N°1',
    buttonAddQuestion: 'Añadir pregunta',
    buttonSave: 'Guardar',
    buttonCancel: 'Cancelar',
    buttonRegenerateUrl: 'Regenerar URL',
    inactive: '(desactivada)',
    noResults: 'No se encontraron resultados para la fecha seleccionada.',
    errorFetchTests: 'Ha ocurrido un error al obtener las videoentrevistas ',
    buttonCreateTest: 'Crear videoentrevista',
    singleQuestion: 'Pregunta',
    multipleQuestions: 'Preguntas',
    successDeleteTest: 'Videoentrevista eliminada correctamente',
    successDuplicateTest: 'Videoentrevista duplicada correctamente',
    successCreateTest: 'Videoentrevista creada correctamente',
    successUpdateTest: 'Videoentrevista actualizada correctamente',
    useSampleQuestion: 'Usar pregunta de ejemplo',
    activesTest: 'Mostrando activas',
    inactivesTest: 'Mostrando todas',
    placeholderInputTerm: 'Filtrar por nombre',
    titleBoxProfileInbox: 'Videoentrevistas',
    statusVideoInterview: 'Estado de la videoentrevista',
    infoUploadVideo: 'Candidato no ha subido video',
    titleTemplate: 'Plantillas de bot',
    successChangeStatus: 'Estado cambiado correctamente',
    errorFieldRequired: 'Este campo es obligatorio',
    errorFieldInvalid: 'Este campo es inválido',
    errorFieldQuestion: 'Debes añadir al menos una pregunta',
    placeholderSelectTemplate: 'Selecciona una plantilla',
    placeholderTimeLimit: 'Tiempo max.',
    successRegenerateUrl: 'Ya se ha regenerado la url y el candidato puede acceder de nuevo',
    errorRegenerateUrl: 'Ha ocurrido un error al regenerar la url',
    inactiveCard: 'Inactiva',
    menuEdit: 'Editar',
    menuAddCsv: 'Importar candidatos',
    menuDuplicate: 'Duplicar',
    menuDelete: 'Eliminar',
    placeholderQuestionOne: 'Cuéntanos en 30 segundos lo más relevante de tu experiencia laboral y cómo se relaciona con el puesto al que estás aplicando.',
    placeholderQuestionTwo: '¿Cómo te comunicas efectivamente con diferentes partes interesadas (clientes, compañeros de trabajo, proveedores, etc.)?',
    placeholderQuestionThree: '¿Qué habilidades consideras más importantes para este trabajo?',
    placeholderQuestionFour: '¿Cómo contribuyes al trabajo en equipo y a la colaboración en un entorno laboral?',
    placeholderQuestionFive: 'Describe una situación en la que superaste un desafío en el trabajo',
    placeholderQuestionSix: '¿Cuáles son tus metas a medio/largo plazo y cómo este puesto se alinea con ellas?',
    titleQuestion: 'Pregunta',
    csvUpload: {
      editCta: 'Importar candidatos',
      modalCheckCandidates: 'Se van a importar %ok% candidatos en el fichero.',
      modalImportCandidates: '¿Deseas continuar? Recibirán una notificación por Whatsapp/email.',
      candidatesImported: 'Candidatos importados correctamente (%ok% / %total%)',
      candidatesNotifiedError: 'Error al notificar a los candidatos'
    }
  },

  inboxColumns: {
    Favoritos: 'Favoritos',
    'Nombre y apellidos': 'Nombre y apellidos',
    País: 'País ',
    DNI: 'DNI',
    'Última videoentrevista': 'Última videoentrevista',
    'Estado videoentrevista': 'Estado videoentrevista',
    'Fecha de envío': 'Fecha de envío'
  },

  filterFields: {
    Nombre: 'Nombre',
    DNI: 'DNI',
    Email: 'Email',
    'Fecha de entrevista': 'Fecha de entrevista',
    Teléfono: 'Teléfono',
    'Video entrevista': 'Videoentrevista',
    Estado: 'Estado'
  },

  filterBlocks: {
    'Video entrevista': 'Videoentrevista',
    'Datos personales': 'Datos personales',
    Evaluación: 'Evaluación'
  },

  user_management: {
    errors: {
      max_users_reached:
        'Has llegado al número máximo de usuarios activos. Si quieres incrementar tu límite ponte en contacto con nuestro equipo de Customer Success.'
    },
    titleSection: 'Administración de usuarios',
    searchLabel: 'Buscador',
    filterProfile: 'Filtrar por perfil',
    placeholderFilter: 'Selecciona un perfil',
    descriptionSection:
      'Aquí podrás controlar y personalizar los permisos y accesos de los administradores de la plataforma. Añade, modifica o elimina cuentas de usuario, asigna roles específicos y garantiza una gestión eficiente del sistema.',
    activeUsers: 'Activos',
    inactiveUsers: 'Inactivos',
    createUser: 'Crear usuario',
    username: 'Nombre',
    lastname: 'Apellido',
    email: 'Email',
    profile: 'Perfil',
    phone: 'Teléfono',
    modalTitleCreate: 'Crear usuario',
    modalTitleUpdate: 'Modificar usuario',
    labelShowDefaultFiltersTalent: 'Configurar Filtros por defecto del módulo de Talento',
    labelShowDefaultFilters: 'Configurar Filtros por defecto del módulo de %module%',
    labelName: 'Nombre',
    labelLastName: 'Apellido',
    labelEmail: 'Email',
    labelModules: 'Módulos',
    labelProfile: 'Perfil',
    labelPassword: 'Contraseña provisional',
    buttonCreateUser: 'Crear usuario',
    buttonCancel: 'Cancelar',
    buttonUpdateUser: 'Actualizar usuario',
    placeholderName: 'Introduce el nombre',
    placeholderLastName: 'Introduce el apellido',
    placeholderEmail: 'Introduce el email',
    placeholderPassword: 'Introduce la contraseña',
    placeholderProfile: 'Selecciona un perfil',
    disableUsers: 'Desactivar usuarios',
    disableUser: 'Desactivar usuario',
    enableUsers: 'Activar usuarios',
    enableUser: 'Activar usuario',
    inputEmailUser: 'Introduce el email del usuario',
    confirmDeactivation: '¿Nos confirmas que deseas desactivar el usuario {name} {surname}?',
    confirmActivation: '¿Nos confirmas que deseas activar el usuario {name} {surname}?',
    buttonConfirmDisable: 'Sí, desactivar usuario',
    buttonConfirmEnable: 'Sí, activar usuario',
    buttonConfirmDisableUsers: 'Sí, desactivar usuarios',
    buttonConfirmEnableUsers: 'Sí, activar usuarios',
    atsAdminUser: 'Súper Administrador',
    supervisorUser: 'Supervisor',
    basicUser: 'Básico',
    customUser: 'Personalizado',
    readOnlyUser: 'Solo lectura',
    createdUser: 'Usuario creado correctamente',
    editedUser: 'Usuario modificado correctamente',
    errorCreateUser: 'Error al crear el usuario',
    errorEditUser: 'Error al modificar el usuario',
    nameRequired: 'Debes añadir un nombre',
    surnameRequired: 'Debes añadir un apellido',
    emailRequired: 'Debes añadir un email',
    emailInvalid: 'Email inválido',
    passwordRequired: 'Debes añadir una contraseña',
    moduleRequired: 'Debes seleccionar un módulo',
    profileRequired: 'Debes seleccionar un perfil',
    confirmDisableUsers: '¿Nos confirmas que deseas desactivar los siguientes usuarios?',
    confirmEnableUsers: '¿Nos confirmas que deseas activar los siguientes usuarios?',
    userDisabledSuccessfully: 'Usuario desactivado correctamente',
    usersDisabledSuccessfully: 'Usuarios desactivados correctamente',
    userEnabledSuccessfully: 'Usuario activado correctamente',
    usersEnabledSuccessfully: 'Usuarios activados correctamente',
    userDisabledError: 'Error al desactivar el usuario',
    usersDisabledError: 'Error al desactivar los usuarios',
    userEnabledError: 'Error al activar el usuario',
    usersEnabledError: 'Error al activar los usuarios',
    passwordRequirements: 'La contraseña debe tener mínimo 12 caracteres y debe incluir una combinación de mayúsculas, minúsculas, números y caracteres especiales.',
    passwordInvalid: 'La contraseña es inválida',
    passwordValid: 'La contraseña es válida',
    userAlreadyExists: '¡El usuario ya existe!',
    dateCreated: 'Fecha de creación',
    labelAuthorizedSections: 'Secciones',
    placeholderModules: 'Selecciona los módulos para este usuario',
    placeholderAuthorizedSections: 'Selecciona las secciones para este perfil',
    authorizedSectionsRequired: 'Debes darle acceso a alguna sección',
    inbox: 'Inbox',
    push_offer: 'Ofertas push',
    pull_config: 'Configuración pull',
    saved_searches: 'Búsquedas guardadas',
    planning: 'Planificación',
    favorites: 'Favoritos',
    reports: 'Informes',
    statistics: 'Estadísticas',
    statistics_dashboard: 'Dashboard estadísticas',
    rpa_config: 'Fuentes de reclutamiento',
    evaluative_tests: 'Videoentrevistas',
    template_requestor: 'Gestor de petición de plantilla',
    template_validator: 'Validación de procesos',
    module: 'Módulo'
  },

  ia_evaluation: {
    titleSection: 'Evaluación automatizada por IAG',
    descriptionSection:
      'Personaliza y configura el sistema de selección automatizada por IAG. En esta primera etapa crea y edita tus evaluaciones de acuerdo a los puestos configurados.',
    createEvaluation: 'Crear nueva evaluación',
    searchLabel: 'Buscador',
    placeholderInputTerm: 'Nombre',
    active: 'Activos',
    inactive: 'Inactivos',
    disable: 'Desactivar evaluación',
    enable: 'Activar evaluación',
    name: 'Nombre',
    job: 'Puesto',
    family: 'Familia',
    criteria: {
      experience: 'Experiencia',
      education_training: 'Formación',
      salary_expectation: 'Expectativas Sal.',
      employment_status: 'Situación laboral',
      location: 'Ubicación',
      mobility: 'Movilidad',
      availability: 'Disponibilidad'
    },
    relevance_level: {
      relevant: 'Relevante',
      desired: 'Deseable',
      mandatory: 'Descarta',
      ignore: 'No evaluar',
      others: 'Informativo',
    },
    buttonConfirmDisable: 'Sí, desactivar evaluación',
    buttonConfirmEnable: 'Sí, activar evaluación',
    confirmDeactivation: '¿Nos confirmas que deseas desactivar la evaluación {name}?',
    confirmActivation: '¿Nos confirmas que deseas activar la evaluación {name}?',
    evaluationDisabledSuccessfully: 'Evaluación desactivada correctamente',
    evaluationEnabledSuccessfully: 'Evaluación activada correctamente',
    evaluationDisabledError: 'Error al desactivar la evaluación',
    evaluationEnabledError: 'Error al activar la evaluación',
    wizard: {
      title_create: 'Crear nueva evaluación',
      title_edit: 'Editar evaluación',
      subtitle: 'Criterios de evaluación',
      description:
        'Las criterios de evaluación pueden incluir aspectos como experiencia, formación, ubicación, entre otros. Esta selección sienta las bases para la evaluación inicial de los candidatos y es un paso fundamental para garantizar que el proceso de selección esté alineado con las características específicas de la evaluación que se está configurando.',
      name: 'Nombre',
      name_placeholder: 'Escribe el nombre del perfil',
      family: 'Familia de puestos',
      family_placeholder: 'Selecciona una familia de puestos',
      job: 'Puestos',
      job_placeholder: 'Selecciona un puesto',
      rpa_credentials: 'Credenciales de fuente de reclutamiento',
      rpa_credentials_placeholder: 'Selecciona una fuente de reclutamiento',
      info_text: 'Puesdes establecer ajustes en la evaluación para el puesto de:',
      info_text_values: '<strong>{job}</strong> de la familia <strong>{family}</strong>.',
      tableHeader: 'Elige los items a los que quieres dar un mayor peso en la evaluación:',
      save: 'Guardar ajustes',
      cancel: 'Cancelar',
      save_confirmation_title: '¿Confima la {mode} de la evaluación?',
      save_confirmation_button: 'Confirmo {mode}',
      create_mode: 'creación',
      edit_mode: 'edición',
      successCreateEvaluation: 'Evaluación creada correctamente',
      errorCreateEvaluation: 'Error al crear la evaluación',
      successUpdateEvaluation: 'Evaluación actualizada correctamente',
      errorUpdateEvaluation: 'Error al actualizar la evaluación'
    }
  },

  job_offers: {
    titleSection: 'Jobs',
    descriptionSection:
      '¿Qué vas a poder ver aquí? En esta vista encontrarás el listado de tus ofertas publicadas en las fuentes de reclutamiento configuradas, así como las candidaturas espontáneas y también los procesos que crees manualmente. Accede a cada una de ellas para visualizar los candidatos y gestionar tus procesos de selección',
    filterJobs: 'Filtrar jobs',
    createJob: 'Crear job',
    name: 'Job',
    createdBy: 'Creador',
    keyword: 'Keyword',
    candidature_name: 'Puesto',
    not_available: 'No disponible',
    profile: 'Perfil evaluación',
    location: 'Localización',
    createdAt: 'Fecha creación',
    endDate: 'Fecha finalización',
    recruitmentSource: 'Fuente reclutamiento',
    newCandidates: 'Candidatos nuevos',
    totalCandidates: 'Candidatos totales',
    candidatesNewVsTotal: 'Candidatos\n nuevos / totales',
    infojobs: 'Infojobs',
    infojobsPriority: 'Infojobs',
    epreselec: 'ePreselec',
    epreselecPriority: 'ePreselec',
    pandape: 'Pandapé',
    pandapePriority: 'Pandapé',
    turijobs: 'Turijobs',
    turijobsPriority: 'Turijobs',
    computrabajo: 'Computrabajo',
    computrabajoPriority: 'Computrabajo',
    talentclue: 'Talent Clue',
    bizneo: 'Bizneo',
    indeed: 'Indeed',
    manual: 'Manual',
    spontaneous: 'Espontánea',
    active_offers: 'jobs activos',
    inactive_offers: 'jobs inactivos',
    filter_offers: 'Filtrar jobs',
    offer_status: 'Estado del job',
    active_label: 'Activos',
    inactive_label: 'Inactivos',
    wizard: {
      title_create: 'Crear nuevo job',
      title_edit: 'Editar job',
      subtitle: 'Criterios de evaluación',
      description:
        'En esta modal podrás crear un job nuevo, debes darle un nombre, asignarle una ubicación y los detalles que consideres por supuesto. Una vez que lo hayas creado podrás asignar candidatos desde Inbox a este job, para así tenerlos agrupados en un mismo proceso e ir gestionando cada candidatura dentro de este job.',
      name: 'Nombre',
      name_placeholder: 'Introduce el nombre del job',
      description_value: 'Descripción',
      description_value_placeholder: 'Introduce una descripción',
      evaluation_profile: 'Workflow de evaluación',
      evaluation_profile_placeholder: 'Selecciona un workflow de evaluación',
      location: 'Localización',
      location_placeholder: 'Introduce la localización',
      recruitment_source: 'Fuente de reclutamiento',
      recruitment_source_placeholder: 'Selecciona una fuente de reclutamiento',
      created_at: 'Fecha de creación',
      created_at_placeholder: 'Selecciona la fecha de creación',
      end_at: 'Fecha de finalización',
      end_at_placeholder: 'Selecciona la fecha de finalización',
      save: 'Guardar',
      save_confirmation_title: '¿Confima la {mode} del job?',
      save_confirmation_button: 'Confirmo {mode}',
      create_mode: 'creación',
      edit_mode: 'edición',
      successCreateJobOffer: 'Job creado correctamente',
      errorCreateJobOffer: 'Error al crear el job',
      successUpdateJobOffer: 'Job actualizado correctamente',
      errorUpdateJobOffer: 'Error al actualizar el job'
    }
  },
  job_offer_applications: {
    backToJobOffers: 'Volver a jobs',
    filter: 'Filtrar candidatos',
    is_favorite: 'Favoritos',
    name_and_surname: 'Nombre y apellidos',
    rating: 'Rating Candidato',
    level_one: 'Puntuación Nivel 1',
    level_two: 'Puntuación Nivel 2',
    level_three: 'Puntuación Nivel 3',
    location: 'Ubicación',
    phase: 'Fase',
    selection_status: 'Estado',
    created_at: 'Fecha',
    last_job: 'Último puesto',
    ia_matching: 'Puntuación IA',
    match: 'Match',
    bot_matching_pending: 'Pendiente',
    formation: 'Formacion',
    cv: 'CV'
  },
  tag_management: {
    titleSection: 'Administración de etiquetas',
    searchLabel: 'Buscador',
    filterModule: 'Filtrar por módulo',
    placeholderFilter: 'Selecciona un módulo',
    descriptionSection: 'Aquí podrás controlar y personalizar las etiquetas. Añade, modifica o elimina etiquetas para tener un flujo de trabajo eficiente.',
    createTag: 'Crear etiqueta',
    name: 'Nombre',
    description: 'Descripción',
    color: 'Color',
    module: 'Módulo',
    modalTitleCreate: 'Crear etiqueta',
    modalTitleUpdate: 'Modificar etiqueta',
    labelName: 'Nombre',
    labelDescription: 'Descripción',
    labelColor: 'Color',
    labelModule: 'Módulo',
    labelMatchingMin: 'Puntuación mínima',
    labelMatchingMax: 'Puntuación máxima',
    buttonCreateTag: 'Crear etiqueta',
    buttonCancel: 'Cancelar',
    buttonUpdateTag: 'Actualizar etiqueta',
    placeholderName: 'Introduce el nombre',
    placeholderDescription: 'Introduce la descripción',
    placeholderColor: 'Introduce el color',
    placeholderModule: 'Selecciona un módulo',
    placeholderMatchingMin: 'Selecciona la puntuación mínima de coincidencia para la auto asignación',
    placeholderMatchingMax: 'Selecciona la puntuación máxima de coincidencia para la auto asignación',
    nameRequired: 'Debes añadir un nombre',
    descriptionRequired: 'Debes añadir una descripción',
    colorRequired: 'Debes añadir un color',
    moduleRequired: 'Debes seleccionar un módulo',
    dateUpdated: 'Fecha de actualización',
    errorCreateTag: 'Error al crear la etiqueta',
    errorUpdateTag: 'Error al actualizar la etiqueta',
    deleteTagError: 'Error al eliminar la etiqueta',
    createdTag: 'Etiqueta creada correctamente',
    editedTag: 'Etiqueta modificada correctamente',
    removeTag: 'Eliminar etiqueta',
    remove_tag_confirmation: '¿Nos confirmas que deseas eliminar la etiqueta {tag_name}?',
    remove_confirmed: 'Sí, eliminar etiqueta',
    deleteTagSuccess: 'Etiqueta eliminada correctamente',
    tag_in_use:
      'Esta etiqueta está actualmente asignada a uno o más candidatos. Tienes dos opciones: Borrar directamente la etiqueta quedando estos candidatos sin etiquetar, o asignarles otra de las disponibles. ¿Qué deseas hacer?',
    remove_anyways: 'Eliminar de todas formas',
    labelCurrentTag: 'Etiqueta actual',
    labelNewTag: 'Etiqueta nueva',
    moveToEmpty: 'Debes seleccionar a qué etiqueta moverlo.'
  },

  keywords_rpa: {
    titleSection: 'Integraciones de fuentes de reclutamiento',
    descriptionSection:
      'Desde esta sección podrás configurar y personalizar las integraciones de las fuentes de reclutamiento, como ingresar el proveedor, credenciales, y lo más importante, generar las keywords claves de seguimiento.',
    newIntegration: 'Nueva integración',
    searchLabel: 'Buscador',
    filterSource: 'Filtrar por fuente',
    placeholderFilter: 'Selecciona una fuente',
    sourceInfojobs: 'Infojobs',
    sourceEpreselec: 'ePreselec',
    sourceComputrabajo: 'Computrabajo',
    sourcePandape: 'Pandapé',
    sourceTurijobs: 'Turijobs',
    sourceIndeed: 'Indeed',
    sourceHellowork: 'HelloWork',
    sourceBizneo: 'Bizneo',
    activeIntegrations: 'Activas',
    inactiveIntegrations: 'Inactivas',
    inactiveIntegration: 'Integración inactiva',
    position: 'Posición',
    source: 'Fuente de reclutamiento',
    user: 'Usuario',
    keyword: 'Keyword',
    lastExecution: 'Última ejecución',
    connectionStatus: 'Estado de conexión',
    connectionStatuses: {
      pending: 'Pendiente de ejecutar',
      ok: 'Conexión establecida',
      error_login: 'Credenciales inválidas',
      error_generic: 'Error'
    },
    labelPosition: 'Posición',
    labelSource: 'Fuente de reclutamiento',
    labelAdvancedConfig: 'Configuración avanzada',
    labelConcatUrl: 'Parámetros de la url',
    labelUser: 'Usuario',
    labelPassword: 'Contraseña',
    labelUrl: 'URL',
    placeholderPosition: 'Selecciona la posición',
    placeholderWorkingDay: 'Selecciona la jornada laboral',
    placeholderSource: 'Selecciona una fuente',
    placeholderConcatUrl: 'Introduce los parámetros de la url',
    placeholderUser: 'Introduce el usuario',
    placeholderPassword: 'Introduce la contraseña',
    placeholderUrl: 'Nombre de cliente',
    placeholderKeyword: 'Keyword generada',
    buttonGenerateKeyword: 'Generar keyword',
    modalTitleNewIntegration: 'Nueva integración',
    modalTitleEditIntegration: 'Editar integración',
    buttonCancel: 'Cancelar',
    buttonSaveIntegration: 'Guardar integración',
    buttonUpdateIntegration: 'Actualizar integración',
    messageGenerateKeyword:
      'Ahora para completar la integración necesitamos asignar una keyword que deberás incluir en todas ofertas que publiques en esta fuente de reclutamiento para esta posición. Por favor, clicka el botón para que se te indique esta keyword',
    positionRequired: 'Debes seleccionar una posición',
    sourceRequired: 'Debes seleccionar una fuente',
    usertRequired: 'Debes añadir un usuario',
    passwordRequired: 'Debes añadir una contraseña',
    urlRequired: 'Debes añadir una URL',
    generatedKeywordRequired: 'Debes generar la keyword para poder continuar',
    messageConfirmData: 'Estos son los datos de la integración que acabas de dar de alta. ¿Son correctos?',
    messageConfirmDataEdit: 'Estos son los datos de la integración que acabas de editar. ¿Son correctos?',
    buttonConfirmIntegration: 'Sí, guardar integración',
    buttonCancelIntegration: 'No, cancelar',
    recoverPasswordEmail: 'Recuperar contraseña',
    buttonConfirmDisabledIntegration: 'Sí, desactivar integración',
    buttonConfirmEnabledIntegration: 'Sí, activar integración',
    confirmDisabledIntegration: '¿Nos confirmas que deseas desactivar la integración {position} {provider}?',
    confirmEnabledIntegration: '¿Nos confirmas que deseas activar la integración {position} {provider}?',
    successCreateIntegration: 'Integración creada correctamente',
    errorCreateIntegration: 'Error al crear la integración',
    errorUpdateIntegration: 'Error al actualizar la integración',
    successUpdateIntegration: 'Integración actualizada correctamente',
    integrationAlreadyExists: '¡La integración ya existe!',
    successSendPasswordEmail: 'Contraseña enviada correctamente',
    errorSendPasswordEmail: 'Error al enviar la contraseña',
    titlePosition: 'Posición:',
    titleSource: 'Fuente de reclutamiento:',
    titleConcatUrl: 'Parámetros de la url:',
    titleUser: 'Usuario:',
    titleKeyword: 'Keyword:',
    integrationDisabledSuccessfully: 'Integración desactivada correctamente',
    integrationEnabledSuccessfully: 'Integración activada correctamente',
    urlRequirements: "La URL debe tener este formato: https://cliente.admin.epreselec.com/es/vacantes, sustituir 'cliente' por el nombre de tu cliente",
    invalidUrl: 'URL inválida',
    emailLabel: 'Email',
    passwordLabel: 'Contraseña',
    verifyPasswordUserPlatformToEditeIntegration: 'Para editar la integración, por favor, verifica la contraseña del usuario de la plataforma.',
    verifyButton: 'Verificar',
    errorPasswordIncorrect: 'Contraseña incorrecta',
    successPasswordVerified: 'Contraseña verificada correctamente',
    prefixUrl: 'https://',
    sufixUrl: '.admin.epreselec.com/es/vacantes',
    sourceInfojobsPriority: 'Infojobs',
    sourceEpreselecPriority: 'ePreselec',
    sourceComputrabajoPriority: 'Computrabajo',
    sourcePandapePriority: 'Pandapé',
    sourceTurijobsPriority: 'Turijobs'
  },

  dashboard: {
    filters: {
      division: 'División',
      area: 'Área',
      subarea: 'Subárea',
      center: 'Centro',
      candidature: 'Posición'
    },
    no_data_available: 'No hay datos disponibles para el rango seleccionado',
    home: {
      title: 'Paneles de estadísticas',
      create: 'Crear nuevo panel',
      editDashboard: 'Editar panel',
      saveDashboard: 'Guardar panel',
      removeDashboard: 'Eliminar panel',
      removeDashboardSuccess: 'El panel se ha eliminado correctamente',
      removeDashboardError: 'Error al eliminar panel',
      changes_not_saved: 'Tienes cambios en el panel. Guarda para no perderlos',
      successfully_saved: 'Cambios guardados correctamente',
      error_saving: 'Error al guardar los cambios',
      successfully_deleted: 'Widget borrado correctamente',
      error_deleting: 'Error al borrar el widget',
      deleteWidgetDialogTitle: '¿Seguro que quieres eliminar este widget?',
      deleteCancel: 'Cancelar',
      deleteConfirm: 'Confirmar',
      no_dashboards_created:
        'No hay dashboards de estadísticas creados en tu cuenta. Una vez tu administrador cree los dashboards podrás visualizarlos desde esta sección.'
    },
    weekdays: {
      '2': 'Lunes',
      '3': 'Martes',
      '4': 'Miércoles',
      '5': 'Jueves',
      '6': 'Viernes',
      '7': 'Sábado',
      '1': 'Domingo'
    },
    pie: {
      accepted: 'Aceptadas',
      not_accepted: 'No aceptadas',
      rejected: 'Rechazadas',
      pending: 'Pendientes',
      replan: 'Replanificadas',
      tentative: 'Provisional',
      total: 'Total',
      suitable: 'Aptos',
      not_suitable: 'No aptos',

      gpp_manual: 'Manual',
      gpp_auto: 'Automático',
      gpp_gap: 'GAP',
      gpp_replacement: 'Sustitución',
      gpp_pending: 'Pendientes',
      gpp_cancelled: 'Anulados',
      gpp_rejected: 'Rechazados',
      gpp_active: 'En proceso',
      gpp_empty: 'Pdte. preselección manual',
      gpp_inactive: 'Expirados',
      gpp_closed: 'Cerrados',
      gender_male: 'Hombres',
      gender_female: 'Mujeres',
      gender_undefined: 'Sin determinar'
    },
    barchart: {
      hour_of_day: ':00h',
      interviews: ' entrevistas'
    },
    funnel: {
      applied: 'Inscritos',
      sent: 'Enviados',
      loaded: 'Abiertos',
      interviewed: 'Entrevistados',
      gpp_total: 'Creados',
      gpp_pending: 'En proceso',
      gpp_closed: 'Cerrados'
    },
    whatsapp: {
      bot_starter: 'Invitaciones entrevista',
      citations: 'Citaciones',
      offers: 'Ofertas push',
      pulls: 'Pull de candidatos'
    },
    features: {
      push_offer: 'Ofertas push',
      doc_request: 'Solicitud de documentación',
      video_analysis: 'Video análisis',
      pull_config: 'Pull de candidatos',
      edit_fields: 'Edición de campos',
      phases_status: 'Fases y estados',
      evaluative_tests: 'Tests de evaluación',
      vacancy_request: 'Gestión de peticiones de plantilla (GPP)',
      job_offer: 'Jobs'
    },
    tooltip: {
      ats_access_count: 'La sesión de usuario tiene una duración de un día',
      candidate_experience_cnps: 'El CNPS se calcula en base a los resultados de la encuesta de satisfacción de los candidatos',
      average_time_to_hire:
        'Establece la media de tiempo en días (:value:) en la que se contrata a un candidato, indicando también el valor mínimo y máximo (:max:) de días',
      gpp_average_time_to_hire:
        'Establece la media de tiempo en días (:value:) en la que se cierra un proceso, indicando también el valor mínimo y máximo (:max:) de días'
    },
    edit: {
      title: 'Edición de panel:',
      save: 'Guardar panel',
      saving: 'Guardando...',
      saved: 'Panel guardado correctamente',
      renamed: 'Panel actualizado correctamente',
      removed: 'Panel eliminado correctamente',
      disable: 'Eliminar panel',
      viewDashboard: 'Visualizar panel',
      renameDashboard: 'Renombrar panel',
      renameConfirm: 'Guardar',
      removeDialogTitle: '¿Seguro que quieres eliminar este panel?',
      removeConfirm: 'Confirmar',
      cancel: 'Cancelar',
      errorRemoving: 'Error al eliminar panel',
      errorRenaming: 'Error al renombrar panel',
      placeholderRename: 'Nombre del panel',
      findWidgets: 'Buscar widgets',
      addWidgets: 'Selecciona los widgets para tu panel',
      alertHasUnsavedChanges: 'Panel modificado: recuerda guardar para no perder tus cambios'
    },
    wizard: {
      title: 'Creador de paneles',
      new_from_scratch: 'Crear panel personalizado',
      placeholder_custom_dashboard: 'Escribe el nombre de tu panel',
      create: 'Crear',
      viewDashboards: 'Volver',
      error_name: 'Introduce un nombre para el nuevo panel',
      error_creating: 'Ha ocurrido un error al crear el panel',
      copy_select_preconfigured_dashboard: 'Elige uno de los paneles de estadísticas diseñados desde hr bot factory:',
      copy_select_custom_dashboard: 'O si lo prefieres, crea uno personalizado con los widgets que más te interesen:',
      name: {
        virtual_evaluation_asistant: 'Evaluación Asistentes Virtuales',
        selection_pipeline: 'Pipeline de Selección',
        recruitment_source: 'Fuentes de Reclutamiento',
        candidates_comunication: 'Comunicación a candidatos',
        hired_services: 'Servicios contratados',
        conversion: 'Conversión',
        citation: 'Citación',
        user_activities: 'Actividad de usuarios'
      },
      description: {
        virtual_evaluation_asistant: 'Visión detallada de diversas métricas y estadísticas clave relacionadas con la interacción y eficacia',
        selection_pipeline: 'Representación visual de todas las etapas del proceso de contratación',
        recruitment_source:
          'Dashboard que proporciona una visión detallada de las diversas fuentes de reclutamiento utilizadas, desde portales de empleo hasta redes sociales y referencias internas',
        candidates_comunication: 'Ofrece una visión completa de todas las interacciones y comunicaciones con los candidatos',
        hired_services: 'Información sobre servicios contratados y funcionalidades activas',
        conversion: 'Detalle de conversiones y altas de candidatos en el ATS',
        citation: 'Visión sobre citaciones y porcentajes de respuesta y asistencia de candidatos',
        user_activities: 'Métricas internas sobre estadísticas y actividad de usuarios en el ATS'
      }
    },
    widget_groups: {
      status_phases: 'Pipeline de selección',
      virtual_assistant_evaluation: 'Evaluación Asistentes Virtuales',
      recruitment_sources: 'Fuentes de reclutamiento',
      candidates_communication: 'Comunicación a candidatos',
      features_enabled: 'Servicios contratados',
      conversion: 'Conversión',
      citation: 'Citación',
      user_activity: 'Actividad de los usuarios',
      ai_generative_evaluation: 'Evaluación IAG',
      publish_offers: 'Publicación de ofertas',
      workflow: 'Workflow',
      vacant_management: 'Gestión de vacantes',
      all: 'Ver todos'
    },
    widgets: {
      interviewed_candidates: 'Número de candidaturas',
      suitable_vs_interviewed: 'Aptos vs entrevistados',
      interested_offer_candidates: 'Interesados en ofertas',
      updated_candidates: 'Candidatos actualizados',
      candidates_by_recruitment_source: 'Candidatos evaluados por fuente de reclutamiento',
      candidates_by_week_activity: 'Candidatos entrevistados por día de la semana',
      candidatures: 'Candidaturas',
      whatsapp_consume_by_service: 'Consumo de WhatsApp',
      whatsapp_consumption: 'Uso de WhatsApp',
      candidates_per_phase: 'Candidatos por fase',
      candidates_per_state: 'Candidatos por estado',
      applied_vs_hired_per_position: 'Candidatos inscritos vs. contratados por posición',
      interviewed_candidates_per_position: 'Candidatos entrevistados por posición',
      push_offers_total: 'Ofertas push lanzadas',
      people_deleted: 'Personas borradas',
      push_offers_candidates_notified: 'Ofertas push candidatos notificados',
      pull_candidates_notified: 'Pull candidatos notificados',
      hired_vs_applied_percentage: 'Candidatos contratados vs. inscritos',
      total_sms_and_whatsapp_sent: 'Nº de SMS/Whatsapp enviados',
      total_email_sent: 'Nº de emails enviados',
      candidates_with_citation_sent: 'Nº de candidatos citados a entrevista',
      average_time_between_citation_and_interview: 'Tiempo medio (en días) entre envío citación y entrevista',
      hired_per_recruitment_source: 'Candidatos contratados por fuente de reclutamiento',
      features_enabled_check: 'Funcionalidades contratadas',
      suitable_per_recruitment_source: 'Aptos a entrevista por fuente de reclutamiento',
      communication_methods_enabled: 'Canales de comunicación con candidatos',
      citations_accepted_percentage_per_position: 'Citaciones aceptadas por puesto',
      candidates_per_region: 'Candidatos por ciudad (worldwide)',
      candidates_per_region_spain: 'Candidatos por ciudad (España)',
      registered_vs_suitable_by_recruitment_source: 'Conversión apto vs. entrevistado por F. de reclutamiento',
      suitable_vs_no_suitable: 'Candidatos aptos vs. no aptos',
      suitable_vs_registered: 'Candidatos aptos vs. inscritos',
      citations_response_pct: 'Porcentaje de respuesta a las citaciones',
      citations_accepted_pct: 'Porcentaje de citaciones aceptadas',
      pull_interested_percentage: 'Ofertas pull interesados por oferta',
      push_interested_percentage: 'Ofertas push interesados por oferta',
      candidate_experience_cnps: 'Candidate experience satisfacción (CNPS)',
      interviews_by_hour_of_day: 'Entrevistas por hora del día',
      interviews_by_hour_of_day_pie: 'Entrevistas por hora del día (circular)',
      average_candidate_experience: 'Candidate experience satisfaction (CNPS)',
      candidates_by_recruitment_source_absolute: 'Candidatos evaluados por fuente de reclutamiento',
      average_time_to_hire: 'Time to hire',
      average_phase_time: 'Tiempo medio (en días) por fase',
      ats_access_count: 'Número de accesos al ATS',
      ats_unique_access_count: 'Usuarios con actividad en el ATS respecto al total',
      ats_access_count_ranking: 'Ranking de usuarios con más accesos',
      ats_most_browsed_sections: 'Apartados más visitados',
      ats_status_changed_count: 'Cambios de estado por usuario',
      whatsapp_voucher_consumed: 'Bonos consumidos Whatsapp',
      funnel_applied_sent_opened_interviewed_by_sms_what: 'Funnel (Inscritos, enviados, abiertos, entrevistados) por Whatsapp/SMS',
      applied_vs_interviewed_conversion_sms_whatsapp: 'Número de Inscritos vs. entrevistados por Whatsapp/SMS',
      funnel_applied_sent_opened_interviewed_by_email: 'Funnel (Inscritos, enviados, abiertos, entrevistados) por e-mail',
      applied_vs_interviewed_conversion_email: 'Número de Inscritos vs. entrevistados por e-mail',
      funnel_applied_sent_opened_interviewed_by_unique: 'Funnel (Inscritos, enviados, abiertos, entrevistados) únicos',
      applied_vs_interviewed_conversion_unique: 'Número de Inscritos vs. entrevistados únicos',
      conversion_by_candidature: 'Ratio de conversión por candidatura',
      suitable_vs_not_suitable_pie: 'Aptos vs no aptos',
      conversion_by_recruitment_source: 'Ratio de conversión por fuente de reclutamiento',
      people_by_client: 'Candidatos únicos',
      top_churns_by_chatbot: 'Puntos de fuga principales por chatbot',
      gpp_created_process: 'Procesos creados',
      gpp_processes_summary: 'Situación de los procesos',
      gpp_processes_by_type: 'Procesos cubiertos automáticamente y manual',
      gpp_processes_by_reason: 'Procesos solicitados por motivo',
      gpp_average_candidates_per_process: 'Candidatos sugeridos de media por vacante',
      gpp_uncovered_processes_automatically: 'Procesos cubiertos manualmente',
      gpp_requests_by_user: 'Peticiones por solicitante',
      gpp_requests_by_validator: 'Peticiones por validador',
      gpp_requests_by_area: 'Peticiones por área',
      gpp_candidates_by_gender: 'Candidatos sugeridos por género',
      gpp_time_to_hire_global: 'Time to hire global',
      gpp_time_to_hire_by_process: 'Time to hire por proceso',
      gpp_lifetime_processes: 'Tiempo de gestión de procesos',
      gpp_average_management_time_by_user: 'Tiempo medio de gestión por solicitante',
      hired_candidates_by_gender: 'Candidatos contratados por género',
      female_candidates_per_phase: 'Candidatas mujeres por fase',
      female_candidates_per_selection_status: 'Candidatas mujeres por estado',
      male_candidates_per_phase: 'Candidatos hombres por fase',
      male_candidates_per_selection_status: 'Candidatos hombres por estado',
      average_positions_per_candidate: 'Media de candidaturas por persona',
      tableColumns: {
        phase: 'Fase',
        state: 'Estado',
        offer: 'Oferta',
        notified: 'Notificados',
        interested: 'Interesados',
        position: 'Puesto',
        hired: 'Contratados',
        applied: 'Inscritos',
        feature: 'Funcionalidad',
        enabled: 'Activo',
        candidates: 'Candidatos',
        recruitment_source: 'Fuente',
        activity: 'Actividad',
        interviews: 'Entrevistas',
        hours: 'Hora del día',
        method: 'Método',
        accepted: 'Aceptados',
        percentage: '%',
        email: 'Usuario',
        total: 'Accesos',
        chatbot: 'Chatbot',
        chatbot_node: 'Nodo',
        chatbot_node_text: 'Pregunta',
        sessions: 'Sesiones totales',
        churn: 'Número de abandonos',
        churn_pct: 'Porcentaje de abandono',

        uncovered_position: 'Posición',
        uncovered_center: 'Centro',
        num_uncovered: 'Procesos sin cubrir',
        num_covered_manually: 'Número de procesos',

        gpp_solicitor: 'Solicitante',
        gpp_solicitor_name: 'Solicitante',
        gpp_cp_name: 'Centro de trabajo',
        gpp_cs_position: 'Posición',
        gpp_vr_name: 'Nombre del proceso',
        gpp_vr_closed_time: 'Días de gestión',
        gpp_vr_avg_closed_time: 'Media de gestión (días)',
        gpp_num_requests: 'Procesos creados',
        gpp_pending_pct: 'Pendientes aprobación',
        gpp_cancelled_pct: 'Anulados',
        gpp_rejected_pct: 'Rechazados',
        gpp_active_pct: 'En proceso',
        gpp_empty_pct: 'Pdte. preselección manual',
        gpp_inactive_pct: 'Expirados',
        gpp_closed_pct: 'Cerrados',

        gpp_pending: 'Pendientes aprobación',
        gpp_cancelled: 'Anulados',
        gpp_rejected: 'Rechazados',
        gpp_active: 'En proceso',
        gpp_empty: 'Pdte. preselección manual',
        gpp_inactive: 'Expirados',
        gpp_closed: 'Cerrados',

        gpp_validator: 'Validador',
        gpp_num_approved: 'Aprobados',
        gpp_num_rejected: 'Rechazados',

        gpp_division: 'División',
        gpp_area: 'Área',
        gpp_subarea: 'Subárea',
        gpp_unit: 'Centro',
        gpp_position: 'Posición',
        time_to_hire: 'Nº días en cerrar proceso'
      }
    }
  },
  templateRequestManager: {
    cloneProcess: 'Clonar proceso',
    pendingValidation: "Pendiente de validación",
    reviewed: 'Revisados',
    requestor: 'Solicitante',
    emptyDataSource: 'Aún no hay candidatos disponibles para este proceso. El Dpto. de Selección se encargará de gestionarlo a la mayor brevedad posible',
    of: 'de',
    emptyData: 'Sin contenido por ahora',
    rowsPerPage: 'Filas por página',
    emptyForm: 'Existen campos obligatorios sin completar',
    titleSection: 'Gestor de petición de plantilla',
    descriptionSection:
      'Crea, centraliza y organiza las peticiones del centro de trabajo de manera ágil, rápida y ordenada para gestionar las necesidades de selección.',
    createProcess: 'Crear proceso',
    filterProcess: 'Filtrar proceso',
    unit: 'Unidad',
    dateCreated: 'Fecha de creación',
    fullname: 'Nombre y apellidos',
    favorites: 'Favoritos',
    yearExperience: 'Años de experiencia',
    levelEducation: 'Nivel de estudios',
    dateValidation: 'Fecha de validación',
    cv: 'CV',
    phone: 'Teléfono',
    email: 'Email',
    address: 'Dirección',
    profile: 'Perfil',
    skills: 'Habilidades',
    match: 'Coincidencia deseables',
    phase: 'Fase',
    status: 'Estado',
    reason: 'Motivo',
    lastDisplayDateAt: 'Última visualización',
    lastCandidatureChangeStatusDateAt: 'Último cambio de estado',
    lastAppointment: 'Última citación',
    dateIncorporation: 'Fecha de incorporación',
    position: 'Puesto',
    shift: 'Turno',
    typeContract: 'Tipo de contrato',
    vacancies: 'Vacantes',
    proposedCandidates: 'Candidatos propuestos',
    interviewedCandidates: 'Candidatos entrevistados',
    statusProcess: 'Estado del proceso',
    newStatusProcess: 'Estado actual',
    processOpened: 'Procesos',
    buttonOpenedProcess: 'Abiertos',
    buttonClosedProcess: 'Cerrados',
    newProcess: 'Nuevo proceso',
    showProcess: 'Detalles del proceso',
    candidates: 'Candidatos',
    dependent: 'Dependiente/a',
    numberVacancies: 'N° de vacantes',
    numberVacanciesRequest: 'Nº de vacantes aprobadas/solicitadas',
    descriptionNewProcess:
      'Comienza un nuevo proceso. Personaliza aspectos cruciales como jornada, requisitos y tipo de contrato, entre otros. Define criterios que ayudarán a identificar a los candidatos adecuados para este proceso de selección',
    backToTemplateRequestManager: 'Volver a gestor de petición de plantilla',
    placeholderPosition: 'Selecciona el puesto',
    placeholderTypeContract: 'Selecciona el tipo de contrato',
    placeholderWorkplace: 'Selecciona el centro de trabajo',
    placeholderShift: 'Selecciona el turno',
    placeholderRequirementsRequired: 'Indica los requisitos imprescindibles',
    placeholderRequirementsDesirable: 'Indica los requisitos deseables',
    placeholderObservations: 'Indica tus observaciones',
    workplace: 'Centro de trabajo',
    requeriments: 'Requisitos',
    desirableRequirements: 'Requisitos deseables',
    mandatoryRequirements: 'Requisitos imprescindibles',
    observations: 'Observaciones',
    saveProcess: 'Guardar',
    titleSectionValidator: 'Validación de procesos',
    descriptionSectionValidator:
      'Valida los procesos de selección que han sido creados por los gestores de petición de plantilla. Revisa los detalles de cada proceso y aprueba o rechaza la solicitud.',
    statusProcessValidator: 'Estado del proceso',
    typeContractIndefinite: 'Indefinido',
    typeContractTemporary: 'Temporal',
    typeContractInterim: 'Interinidad',
    workingDayFullTime: 'Completa',
    workingDayPartTime: 'Parcial',
    workingDayHourly: 'Por horas',
    shiftMorning: 'Mañana',
    shiftAfternoon: 'Tarde',
    shiftFullTime: 'Completo',
    shiftNight: 'Noche',
    shiftWeekends: 'Fines de semana',
    vacancyStates: {
      cancelled: 'Proceso anulado',
      rejected: 'Proceso rechazado',
      active: 'Proceso aprobado',
      closed: 'Proceso cerrado',
      pending: 'Pendiente de aprobación',
      empty: 'Pendiente preselección manual',
      inactive: 'Proceso expirado'
    },
    vacancyReasons: {
      gap: 'GAP',
      replacement: 'Sustitución',
    },
    vacancyContract: {
      1: 'Contrato indefinido',
      2: 'Contrato temporal'
    },
    workingDayLabel: 'Jornada',
    placeholderWorkingDay: 'Selecciona la jornada',
    workingDay: {
      full_time: 'Completa',
      part_time: 'Parcial',
      weekend: 'Fines de semana',
    },
    workShiftLabel: 'Turno',
    placeholderWorkShift: 'Selecciona el turno',
    workShift: {
      morning_shift: 'Mañana',
      swing_shift: 'Tarde',
      night_shift: 'Noche',
      rotating_shift: 'Rotativo',
      split_shift: 'Partido',
      full_shift: 'Completo',
      intensive_shift: "Intensivo",
    },
    contractHoursLabel: 'Horas/semana',
    placeholderContractHours: 'Selecciona las horas por semana',
    contractHours: {
      40: '40 horas semanales',
      37: '37 horas semanales',
      35: '35 horas semanales',
      30: '30 horas semanales',
      20: '20 horas semanales',
      16: '16 horas semanales',
      15: '15 horas semanales',
      12: '12 horas semanales',
      10: '10 horas semanales',
      8: '8 horas semanales',
    },
    searchMode: 'Vinculación',
    searchModes: {
      direct: 'Asignación directa',
      algorithm: 'Algoritmo',
      repush: 'Repush'
    },
    actions: 'Acciones',
    acceptProcess: 'Aceptar proceso',
    rejectProcess: 'Rechazar proceso',
    cancelProcess: 'Anular proceso',
    cancelProcessTooltip: 'Si no deseas continuar con el proceso, anularlo hará que los candidatos sean liberados y el cierre del mismo.',
    closeProcess: 'Finalizar proceso',
    closeProcessTooltip: 'Si el proceso ha llegado a su fin consiguiendo las contrataciones deseadas, finalizar el proceso hará que el mismo concluya y no se pueda reabrir.',
    repushCandidatesTooltip: 'Rellenar el proceso con nuevos candidatos encontrados hasta completar el proceso.',
    repushCandidates: 'Rellenar candidatos',
    repushCandidatesSuccess: 'La solicitud de búsqueda de nuevos candidatos se ha ejecutado correctamente',
    repushCandidatesFullfilled: 'La petición de plantilla ya se encuentra con el número máximo de candidatos permitido.'
  },

  IAGen: {
    criterias: {
      "experience": "Experiencia",
      "EXPERIENCIA": "Experiencia",

      "education_training": "Estudios / Formación",
      "ESTUDIOS": "Estudios / Formación",

      "salary_expectation": "Expectativas salariales",
      "SALARIO": "Expectativas salariales",

      "employment_status": "Situación laboral",
      "SITUACION_LABORAL": "Situación laboral",

      "location": "Ubicación",
      "UBICACION": "Ubicación",

      "mobility": "Movilidad",
      "MOVILIDAD": "Movilidad",

      "availability": "Disponibilidad",
      "DISPONIBILIDAD": "Disponibilidad",

      "others": "Otros",

      "not_evaluated": "Sin evaluar"
    }
  }
}
