/* eslint-disable react/display-name */
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import {
  BoxInfoHeader,
  BoxKeyValue,
  BoxOtherInfo,
  BoxOtherInfoHeader,
  BoxProfileCite,
  BoxSelectionFunnel,
  BoxVideoSentiments,
  DocumentsList,
  DocumentsListViewer,
  HeaderProfileButtonsQuery,
  ObservationsTable,
  OfferStateImage,
  ProfileActionsButtonsQuery,
  ProfileStatsQuery,
  UserNameTitle,
  BoxVideoCandidate,
} from '../../molecules'
import ProfileInfoStyled from './styled'
import {MTDate, MTText, SpinnerIcon, IconEdit, MTCitationStatusTextColor} from '../../atoms'
import BoxPDAMVMInfo from "../../molecules/box-pda-mvm-info";
import BoxPDAEmergiaInfo from "../../molecules/box-pda-emergia-info";
import Divider from '@material-ui/core/Divider';
import JSZip from "jszip";
import _map from "lodash/map";
import * as S from "../profile-processes-info/styled";
import _get from "lodash/get";
import {Box, Tab, Tabs, Tooltip} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import DraggableModal from "../../molecules/draggable-modal/view";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MaterialTable from "material-table";
import routes from "../../../config/routes";
import OfferCandidatureStatusType from "../../../models/offer-candidature-status-type";
import {BACKEND_CLASSES, getCurrentSectionKey, MODULES, QUERY_KEYS, SECTION_KEYS} from "../../../config/constants";
import {useAppContext} from "../../../context/appContext";
import useTotalCount from "../../../api/queries/useTotalCount";
import {matchPath, useParams} from "react-router";
import useProfile from "../../../api/queries/useProfile";
import useObservations from "../../../api/queries/useObservations";
import {useMutation} from "@tanstack/react-query";
import * as api from "../../../api";
import {
  mutationChangeTagsCallback,
  mutationFavoritesCallback,
  mutationMarkAsReadCallback,
  mutationPostObservationCallback,
  refetchQueriesByKeys,
  invalidateQueriesByKeysWithNoModule,
} from "../../../api/mutations/mutationCallbacks";
import {toast} from "react-toastify";
import useInbox from "../../../api/queries/useInbox";
import usePushOfferByCandidature from "../../../api/queries/usePushOfferByCandidature";
import useTagsTypes from "../../../api/queries/useTagsTypes";
import useFavorites from "../../../api/queries/useFavorites";
import useSavedSearchesItem from "../../../api/queries/useSavedSearchesItem";
import useCitations from "../../../api/queries/useCitations";
import StatusGroupType from "../../../models/status-group-type";
import eventTracker from "../../../utils/eventTracker";
import BoxVideoInterview from "../../molecules/box-videointerview";
import usePhases from '../../../api/queries/usePhasesProcesses'
import { FeatureGuard } from '../../../utils/guard'

const UseInboxQuery = ({module, filters, range, sort, updateQueryData}) => {
  const { data, isFetched } = useInbox(module, filters, range, sort);
  updateQueryData(data, isFetched);

  return null;
}
const UseFavoritesQuery = ({module, filters, range, sort, updateQueryData}) => {
  const {data, isFetched} = useFavorites(module, filters, range, sort);
  updateQueryData(data, isFetched);

  return null;
}
const UsePlanningQuery = ({module, filters, range, sort, updateQueryData}) => {
  const {data, isFetched} = useCitations(module, filters, range, sort);
  updateQueryData(data, isFetched);

  return null;
}
const UseSavedSearchesItem = ({module, savedSearchItemId, filters, filterGroup, range, sort, updateQueryData}) => {
  let targetFilterGroup = filterGroup[SECTION_KEYS.SAVED_SEARCHES_ITEM] && filterGroup[SECTION_KEYS.SAVED_SEARCHES_ITEM][savedSearchItemId] ? filterGroup[SECTION_KEYS.SAVED_SEARCHES_ITEM][savedSearchItemId] : null;
  const {data, isFetched} = useSavedSearchesItem(module, savedSearchItemId, filters, targetFilterGroup, range, sort);
  updateQueryData(data, isFetched);

  return null;
}

const ProfileInfoQuery = ({
                            i18n,
                            fetchUserList,
                            profileMultipleData,
                            history,
                            isFetching,
                            isFetchingUserList,
                            showActions,
                            showFav,
                            showObsInput,
                            user,
                            client,
                            location,
                            citations,
                            filters: stateFilters,
                            responsibles,
                     }) => {

  const {filters, range, sort, savedSearchItems, filterGroup, module, clearContextIfNeeded} = useAppContext();
  const {savedSearchItemId, slug} = useParams();

  const [candidatureId, setCandidatureId] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const [currentStatusLabel, setCurrentStatusLabel] = useState('');
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [tabValue, setTabValue] = useState( 'notes' );
  const [interviewerNote, setInterviewerNote] = useState( '' );
  const [printNotes, setPrintNotes] = useState( false );
  const [showCvPreview, setShowCvPreview] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [targetInboxData, setTargetInboxData] = useState(citations.data ?? {});
  const [isFetchedTargetInboxData, setIsFetchedTargetInboxData] = useState(false);
  const [totalByStatusGroup, setTotalByStatusGroup] = useState(0);
  const [dniValue, setDniValue] = useState(null);
  const [candidatureTabsData, setCandidatureTabsData] = useState([]);
  const [candidatureTabValue, setCandidatureTabValue] = useState(0);

  // START - Child Modal States
  const [isCitationOpened, setIsCitationOpened] = useState(false);
  const [citationData, setCitationData] = useState(false);
  const [isRequestDocumentationOpened, setIsRequestDocumentationOpened] = useState(false);
  const [isAttachmentFilesOpened, setIsAttachmentFilesOpened] = useState(false);
  const [isBlacklistOpened, setIsBlacklistOpened] = useState(false);
  const [isDeleteCandidatureOpened, setIsDeleteCandidatureOpened] = useState(false);
  const [isOpenModalSuggestChangeStatus, setIsOpenModalSuggestChangeStatus] = useState(false);
  // END - Child Modal States

  const [isLabelSelectorOpened, setIsLabelSelectorOpened] = useState(false);

  useEffect(() => {
    if (slug) {
      if (slug && typeof slug === 'string' && slug.split('-').length > 1) {
        const ids = _.filter(slug.split('-'), id => !_.isEmpty(id))
        fetchUserList(ids).then(() => setTimeout(window.print, 300))
      } else {
        setCandidatureId(slug);
      }
    }
  }, [slug]);

  useEffect(() => {
    if (client && dniValue) {
      api.getCandidaturesByClientAndDni(client.id, dniValue).then(res => {
        let data = res ? res.data : null;
        if (data) {
          setCandidatureTabsData(res.data);
        }

        if (targetInboxData && targetInboxData.data) {
          let inboxData = targetInboxData.data;
          data.map(item => {
            if (_.find(inboxData, {id: item.id})) {
              let key = client && client.id ? `profileIdMapper-${client.id}` : 'profileIdMapper';
              localStorage.setItem(key, item.id)
            }
          })
        }
      })
    }
  }, [client, dniValue])

  useEffect(() => {
    clearContextIfNeeded(MODULES.currentModule());
  }, [module]);

  const getFiltersToQueryCount = () => {
    if (localStorage.getItem('currentOfferId')) {
      return filters[SECTION_KEYS.PUSH_OFFER_CANDIDATES];
    } else if (![SECTION_KEYS.SAVED_SEARCHES, SECTION_KEYS.SAVED_SEARCHES_ITEM].includes(getCurrentSectionKey())) {
      return filters[getCurrentSectionKey()]
    } else {
      return filters[getCurrentSectionKey()][savedSearchItemId];
    }
  }

  const getSlugToQueryCount = () => {
    if (localStorage.getItem('currentOfferId')) {
      return localStorage.getItem('currentOfferId');
    }
    return getCurrentSectionKey() === SECTION_KEYS.SAVED_SEARCHES_ITEM ? savedSearchItemId : null
  }

  const {data: totalRQuery, isFetched: isTotalRQueryFetched} = useTotalCount(
    module,
    getCurrentSectionKey(),
    getFiltersToQueryCount(),
    getSlugToQueryCount()
  );

  const isPlanningProfile = () => matchPath(location.pathname, {path: routes.profilePlanning}) !== null;
  const isPersonProfile = () => matchPath(location.pathname, {path: routes.profilePerson}) !== null;

  const {data: profileData} = useProfile(module, candidatureId);
  const {data: observations, isFetched: isObservationsFetched} = useObservations(
    module, profileData && profileData.person && profileData.person.id ? profileData.person.id : null);
  const {data: pushOffers, isFetched: isPushOfferFetched} = usePushOfferByCandidature(module, candidatureId);
  const {data: lastCitations, isFetched: isCitationsDataFetched} = useCitations(
    module,
    slug ? {"candidature.id": slug} : filters[SECTION_KEYS.PLANNING],
    range[SECTION_KEYS.PLANNING],
    sort[SECTION_KEYS.PLANNING]);

  const {data: tagsTypes} = useTagsTypes(module);

  useEffect(() => {
    eventTracker.track('AccessToCandidateProfile', {
      candidatureId: candidatureId,
      module: module
    });
  }, []);

  const mutationPostReads = useMutation(api.postReads_query);
  const mutationPostObservation = useMutation(api.postUserObservationsQuery);
  const mutationChangeTags = useMutation(api.changeTags_query);
  const mutationPostFavorites = useMutation(api.postFavorites_query);
  const mutationDeleteFavorites = useMutation(api.deleteFavorites_query);
  const mutationDeleteSelectedAttachments = useMutation(api.deleteSelectedAttachmentsQuery)

  const { data: phasesData } = usePhases(module, data => data)

  const updateQueryData = (data, isFetched) => {
    if (!isFetched) {
      return;
    }

    let targetData = data;
    if(isPlanningProfile() && data.data) {
      //Find in data by candidatureId
      targetData = {
        data: data.data.map((citation) => {
          return Object.assign(
            {},
            citation.candidature,
            { itemCount: citation.itemCount }
          );
        }),
        total: data.total
      };
    }
    if (!_.isEqual(targetData, targetInboxData)) {
      setTargetInboxData(targetData);
      setIsFetchedTargetInboxData(isFetched);
    }
  }

  const getInboxDataBySection = () => {
    let currentFilters = ![SECTION_KEYS.SAVED_SEARCHES_ITEM].includes(getCurrentSectionKey()) ? filters[getCurrentSectionKey()] : filters[getCurrentSectionKey()][savedSearchItemId];
    if (isPlanningProfile()) {
      currentFilters = stateFilters
    } else if (isPersonProfile() && localStorage.getItem('currentOfferId')) {
      currentFilters = filters[SECTION_KEYS.PUSH_OFFER_CANDIDATES];
    }
    let props = {
      module: module,
      sectionKey: getCurrentSectionKey(),
      filters: currentFilters,
      range: range[getCurrentSectionKey()],
      sort: sort[getCurrentSectionKey()],
      updateQueryData
    };

    switch (getCurrentSectionKey()) {
      case SECTION_KEYS.INBOX:
        return <UseInboxQuery {...props}/>
      case SECTION_KEYS.FAVORITES:
        return <UseFavoritesQuery {...props}/>
      case SECTION_KEYS.PLANNING:
        return <UsePlanningQuery {...props}/>
      case SECTION_KEYS.SAVED_SEARCHES_ITEM:
        // eslint-disable-next-line
        let strFilters = savedSearchItems && savedSearchItems[savedSearchItemId] ? savedSearchItems[savedSearchItemId].fields : null;
        props.filters = strFilters ? JSON.parse(strFilters) : {};
        props.filterGroup = filterGroup;
        props.savedSearchItemId = savedSearchItemId;
        return <UseSavedSearchesItem {...props}/>
    }
  }

  const InboxBySectionComponent = () => {
    return getInboxDataBySection();
  }


  useEffect(() => {
    if (totalRQuery && getCurrentSectionKey() === SECTION_KEYS.SAVED_SEARCHES_ITEM ) {

      let currentFilterGroup = JSON.stringify(filterGroup[getCurrentSectionKey()]);
      currentFilterGroup = JSON.parse(currentFilterGroup);
      currentFilterGroup =  currentFilterGroup[savedSearchItemId] ? currentFilterGroup[savedSearchItemId] : 1;

      let currentGroupStatus = _.find(StatusGroupType, {id: currentFilterGroup});
      currentGroupStatus = currentGroupStatus ? currentGroupStatus.status : null

      if (currentGroupStatus) {
        let counter = 0;
        let keys = Object.keys(totalRQuery);

        keys.map(key => {
          let status = key.split('_').pop();
          status = status ? +status : null;

          if (currentGroupStatus.includes(status)) {
            counter += totalRQuery[key];
          }
        });

        setTotalByStatusGroup(counter);
      }
    }
  }, [totalRQuery, filterGroup]);

  const pushOfferTableColumns = [
    { title: i18n.pushOfferTable.name, field: 'name', render: row => <MTText value={row.name}/>},
    { title: i18n.pushOfferTable.description, field: 'description', render: row =>
        <Tooltip title={<p dangerouslySetInnerHTML={{__html: row.description}}></p>}>
          <p className="offer_description"
             dangerouslySetInnerHTML={{__html: row.description}}>
          </p>
        </Tooltip>
    },
    { title: i18n.pushOfferTable.userName, field: 'userName', render: row => <MTText className="offer_user_name" value={row.userName}/>  },
    { title: i18n.pushOfferTable.createdAt, field: 'createdAt', render: row => row.createdAt ? <MTDate date={row.createdAt}/> : '-' },
    { title: i18n.pushOfferTable.state, field: 'state', render: row => {
        let icon = null;
        switch (row.state) {
          case OfferCandidatureStatusType.INTERESTED:
            icon = <OfferStateImage state={OfferCandidatureStatusType.INTERESTED} bigSize={true}></OfferStateImage>
            break;
          case OfferCandidatureStatusType.NOT_INTERESTED:
            icon = <OfferStateImage state={OfferCandidatureStatusType.NOT_INTERESTED} bigSize={true}></OfferStateImage>
            break;
          case OfferCandidatureStatusType.UNANSWERED:
            icon = <OfferStateImage state={OfferCandidatureStatusType.UNANSWERED} bigSize={true}></OfferStateImage>
            break;
          default:
            break;
        }
        return icon;
      }
    },
  ];

  const getReadClass = rowData => (_.get(rowData, 'candidature.was_read', false) ? 'was_read' : 'not_read')
  const MTAttendees = ( rowData ) => {
    let attendees = '';
    rowData.attendees && rowData.attendees.map( ( attendee ) => {
        attendees += attendee.emailAddress && ', ' + attendee.emailAddress.address ;
    });

    let attendeesText = rowData.names + "" + attendees;

    if (attendeesText.length > 20) {
      attendeesText = attendeesText.substring(0, 20) + ' ...';
    }
    return <MTText title={rowData.names + "" + attendees} style={{'text-overflow': 'ellipsis'}} className={getReadClass(rowData)} value={ attendeesText }/>
  };

  const lastCitationsTableColumns = [
    { title: i18n.lastCitationsTable.date, field: 'name', render: row => row.date.date ? <MTDate date={row.date.date}/> : '-'},
    { title: i18n.lastCitationsTable.interviewDate, field: 'interviewDate', render: row => row.citationDate.date ? <MTDate date={row.citationDate.date}/> : '-'},
    { title: i18n.lastCitationsTable.location, field: 'location', render: row => <MTText value={row.location}/>},
    { title: i18n.lastCitationsTable.userName, field: 'userName', render: row => <MTText value={row.username}/>},
    { title: i18n.lastCitationsTable.attendees, field: 'attendees', render: MTAttendees},
    { title: i18n.lastCitationsTable.statusAnswer, field: 'statusAnswer', render: row => <MTCitationStatusTextColor value={row.status} />},
    { title: i18n.lastCitationsTable.reason, field: 'reason', render: row => {
        if (!['replan', 'rejected'].includes(row.status)) {
          return <MTText title={'-'} value={ '-' }/>
        }
        let reason = '';
        if (row?.not_interested_label) {
          reason += row.not_interested_label;
        } else if (row?.availability_label) {
          reason += row.availability_label + ' ' + row.reason;
        }
        return <MTText title={reason ? reason : '-'} value={ reason ? reason : '-' }/>
      }},
    { title: i18n.lastCitationsTable.editCitation, field: 'editCitation', render: row => <div className='custom-edit' onClick={() => handleEditIconClick(row)} ><IconEdit /></div>  },
  ];

  const handleEditIconClick = (row) => {
    setCitationData(row);
    setIsCitationOpened(true);
  };

  const tableOptions = {
    emptyRowsWhenPaging: false,
    loadingType: 'linear',
    cellStyle: { textAlign: 'center' },
    pageSize: 20,
    pageSizeOptions: [20, 50, 100],
    toolbar: false,
    draggable: false,
  }

  //Tab Panel
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  useEffect(() => {
    if (profileData) {
      setIsFavorite(profileData.is_favorite);
      let person = profileData.person ?? null;
      setInterviewerNote(person ? person.nota_entrevistador : profileData.nota_entrevistador);
      setCurrentStatusLabel(i18n.status[_.get(profileData, 'state.id', -1)]);

      if (!profileData.was_read) {
        mutationPostReads.mutateAsync({ids: [candidatureId], module},
          mutationMarkAsReadCallback([candidatureId], module, profileData, true))
          .then(() => {
            let queriesToRefetch = [
              `${module}:${QUERY_KEYS.INBOX}`,
              `${module}:${QUERY_KEYS.FAVORITES}`,
            ];

            if (getCurrentSectionKey() === SECTION_KEYS.SAVED_SEARCHES_ITEM && savedSearchItemId && filterGroup && filterGroup[SECTION_KEYS.SAVED_SEARCHES_ITEM] && filterGroup[SECTION_KEYS.SAVED_SEARCHES_ITEM][savedSearchItemId]) {
              queriesToRefetch.push(`${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:${filterGroup[SECTION_KEYS.SAVED_SEARCHES_ITEM][savedSearchItemId]}`);
            }
            refetchQueriesByKeys(queriesToRefetch);
          });
      }
      let cv = _get(profileData, 'cv');
      if (cv && cv.length > 0) {
        let cvMainUrl = cv.split('?').shift();
        // eslint-disable-next-line
        let extension = cvMainUrl.split('.').pop();
        setShowCvPreview(true);
      }

      let atts = _get(profileData, 'attachments');
      if(atts && !Array.isArray(atts) && Object.keys(atts).length > 0){
        atts = [atts];
      }

      if (Array.isArray(atts)) {
        setAttachments(atts);
      }
    }

  }, [profileData])

  const handleAddObservation = (value) => {
    mutationPostObservation.mutateAsync({id: profileData && profileData.person && profileData.person.id ? profileData.person.id : null, change_msg: value, module, type: 'person'},
      mutationPostObservationCallback(
        candidatureId, value,  module, observations))
      .then(() => toast.success(i18n.toast_msg.observation.success))
      .catch(() => toast.error(i18n.toast_msg.observation.error));
  }

  const onSubmitProfileChangeTags = (ids, tags, module) => {
    mutationChangeTags.mutateAsync({ids, tags, module}).then(() => {
      let queriesToRefetch = [
        `${module}:${QUERY_KEYS.PROFILE}:${slug}`,
        `${module}:${QUERY_KEYS.INBOX}`,
        `${module}:${QUERY_KEYS.FAVORITES}`,
        `${module}:${QUERY_KEYS.OBSERVATIONS}:${slug}`,
      ];

      if (getCurrentSectionKey() === SECTION_KEYS.SAVED_SEARCHES_ITEM && savedSearchItemId && filterGroup && filterGroup[SECTION_KEYS.SAVED_SEARCHES_ITEM] && filterGroup[SECTION_KEYS.SAVED_SEARCHES_ITEM][savedSearchItemId]) {
        queriesToRefetch.push(`${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:${filterGroup[SECTION_KEYS.SAVED_SEARCHES_ITEM][savedSearchItemId]}`);
      }
      refetchQueriesByKeys(queriesToRefetch);
    });
  }

  const handleOnClickFav = () => {
    if (isFavorite) {
      mutationDeleteFavorites.mutateAsync({ids: [candidatureId], module},
        mutationFavoritesCallback([candidatureId], module, getCurrentSectionKey(), isFavorite, targetInboxData
      )).then(() => {
        setIsFavorite(false);
        let queriesToRefetch = [
          `${module}:${QUERY_KEYS.INBOX}`,
          `${module}:${QUERY_KEYS.FAVORITES}`,
        ];

        if (getCurrentSectionKey() === SECTION_KEYS.SAVED_SEARCHES_ITEM && savedSearchItemId && filterGroup && filterGroup[SECTION_KEYS.SAVED_SEARCHES_ITEM] && filterGroup[SECTION_KEYS.SAVED_SEARCHES_ITEM][savedSearchItemId]) {
          queriesToRefetch.push(`${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:${filterGroup[SECTION_KEYS.SAVED_SEARCHES_ITEM][savedSearchItemId]}`);
        }
        refetchQueriesByKeys(queriesToRefetch);
      });
    } else {
      mutationPostFavorites.mutateAsync({ids: [candidatureId], module},
        mutationFavoritesCallback([candidatureId], module, getCurrentSectionKey(), isFavorite, targetInboxData
      )).then(() => {
        setIsFavorite(true);
        let queriesToRefetch = [
          `${module}:${QUERY_KEYS.INBOX}`,
          `${module}:${QUERY_KEYS.FAVORITES}`,
        ];

        if (getCurrentSectionKey() === SECTION_KEYS.SAVED_SEARCHES_ITEM && savedSearchItemId && filterGroup && filterGroup[SECTION_KEYS.SAVED_SEARCHES_ITEM] && filterGroup[SECTION_KEYS.SAVED_SEARCHES_ITEM][savedSearchItemId]) {
          queriesToRefetch.push(`${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:${filterGroup[SECTION_KEYS.SAVED_SEARCHES_ITEM][savedSearchItemId]}`);
        }
        refetchQueriesByKeys(queriesToRefetch);
      });
    }
  }

  const formatCV = (cvFile) => {
    if (cvFile) {
      let cvMainUrl = cvFile.split('?').shift();
      let cvName = cvMainUrl.split('/').pop();
      let originalUrl = cvMainUrl.split('.com/').pop();

      let cvObj = {
        id: originalUrl,
        name: decodeURI(cvName),
        file: cvFile,
      }
      return [cvObj];
    }
    return [];
  }

  const formatAttachments = data => {
    if (!data) return []
    if (data.length <= 1 && "id" in data) data = [data]

    let files = _map(data, ({id, original_name, url}) => {
      return {id, name: original_name, file: url}
    });
    //let cv = formatCV(_get(profileData, 'cv'), true);
    //if (!_.isEmpty(cv)) files.push(cv)

    return files;
  }

  const downloadBlob = (blob, name = 'myFile') => {
    const blobUrl = URL.createObjectURL(blob)
    const link = document.createElement('a')

    link.href = blobUrl
    link.download = name
    document.body.appendChild(link)
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    )
    document.body.removeChild(link)
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }} style={{padding: "0px"}}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps( index ) {
    return {
      id: `simple-tab-${ index }`,
      value: index,
      'aria-controls': `simple-tabpanel-${ index }`,
    };
  }

  const handleTabChange = ( event, newValue ) => {
    setTabValue( newValue );
  };
  const handleCandidatureTabChange = (event, newValue) => {
    setCandidatureTabValue(newValue)
  }
  const getInterviewerNote = () => {
    return {__html: _.split( interviewerNote, '-*{}*-')[0]};
  }

  const getInterviewerNoteAuthor = () => {
    if(_.isEmpty(interviewerNote))
    {
      return '';
    }
    //Pasamos la traducción
    return i18n.interviewerNotes.updatedAt
      .replace('{author}',_.split(interviewerNote, '-*{}*-')[1])
      .replace('{date}', _.split(interviewerNote, '-*{}*-')[3])
      .replace('{hour}', _.split(interviewerNote, '-*{}*-')[2]);

    //i18n.modalAnnouncement.confirmation[!isEdit ? 'description_create' : 'description_update'].replaceAll('{num}'
  }

  const handleFileDelete = () => {
    const filesToDelete = selectedDocuments.map(file => {
      return file.id
    })

    mutationDeleteSelectedAttachments.mutateAsync({ entityId: slug, files: filesToDelete, module })
      .then(() => {
        let queriesToRefetch = [
          `${module}:${QUERY_KEYS.PROFILE}:${slug}`,
        ];
        let queriesToInvalidate = candidatureTabsData.map(candidature => candidature.id != slug ? `${module}:${QUERY_KEYS.PROFILE}:${candidature.id}` : null)

        invalidateQueriesByKeysWithNoModule(queriesToInvalidate);
        refetchQueriesByKeys(queriesToRefetch);
      });
  }

  const handleDownload = () => {
    const newDocuments = selectedDocuments.map(item => {
      return fetch(item.file, {cache: 'no-cache'})
        .then(response => response.arrayBuffer())
        .then(arrayBuffer => {
          return Promise.resolve({
            data: Buffer.from(arrayBuffer, 'binary').toString('base64'),
            name: item.name,
            file: item.file
          })
        });
    })

    var zip = new JSZip()
    Promise.all(newDocuments).then(response => {
      response.map(item => zip.file(item.name, item.data, {base64: true}))
      zip.generateAsync({type: 'blob'}).then(function (content) {
        downloadBlob(content, 'files_' + moment().format('YYYYMMDD_Hms') + '.zip')
      })
    })
  }

  const handleOfferRowClick = (event, rowData) => {
    event.preventDefault();
    if (rowData && rowData.offerId) {
      history.push(routes.offerCandidatures.replace(':slug', rowData.offerId))
    }
  }

  const renderTabs = ( profileData ) => {
    if (!profileData || isFetching || !isObservationsFetched || !responsibles) {
      return <SpinnerIcon/>
    } else {
      return <>
        <Grid container xs={12} spacing={ 2 } style={{marginTop: "50px", marginBottom:"0px"}}>

          <Grid item xs={ 12 }>

            <Box sx={{ m:-2 }}>

              {/* Tabs */}
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={"no-print"} >
                <Tabs value={ tabValue } onChange={ handleTabChange } aria-label="basic tabs example" >
                  <Tab label={i18n.interviewerNotes.titleTab} className={ `documentsInfoTitle tab-item ${ printNotes ? 'print' : 'no-print' }` } {...a11yProps('notes')} />
                  <Tab label={i18n.observationsTable.title} className={"documentsInfoTitle tab-item"} {...a11yProps('observations')} />
                  {FeatureGuard.canAccessToPushOffers() && <Tab label={i18n.pushOfferTable.title} className={"documentsInfoTitle tab-item"} {...a11yProps('push-offers')} />}
                  {FeatureGuard.canAccessToFunnelStatus() && <Tab label={i18n.lastCitationsTable.title} className={"documentsInfoTitle tab-item"} {...a11yProps('last-citations')} />}
                </Tabs>
              </Box>

              {/*Notas entrevista*/}
              <TabPanel value={ tabValue } index={'notes'} >
                <Grid id="interviewer_notes_container" spacing={10} item style={{padding: "30px"}}
                      className={printNotes ? 'print' : 'no-print'}>
                  <p className={`interview-otes-title-print ${ printNotes ? 'print' : 'no-print' }` }>{ i18n.interviewerNotes.titleTab }</p>
                  <p className={"interviewerNote"} dangerouslySetInnerHTML={getInterviewerNote()}></p>
                  <Grid className={"no-print"}>
                    <DraggableModal user={user} titleTab={i18n.interviewerNotes.titleTab} module={module}
                                    id={profileData && profileData.person ? profileData.person.id : candidatureId}
                                    className={BACKEND_CLASSES.PERSON}
                                    slug={slug} interviewerNote={interviewerNote} setInterviewerNote={setInterviewerNote}/>
                    <FormControlLabel control={<Checkbox checked={ printNotes } style={{ marginLeft: "15px" }} onClick={ () => setPrintNotes( !printNotes ) }/>} label={ i18n.interviewerNotes.showOnPrint } />
                    <span className={"interviewerNote_metadata pull-right"}>{getInterviewerNoteAuthor()}</span>
                  </Grid>
                </Grid>
              </TabPanel>

              {/*Observaciones*/}
              <TabPanel value={ tabValue } index={'observations'} >
                <Grid style={{padding: "0px"}} spacing={2} container  className={!observations || observations.length === 0 ? 'no-print' : 'print'}>
                  <Grid id="observations-wrapper" item xs={12} style={{marginTop:"-10px"}} >
                    {!observations || (!isObservationsFetched && <SpinnerIcon styledType={'overlay-spinner'}/>)}
                    <ObservationsTable id='boxObsevationsContainer'
                                       showObsInput={showObsInput}
                                       handleAddObservation={handleAddObservation}
                                       observations={observations}
                    />
                  </Grid>
                </Grid>
              </TabPanel>

              {/*Ofertas Push*/}
              {FeatureGuard.canAccessToPushOffers() && <TabPanel value={ tabValue } index={'push-offers'} >
                <Grid style={{padding: 0, marginLeft: 3}} spacing={2} container  className={!observations || observations.length === 0 ? 'no-print' : 'print'}>
                  <Grid id="push-offers-wrapper" className="offer_table_container" item xs={12} style={{marginTop:"8px"}} >
                    {!pushOffers || (!isPushOfferFetched && <SpinnerIcon styledType={'overlay-spinner'}/>)}
                    <MaterialTable
                      data={pushOffers}
                      onRowClick={handleOfferRowClick}
                      columns={pushOfferTableColumns}
                      options={tableOptions}
                      localization={i18n.tableLocalization}
                    />
                  </Grid>
                </Grid>
              </TabPanel>}

              {/*Últimas Citaciones*/}
              {FeatureGuard.canAccessToFunnelStatus() && <TabPanel value={ tabValue } index={'last-citations'} >
                <Grid style={{padding: 0, marginLeft: 3}} spacing={2} container  className={!observations || observations.length === 0 ? 'no-print' : 'print'}>
                  <Grid id="last-citations-wrapper"  className="last_citations_table_container" item xs={12} style={{marginTop:"8px"}} >
                    {!lastCitations || (!isCitationsDataFetched && <SpinnerIcon styledType={'overlay-spinner'}/>)}
                    <MaterialTable
                      data={lastCitations && lastCitations.data ? lastCitations.data : []}
                      columns={lastCitationsTableColumns}
                      options={tableOptions}
                      localization={i18n.tableLocalization}
                    />
                  </Grid>
                </Grid>
              </TabPanel>}
            </Box>
          </Grid>
        </Grid>
      </>
    }
  }
    
  const renderProfile = (candidatureId, profileData, showActions, showFav, showObsInput) => {
    const candidature = _.get(profileData, 'candidatura', '')
    const workingDay = _.get(profileData, 'jornada', '')
    const userName = `${_.get(profileData, 'nombre', '')} ${_.get(profileData, 'apellidos', '')}`
    const responsibleCitation = _.get(profileData, 'citation.user.name', '')
    const statusId = _.get(profileData, 'state.id', -1)
    const currentStatus = i18n.status[statusId]
    const currentStatusColor = profileData.state.color;
    const photo = _.get(profileData, 'foto', null)
    const profileValue = Math.round(_.get(profileData, 'scoring_profile', ''))
    const matchValue = Math.round(_.get(profileData, 'matching_total', ''))
    const apto = _.get(profileData, 'apto', undefined)
    const skill1 = _.get(profileData, 'skill_score1', '')
    const skill2 = _.get(profileData, 'skill_score2', '')
    const skill3 = _.get(profileData, 'skill_score3', '')
    const skill4 = _.get(profileData, 'skill_score4', '')
    const skillsValue = Math.round(_.get(profileData, 'scoring_skills', ''))
    const skills = [skill1, skill2, skill3, skill4]

    const hasSkills = () => {
      return !(skill1.type && skill1.type.name === 'Vacio'
        && skill2.type && skill2.type.name === 'Vacio'
        && skill3.type && skill3.type.name === 'Vacio'
        && skill4.type && skill4.type.name === 'Vacio');
    }

    let origin = _.get(profileData, 'fuente_recrutamiento', '')
    let formation = _.get(profileData, 'formacion', '')
    let experience = _.get(profileData, 'anos_experiencia', '')
    let lastJob = _.get(profileData, 'ultimo_puesto', '')

    const hasFormationExperienceOrLastJob = () => formation || experience || lastJob

    let email = _.get(profileData, 'email', '')
    let phone = _.get(profileData, 'telefono', '')
    let tags = _.get(profileData, 'tags', 'os_experiencia', '')
    let address = _.get(profileData, 'direccion', '')
    let location = _.get(profileData, 'cp', '')
    let zone = _.get(profileData, 'zona_deseada', '')
    let variableFields = _.get(profileData, 'variable_fields', '')

    let availability = _.get(profileData, 'disponibilidad', '')
    availability = moment(availability).isValid() ? moment(availability).format('DD/MM/YYYY') : availability
    let citationDate = _.get(profileData, 'citation.citation_date', '')
    citationDate = citationDate ? moment(citationDate).format('DD/MM/YYYY') : ''
    let hiringDate = _.get(profileData, 'hiring_date', '')
    hiringDate = hiringDate ? moment(hiringDate).format('DD/MM/YYYY') : ''
    let dateOfBirth = _.get(profileData, 'fecha_nacimiento', '')
    const dateOfBirthFormat = moment(dateOfBirth, _.includes(dateOfBirth, '-') ? 'YYYY-MM-DD' : 'DD/MM/YYYY')
    const formatYears = dateOfBirth ? `${moment().diff(dateOfBirthFormat.format('YYYY-MM-DD'), 'years')} ${i18n.profileInfo.years}` : ''
    dateOfBirth = dateOfBirth ? `${formatYears} - ${dateOfBirthFormat.format('DD/MM/YYYY')}` : ''
    const yearsOld = _.get(profileData, 'edad') ? `${_.get(profileData, 'edad')} ${i18n.profileInfo.years} ` : ''
    const years = dateOfBirth ? dateOfBirth : yearsOld
    let createdAt = _.get(profileData, 'created_at', '');
    createdAt = moment(createdAt).isValid() ? moment(createdAt).format('DD/MM/YYYY') : createdAt;
    let updatedAt = _.get(profileData, 'updated_at', '');
    updatedAt = moment(updatedAt).isValid() ? moment(updatedAt).format('DD/MM/YYYY') : updatedAt;
    let pullSentAt = _.get(profileData, 'pull_sent_at', '');
    pullSentAt = moment(pullSentAt).isValid() ? moment(pullSentAt).format('DD/MM/YYYY') : pullSentAt;
    let isPullAccepted = _.get(profileData, 'is_pull_accepted', null);
    let evaluative_config = _.get(profileData, 'evaluative_config', []);
    if(evaluative_config.id){
      evaluative_config = [evaluative_config];
    }

    const dni = _.get(profileData, 'dni', '')
    setDniValue(dni);

    const files = []
    const cvFile = _.get(profileData, 'cv', '')
    const videoAnalysis = _.get(profileData, 'video_analysis', '');
    const videoUrl = _.get(profileData, 'video', '')
    const attached1 = _.get(profileData, 'adjunto1', '')
    const attached2 = _.get(profileData, 'adjunto2', '')
    const attached3 = _.get(profileData, 'adjunto3', '')
    const attached4 = _.get(profileData, 'adjunto4', '')
    const attached5 = _.get(profileData, 'adjunto5', '')
    const attached6 = _.get(profileData, 'adjunto6', '')
    const attached7 = _.get(profileData, 'adjunto7', '')
    const attached8 = _.get(profileData, 'adjunto8', '')
    const attached9 = _.get(profileData, 'adjunto9', '')
    const attached10 = _.get(profileData, 'adjunto10', '')

    cvFile && files.push({label: i18n.profileInfo.cv, url: cvFile})
    videoUrl && files.push({label: i18n.profileInfo.video, url: videoUrl})
    attached1 && files.push({label: `${i18n.profileInfo.attached} 1`, url: attached1})
    attached2 && files.push({label: `${i18n.profileInfo.attached} 2`, url: attached2})
    attached3 && files.push({label: `${i18n.profileInfo.attached} 3`, url: attached3})
    attached4 && files.push({label: `${i18n.profileInfo.attached} 4`, url: attached4})
    attached5 && files.push({label: `${i18n.profileInfo.attached} 5`, url: attached5})
    attached6 && files.push({label: `${i18n.profileInfo.attached} 6`, url: attached6})
    attached7 && files.push({label: `${i18n.profileInfo.attached} 7`, url: attached7})
    attached8 && files.push({label: `${i18n.profileInfo.attached} 8`, url: attached8})
    attached9 && files.push({label: `${i18n.profileInfo.attached} 9`, url: attached9})
    attached10 && files.push({label: `${i18n.profileInfo.attached} 10`, url: attached10})

    const createCandidatureTabs = () => {
      return <Tabs value={ candidatureTabValue } onChange={ handleCandidatureTabChange }
                   className="candidature_tabs_container" aria-label="basic tabs example" >
        {candidatureTabsData.map((item) => {
          return <Tab tabIndex={item.candidatura.id} key={item.id}
                      onClick={() => history.push(window.location.pathname.replace(candidatureId, item.id))}
                      label={item.candidatura.name}
                      className={ `candidatureTabs tab-item ${item.candidatura.name === candidature ? 'candidature_tab_selected': 'candidature_tab_not_selected'}` }
                      {...a11yProps(item.id)}/>
        })}
      </Tabs>
    }

    const getTotalRQueryCounter = () => {
      if (isTotalRQueryFetched && totalRQuery) {
        if (getCurrentSectionKey() === SECTION_KEYS.SAVED_SEARCHES_ITEM) {
          return totalByStatusGroup;

        } else if (targetInboxData.total) {
          return targetInboxData.total;

        } else {
          return totalRQuery.total;
        }
      }
      return 0;
    };

    return (
      <ProfileInfoStyled id='profilePrint'>
        {module && getCurrentSectionKey() && <InboxBySectionComponent/>}
        {showActions && (
          <div className="no-print">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <HeaderProfileButtonsQuery
                  userName={userName}
                  candidatureId={candidatureId}
                  setCandidatureId={setCandidatureId}
                  targetInboxData={targetInboxData && targetInboxData.hasOwnProperty('data') ? targetInboxData.data : targetInboxData}
                  isFetchedTargetInboxData={isFetchedTargetInboxData}
                  setIsFetchedTargetInboxData={setIsFetchedTargetInboxData}
                  module={module}
                  userId={profileData.id}
                  totalRQuery={getTotalRQueryCounter()}
                />
              </Grid>
            </Grid>
          </div>
        )}
        <Grid container>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={"tabpanel_box no-print"} >
              {candidatureTabsData && createCandidatureTabs()}
          </Box>
        </Grid>
        <Grid id='firstSectionStyle' container spacing={4}>
          <Grid className='profileActionButtonsWrapper' justifyContent='space-between' container item lg={12}>
            <Grid id="ProfileActionsButtons" container item lg={12}>
              <ProfileActionsButtonsQuery
                currentStatusColor={currentStatusColor}
                files={files}
                currentStatus={currentStatusLabel}
                setCurrentStatus={setCurrentStatusLabel}
                userName={userName}
                candidatureId={candidatureId}
                setCandidatureId={setCandidatureId}
                targetInboxData={targetInboxData && targetInboxData.hasOwnProperty('data') ? targetInboxData.data : targetInboxData}
                module={module}
                statusData={profileData.selection_statuses}
                citationModalStatus={{ isCitationOpened, setIsCitationOpened }}
                requestDocumentationModalStatus={{ isRequestDocumentationOpened, setIsRequestDocumentationOpened }}
                attachmentFilesModalStatus={{ isAttachmentFilesOpened, setIsAttachmentFilesOpened }}
                blacklistModalStatus={{ isBlacklistOpened, setIsBlacklistOpened }}
                deleteCandidatureModalStatus={{ isDeleteCandidatureOpened, setIsDeleteCandidatureOpened }}
                suggestChangeStatusModal={{ isOpenModalSuggestChangeStatus, setIsOpenModalSuggestChangeStatus}}
                citationData={{citationData, setCitationData}}
              />
            </Grid>
            <Divider className='divider-horizontal' variant="fullWidth"/>
          </Grid>

          {profileData.selection_statuses &&
            <Grid item lg={12} style={ {width : 'inherit'} } justifyContent='center'>
             <BoxSelectionFunnel profileData={profileData} i18n={i18n} phasesData={phasesData}/>
            </Grid>
          }

          <Grid className={"profileStatsInfo-print"} direction="row" container item lg={12}>
            <Grid spacing={4} item lg={6}>
              {/* TODO: maybe verify tagsType not procced */}
              {tagsTypes &&
                <ProfileStatsQuery
                  showFav={showFav}
                  isFav={isFavorite}
                  handleOnClickFav={handleOnClickFav}
                  avatarImage={photo}
                  info2={years}
                  titleInfo3={i18n.profileInfo.availableFrom}
                  titleInfo4={''}
                  info4={dni}
                  mail={email}
                  tel={phone}
                  tags={tags}
                  tagsTypes={tagsTypes}
                  userName={userName}
                  candidatureId={candidatureId}
                  changeTags={onSubmitProfileChangeTags}
                  module={module}
                  createdAt={createdAt}
                  updatedAt={updatedAt}
                  pullSentAt={pullSentAt}
                  isPullAccepted={isPullAccepted}
                  valid={apto}
                  labelSelectorStatus={{isLabelSelectorOpened, setIsLabelSelectorOpened}}
                />}
            </Grid>
            <Grid className="otherInfoContainer" spacing={4} item lg={6}>
              <BoxOtherInfoHeader
                titleInfo1={i18n.profileInfo.city}
                titleInfo2={i18n.profileInfo.desiredLocation}
                titleInfo3={i18n.profileInfo.desiredZone}
                value1={address}
                value2={location}
                value3={zone}
                actualStatus={currentStatus}
                origin={origin}
                workingDay={workingDay}
                availability={availability}
              />

            </Grid>
          </Grid>

          {(matchValue || profileValue || skillsValue) ? (
          <Grid id='analiticSection' direction="row" container item lg={12} spacing={4}>
            <Grid id="boxInfoHeaderGridContainer" item lg={hasSkills() ? 6 : 12}>
              <BoxInfoHeader
                totalMatch={matchValue}
                profileValue={profileValue}
                valid={apto}
                title={i18n.profileInfo.profile}
                titleInfo1={i18n.profileInfo.formation}
                titleInfo2={i18n.profileInfo.exp}
                titleInfo3={i18n.profileInfo.lastJob}
                value1={formation}
                value2={experience}
                value3={lastJob}
                noSkillsRender={!hasSkills()}
                showAdditionalInfo={hasFormationExperienceOrLastJob()}
              />
            </Grid>
            {hasSkills() && <Grid id='boxProfileCiteGridContainer' item lg={6}>
              <BoxProfileCite
                date={citationDate}
                hiringDate={hiringDate}
                responsible={responsibleCitation}
                skillValue={skillsValue}
                skills={skills}
              />
            </Grid>}
          </Grid>) : null}
        </Grid>

        <Grid spacing={4} container>

          {evaluative_config.length > 0 &&
            <Grid container className="pagebreakavoid">
                <div className="header-table">
                  <p>{i18n.evaluativeTests.titleBoxProfileInbox}</p>
                </div>
                {evaluative_config.length ?
                  evaluative_config.map((config, index) => (
                    <Grid item xs={12} key={index}>
                      <BoxVideoInterview evaluativeConfig={config} profileData={profileData} i18n={i18n} />
                    </Grid>
                  )) : null
                }                
            </Grid>
          }
          {profileData.integrations_pda_emergia &&
            <Grid container className="pagebreakavoid">
              <Grid item xs={12}>
                <BoxPDAEmergiaInfo pdaFields={profileData.integrations_pda_emergia}/>
              </Grid>
            </Grid>
          }

          {profileData.integrations_pda &&
            <Grid container className="pagebreakavoid">
              <Grid item xs={12}>
                <BoxPDAMVMInfo pdaFields={profileData.integrations_pda}/>
              </Grid>
            </Grid>
          }

          {profileData.nearby_places && profileData.nearby_places.length > 0 &&
          <Grid container className="nearbyPlacesWrapper pagebreakavoid">
            <Grid item xs={12}>
              <BoxKeyValue
                title={i18n.boxOtherInfo.nearbyPlaces}
                data={profileData.nearby_places}
                dataKey={'place_name'}
                dataValue={'distance_human'}
                showList={true}
                numCols={2} />
            </Grid>
          </Grid>
          }

          <Grid id='boxOtherInfoContainer' container className="pagebreakavoid">
            <Grid item xs={12}>
              <BoxOtherInfo variableFields={variableFields} profile={profileData}/>
            </Grid>
          </Grid>

          { client && client.name && client.name.includes("Demo") &&
            <Grid id="videoSentiments" container className="pagebreakavoid">
              <Grid item xs={12}>
                <BoxVideoSentiments videoUrl={videoUrl} videoAnalysis={ videoAnalysis }/>
              </Grid>
            </Grid>
          }

          <Grid container spacing={2} className="pagebreakavoid">
            {profileData.video && (
              <Grid item lg={profileData.cv ? 5 : 12} md={12} xs={12}>
                <div className="header-table">
                  <p>{i18n.process.previewVideo}</p>
                </div>               
                <BoxVideoCandidate profileData={profileData} lg={profileData.cv ? 5 : 12} />
              </Grid>
            )}

            {profileData && showCvPreview && _get(profileData, 'cv') !== null && (
              <Grid item lg={profileData.video ? 7 : 12} md={12} xs={12} className="pagebreakavoid no-print">
                <p className="documentsInfoTitle">{i18n.process.previewCV}</p>
                <DocumentsListViewer key={slug} options={formatCV(_get(profileData, 'cv'))} embedded={true}/>
              </Grid>
            )}
          </Grid>
        </Grid>        
      
        {profileData && attachments.length ?
          <Grid spacing={4} id='documentsInfoContainer' container className="pagebreakavoid no-print">
            <p className="documentsInfoTitle">{i18n.process.documentation}</p>
            <Grid className="documentsInfoBody" item xs={12}>
              <S.Toolbar className="no-print">
                <S.ActionButton
                  disabled={!selectedDocuments || selectedDocuments.length <= 0}
                  handleOnClick={handleFileDelete}
                  title={i18n.process.deleteDocs}
                  styledType="invert"
                  color="red"
                />
                <S.ActionButton
                  disabled={!selectedDocuments || selectedDocuments.length <= 0}
                  handleOnClick={handleDownload}
                  title={i18n.process.downloadDocs}
                  styledType="invert"
                />
              </S.Toolbar>
              <DocumentsList
                check
                value={selectedDocuments}
                options={formatAttachments(attachments)}
                onChange={value => setSelectedDocuments(value)}
              />
            </Grid>
          </Grid> : null}

        {renderTabs( profileData )}

      </ProfileInfoStyled>
    )
  }

  const LogicOfRender = () => {
    //IF MULTIPLE USER SLUG (PRINT)
    if (candidatureId && candidatureId.split('-').length > 1) {
      if (!profileMultipleData || isFetchingUserList || !isFetchedTargetInboxData) {
        return <>{module && getCurrentSectionKey() && <InboxBySectionComponent/>}<SpinnerIcon/></>
      }
      const profileMultipleDataFiltered = _.filter(profileMultipleData, data => _.has(data, 'id'))
      return _.map(profileMultipleDataFiltered, (profile, key) => (
        <>
          {key > 0 &&
            <div className="pagebreak"></div>
          }
          <div
            key={key}>{renderProfile(profile.id, profile, profile.observations, showActions, showFav, showObsInput)}</div>
        </>
      ))
    } else {
      if (!profileData || isFetching || !isFetchedTargetInboxData || !isObservationsFetched || !responsibles) {
        return <>{module && getCurrentSectionKey() && <InboxBySectionComponent/>}<SpinnerIcon/></>
      }
      return renderProfile(candidatureId, profileData, observations, showActions, showFav, showObsInput)
    }
  }

  return (
    <LogicOfRender/>
  )
}

ProfileInfoQuery.defaultProps = {
  showActions: true,
  showFav: true,
  showObsInput: true
}

ProfileInfoQuery.propTypes = {
  i18n: PropTypes.object.isRequired,
  favorites: PropTypes.array,
  fetchUserList: PropTypes.func,
  profileData: PropTypes.object,
  profileMultipleData: PropTypes.array,
  match: PropTypes.object,
  isFetching: PropTypes.bool,
  isFetchingUserList: PropTypes.bool,
  deleteFavorites: PropTypes.func,
  postFavorites: PropTypes.func,
  showActions: PropTypes.bool,
  showFav: PropTypes.bool,
  showObsInput: PropTypes.bool,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onGoBack: PropTypes.func,
  citations: PropTypes.object,
}

export default ProfileInfoQuery
