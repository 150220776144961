export default { // Catalán
  generalError: 'Ha ocorregut un error',
  update: 'Actualitzar',
  showMore: 'Mostrar més +',
  showLess: 'Mostrar menys -',
  markAs: 'Marcar com',

  genericText: {},

  modalGeneric: {
    cancel: "Cancel·lar",
    send: "Enviar"
  },

  //Atoms
  avatarMatch: {
    match: 'Match'
  },

  regularButton: {
    apply: 'Aplicar'
  },

  regularSelect: {
    select: 'Seleccionar'
  },

  modalHeader: {
    planning: 'Planificació'
  },

  //Molecules
  modalDeleteUser: {
    removeUser: 'Eliminar',
    enterCandidateName: "Introdueix el nom del candidat per a confirmar l'acció",
    writeCandidateName: 'Escriu el nom del candidat',
    filterProfiles: 'Filtrar perfils',
    remove: 'Eliminar'
  },

  modalUpdateBlacklist: {
    addToBlacklist: 'Afegir a la llista negra a ',
    removeFromBlacklist: 'Eliminar de la llista negra a ',
    enterCandidateName: "Introdueix el nom del candidat per a confirmar l'acció",
    writeCandidateName: 'Escriu el nom del candidat',
    filterProfiles: 'Filtrar perfils',
    add: 'Afegir',
    remove: 'Eliminar'
  },

  modalChangeStatus: {
    changeStatus: 'Canviar estat',
    selectStatus: 'Selecciona un estat',
    selectOffer: 'Selecciona un job',
    incomplete_form: "Necessites seleccionar l'estat i el job per poder realitzar aquesta acció",
    select: 'Seleccionar',
    suitable: 'Apte',
    not_suitable: 'No apte',
    not_evaluated: "Sense avaluar",
    to_be_confirmed: 'Per confirmar',
    confirmed: 'Confirmat',
    not_interested: 'No interessat',
    reschedule: 'Reprogramar',
    hired: 'Contractat',
    booking: 'Reserva',
    not_attend: 'No assisteix',
    cited_phase2: 'Citat fase 2',
    pending_update: 'Actualització pendent',
    discarded: 'Descartat',
    descarded_phase2: 'Descartat fase 2',
    not_interested_phase2: 'No interessat fase 2',
    not_attend_phase2: 'No assisteix fase 2',
    in_process: 'En procés',
    hiredDate: "Data d'alta ",
    in_progress: 'En progrés',
    doc_required: 'Doc sol·licitada',
    doc_received: 'Doc recbuda',
  },

  modalChangeTags: {
    title: 'Canviar etiquetes',
  },

  favoritesTable: {
    date: 'Data',
    markAsRead: 'Marcar com llegit',
    changeState: 'Canviar estat',
    markFavorite: 'Marcar favorits',
    removeFavorites: 'Eliminar favorits',
    fav: 'Favorit',
    export: 'Exportar',
    profile: 'Scoring profile',
    skills: 'Scoring skills',
    matching: 'Matching total',
    status: 'Estat',
    evaluation: 'Avaluació',
    personalData: 'Dades personals'
  },

  favoritesProcessTable: {
    title: 'Processos favorits'
  },

  favoritesOnboardingTable: {
    title: 'Onboarding favorits'
  },

  processesTable: {
    title: 'Inbox processos',
    id: 'Procés',
    name: 'Nom i cognom',
    prominent: 'Destacat',
    date: 'Data i hora',
    department: 'Departament, àrea',
    category: 'Categoria',
    type: 'Tipus',
    contact: 'Email',
    tel: 'Telèfon',
    status: 'Estat',
    markFavorite: 'Marcar favorit',
    removeFavorites: 'Eliminar favorit',
    print: 'Imprimir',
    markAsRead: 'Marcar com llegit',
    unmarkAsRead: 'Marcar com no llegit',
    filterProcesses: 'Filtrar processos',
    changeStatus: 'Canviar estat',
    changeTags: 'Canviar etiquetes',
    offer: 'Enviar oferta',
    offerCandidatureState: 'Interès',
    export: 'Crear Informe',
    all: 'Tots',
    talent_acquisition: 'Candidatures seleccionades',
    on_board: 'Empleats seleccionats',
    welcome: 'Empleats seleccionats',
    employee_line: 'Empleats seleccionats',
    feedback: 'Empleats seleccionats',
    exit_interview: 'Empleats seleccionats',
    offer_sent: 'Ofertes enviades',
    statusChanged: 'Estat modificat correctament',
  },

  offerModal: {
    title: 'Crear oferta push',
    name: "Nom de l'oferta",
    name_placeholder: 'Repartidor Terrassa',
    description: 'Descripció',
    description_placeholder: "Necessitem una persona que tingui 2 anys d'experiència i amb vehicle per a la zona de Terrassa",
    date_range: "Data inici/fin de l'oferta",
    areUSure: {
      description: 'Vas a notificar a {num} persona/es sobre aquesta oferta, Estàs segur?',
      sendCompleted: 'Oferta Enviada'
    }
  },

  assignJobOfferModal: {
    title: "Assignar job",
    offer: "Job",
    offer_placeholder: "Selecciona el job que vols assignar",
    button_title: "Assignar job",
    assign: "Assignar",
    successfully_assigned: "Els candidats s'han assignat correctament al job indicat",
    areUSure: {
      description: "Vas a assignar {num} candidat/s a aquest job, estàs segur?",
      sendCompleted: "Job assignat"
    }
  },

  assignedModal: {
    title: "Assignar al procés",
    assign: "Assignar",
    subModalTitle: "Confirma l'assignació al procés?",
    subModalSubTitle: "Candidatures:",
    subModalSubDescription: "Procés seleccionat:",
    latest: "Recents",
    placeholder: "Cercar per nom del procés"
  },

  process: {
    title: 'Processos',
    category: 'Categoria',
    department: 'Departament',
    service: 'Servici',
    process: 'Procés',
    comments: 'Comentaris',
    date: 'Data',
    time: 'Hora',
    comment: 'Comentari',
    author: 'Autor',
    documentation: 'Documentació aportada',
    previewCV: 'CV del candidat',
    noPreview: 'Preview no disponible',
    downloadDocs: 'Descarregar seleccionats',
    deleteDocs: 'Eliminar seleccionats',
    uploadDocs: 'Pujar documents',
    createComment: 'Crear comentari',
    text: 'Text',
    commentName: 'Comentari',
    dayHourTransaction: 'Data i hora transacció',
    area: 'Àrea',
    centerProvince: 'Centre Provincia',
    centerType: 'Tipus de centre',
    registerData: "Data d'alta",
    noDocuments: 'No hi ha documents',
    modifiedStatus: 'Estat modificat',
    deleteSuccess: "El procés s'ha eliminat amb èxit",
    deleteModalTitle: 'Atenció!',
    deleteModalText: 'Estàs segur que vols eliminar aquest element?',
    remove: 'Eliminar',
    previewVideo: 'Vídeos'
  },

  modalAnnouncement: {
    quoteCall: 'Citar convocatòria',
    editQuoteCall: 'Convocatòria',
    date: "Data d'enviament",
    citationStatus: 'Resposta',
    citationDate: 'Data de citació',
    citationHour: 'Hora de citació',
    interviewer: 'Entrevistador',
    location: 'Ubicació',
    select: 'Seleccionar',
    announcement_details: 'Detalls',
    writeDetails: 'Especifica els detalls de la convocatòria',
    writeDetailsIntegration: "Especifica els detalls de la convocatòria (no podrà ser modificat una vegada creat l'esdeveniment)",
    body: 'Cos',
    writeBody: 'Escriu un cos del missatge',
    defaultMessages: 'Missatges predeterminats',
    sendCitation: 'Enviar Citació',
    updateCitation: 'Actualitzar',
    writeLocation: 'EscriU el lloc',
    writeInterviewer: "Escriu el nom de l'entrevistador",
    new_event: "Assumpte",
    invite_people: "Assistents opcionals",
    invite_people_placeholder: "Inclou els correus separats per ;",
    to_time: "fins a",
    online_meeting: "Reunió en línia",
    attendees: "Assistents",
    optionals: "Opcionals",
    accepted: "Acceptada",
    declined: "Declinada",
    rejected: "Declinada",
    none: "Cap",
    pending: "Sense resposta",
    tentative: "Provisional",
    organizer: "Organitzador",
    notes: "Notes",
    confirmation: {
      description_create_individual: 'Crearàs una convocatòria individual, Estàs segur?',
      description_create_group: 'Crearàs una convocatòria grupal per a {num} persones, Estàs segur?',
      description_update: 'Editaràs convocatòria per a {num} persona/es, les convocatòries originals se sobreescriuran, Estàs segur?',
      description_update_one: 'Ja hi ha una convocatòria existent, les dades se sobreescriuran, Estàs segur?',
    },
    filterBlockCitation: "Citació",
    filterBlockGeneral: "General",
    responsible: "Responsable",
  },

  modalRequireDoc: {
    requireDoc: 'Solicitar documentació',
    limitDate: 'Data límit per a aportar la documentació',
    select: 'Seleccionar',
    infoToUpload: 'Informació a aportar',
    writeInfoToUpload: 'Especifica la informació a aportar pel candidat',
    body: 'Cos',
    writeBody: 'Escriu un cos del missatge',
    defaultMessages: 'Missatges predeterminats',
    sendRequireDoc: 'Enviar',
  },

  modalMailCustom: {
    quoteCall: 'Enviar notificació',
    editQuoteCall: 'Editar convocatòria',
    subject: 'Assumpte',
    writeSubject: 'Escriu el assumpte',
    message: 'Missatge',
    writeMessage: 'Escriu el assumpte',
    body: 'Cos',
    writeBody: 'Escriu un cos del missatge',
    defaultMessages: 'Missatges predeterminats',
    send: 'Enviar Notificació',
    sendCompleted: 'Notificació enviada',
    sendsCompleted: 'Notificacions enviades'
  },

  modalFilter: {
    search: 'Cercar',
    notResults: 'No hi ha resultats',
    filterProfiles: 'Filtrar perfils',
    name: 'Nom',
    candidature: 'Posició',
    namePlaceholder: 'Nom del candidat',
    date: 'Data',
    fromDate: 'Des de',
    toDate: 'Fins a',
    profile: 'Perfil',
    skills: 'Skills',
    recruitmentSource: 'Font de reclutament',
    recruitmentPlaceholder: 'Infojobs, Linkedin...',
    from: 'Des de',
    to: 'Fins a',
    inputTextHelper: 'cerca múltiples coincidències. Ej: "Madrid;Barcelona"',
    matching: 'Matching',
    workingDayLabel: 'Jornada',
    workingDayPlaceholder: '10 hores, 20 hores...',
    select: 'Seleccionar',
    availability: 'Disponibilitat',
    availabilityPlaceholder: 'Total, parcial...',
    actualStatus: 'Estat actual',
    actualStatusPlaceholder: 'Apte, No apte...',
    position: 'Posició',
    positionPlaceholder: 'Dependent, Cambrer...',
    academicFormation: 'Formació acadèmica',
    academicFormationPlaceholder: 'Diplomat, Llicenciat...',
    experience: 'Experiència',
    experiencePlaceholder: '1 any, 2 anys...',
    lastJob: 'Últim lloc de treball',
    lastJobPlaceholder: 'Dependent, Cambrer...',
    cityOfResidence: 'Direcció',
    cityOfResidencePlaceholder: 'Madrid, Barcelona...',
    desiredLocation: 'Dades addicionals',
    desiredLocationPlaceholder: 'Bracelona, Terrassa...',
    desiredArea: 'Altres dades',
    desiredAreaPlaceholder: 'Centro, Sants...',
    saveSearch: 'Guardar cerca filtrada',
    status: 'Estat',
    planningFilters: 'Filtres de Planificació',
    candidatureFilters: 'Filtres del Candidat'
  },

  boxOtherInfo: {
    otherData: 'Altres dades',
    additionalInformation: 'Informació complementària',
    nearbyPlaces: 'Centres més propers'
  },

  "boxIAGen": {
    "title": "Avaluació IA",
    "matchTitle": "Puntuació",
    "scoresTitle": "Puntuació IA",
    "summaryTitle": "Resum:"
  },

  boxEditableInfo: {
    title: 'Informació editable',
    edit: 'Editar',
    cancel: 'Cancelar',
    accept: 'Acceptar',
    success: 'Camps actualitzats correctament',
    error: 'Error actualitzant camps',
  },

  editFieldAction: {
    title: 'Editar camp',
    empty: '(Sense valor)',
  },

  videoSentiments: {
    title: 'Anàlisi del vídeo',
    titleValores: 'Sentiments detectats',
    anger: "Ira",
    contempt: "Menyspreu",
    disgust: "Fàstic",
    fear: "Por",
    happiness: "Felicitat",
    neutral: "Neutralitat",
    sadness: "Tristesa",
    surprise: "Sorpresa"
  },
  citations: {
    citationCreated: 'Citació creada',
    citationUpdated: 'Citació actualitzada',
    citationError: 'Error en crear la citació',
    citations_sent: 'Citationes enviades',
    candidatures: 'Candidats',
  },

  citationStatus: {
    rejected: 'Rebutjada',
    accepted: 'Acceptat',
    replan: 'Replanificar',
    pending: 'Sense resposta',
    tentative: 'Provisional',
  },

  requireDoc: {
    docRequired: 'Sol·licitud de documentació enviada',
    docRequest: 'Documentació sol·licitada',
    errorDocRequire: 'Error en sol·licitar documentació',
  },
  interviewerNotes: {
    titleTab: "Notes de l'entrevista",
    edit: 'Editar notes',
    textareaPlaceholder: 'Notes',
    showOnPrint: 'Mostrar en imprimir',
    updatedAt: 'Modificat per {author}, el {date} a les {hour}',
  },

  headerProfileButtons: {
    back: 'Enrere',
    actions: 'Accions',
    previous: 'Anterior',
    following: 'Següent',
    downloadCV: 'Descarregar CV',
    downloadVideo: 'Vídeo adjunt',
    markAsRead: 'Marcar com llegit',
    quoteCall: 'Citar convocatòria',
    editQuoteCall: 'Convocatòria',
    changeState: 'Canviar estat',
    changeTags: 'Canviar etiquetes',
    markFavorite: 'Marcar favorit',
    removeFavorites: 'Eliminar favorits',
    exportFavorites: 'Exportar excel',
    remove: 'Eliminar',
    print: 'Imprimir',
    userDeleted: 'Usuari eliminat',
    files: 'Adjunts',
    mailCustom: 'Enviar notificació',
    docs: 'Adjunts',
    backToApplications: 'Tornar a candidatura',
    add_to_blacklist: 'Afegir a llista negra',
    remove_from_blacklist: 'Eliminar de llista negra',
    added_to_blacklist: 'Afegit a llista negra',
    removed_from_blacklist: 'Tret de llista negra',
    backToProcess: 'Tornar al procés',
    backToEvaluativeTests: 'Tornar a video entrevista',
    statusChanged: 'Estat modificat correctament',
    personDeleted: "Persona eliminada correctament",
    deletePersonError: "Error en eliminar la persona"
  },

  modalDownloadFiles: {
    title: 'Adjunts'
  },

  modalFilesPreview: {
    no_preview: 'Preview no disponible'
  },

  modalCreateReport: {
    createReport: 'Crear Informe',
    name: 'Nom',
    reportName: "Nom de l'informe",
    date: 'Data',
    fromDate: 'Des de',
    toDate: 'Fins a',
    profile: 'Perfil',
    skills: 'Skills',
    recruitmentSource: 'Font de reclutament',
    matching: 'Matching',
    workingDay: 'Jornada',
    availability: 'Disponibilitat',
    actualStatus: 'Estat actual',
    position: 'Posició',
    positionPlaceholder: 'Dependent, Cambrer...',
    academicFormation: 'Formació acadèmica',
    academicFormationPlaceholder: 'Diplomat, Llicenciat...',
    experience: 'Experiència',
    lastJob: 'Últim lloc de treball',
    lastJobPlaceholder: 'Dependent, Cambrer...',
    cityOfResidence: 'Direcció',
    cityOfResidencePlaceholder: 'Madrid, Barcelona...',
    desiredLocation: 'Dades adicionals',
    desiredLocationPlaceholder: 'Madrid, Majadahonda...',
    desiredArea: 'Altres dades',
    desiredAreaPlaceholder: 'Centro, Sants...',
    removeUser: 'Eliminar Usuari',
    enterCandidateName: "Introdueix el nom del candidat per a confirmar l'acció",
    writeCandidateName: 'Escriu el nom del candidat',
    filterProfiles: 'Filtrar perfils',
    from: 'De',
    to: 'A',
    include: 'Incloure',
    selectAll: 'Seleccionar tot'
  },

  modalChangeAvatar: {
    title: 'Canviar avatar',
    info: 'Arrossega una imatge o clica en la caixa per a cercar-la',
    submit: 'Acceptar',
    dropFilesHere: 'Arrossega una imatge aquí, o clica per a seleccionar arxius...',
    dropFilesActive: 'Imatge vàlida, solta per a continuar'
  },

  modalChangePass: {
    title: 'Canviar contrasenya',
    forceTitle: 'Per a continuar, canviï la contrasenya',
    info: 'Introdueix la nova contrasenya i clicla a Acceptar',
    submit: 'Acceptar',
    oldPass: 'Contrasenya actual',
    newPass: 'Nova contrasenya',
    repPass: 'Repeteix la contrasenya',
    errors: {
      passNotMatch: 'Les contrasenyes no coincideixen',
      passNotStrongEnough: "La contrasenya ha de tenir com a mínim 12 caràcters i ha d'incloure una combinació de majúscules, minúscules, números i caràcters especials."
    }
  },

  boxProfileCite: {
    currentState: 'Estat actual',
    citationDate: 'Data citació',
    hiringDate: 'Data de contractació',
    citationManager: 'Responsable citació',
    recruitmentSource: 'Font de reclutament',
    tags: 'Etiquetes',
    offers: 'Ofertes enviades',
    disponibilidad: "Disponibilitat horària:"
  },

  boxInfoHeader: {
    currentState: 'Estat actual',
    citationDate: 'Data citació',
    citationManager: 'Responsable citació'
  },

  modules: {
    title: 'Mòduls',
    module1: 'Talent Acquisition',
    module2: 'On Boarding',
    module3: 'Welcome Pack',
    module4: "Línia d'empleat",
    module5: 'Absentisme',
    module6: 'Surveys',
    module7: 'Tutor visual',
    module8: 'interview interview'
  },

  module_names: {
    talent_acquisition: "Captació de talent",
    on_board: "Onboarding",
    employee_line: "Línia d'empleat",
    feedback: "Feedback",
    exit_interview: "Exit interview",
    welcome: "Benvinguda",
    abseentism: "Absentisme",
    survey: "Enquesta",
    visual: "Visual",
  },

  myProfile: {
    title: 'El meu perfil',
    logOut: 'Tancar la sessió',
    name: 'Nom',
    surname: 'Cognom',
    email: 'Email',
    id: 'Id',
    client: 'ID client',
    phone: 'Telèfon',
    profileUpdated: 'Perfil actualitzat',
    avatarUpdated: 'Avatar actualitzat',
    updatePass: 'Actualitzar contrasenya',
    update: 'Actualitzar',
    passUpdated: 'Contrasenya actualitzada',
    passUpdatedError: "No s'ha actualitzat la contrasenya. Si us plau, comprovi que la contrasenya és correcta.",
    changeLanguage: 'Canviar idioma',
    "languages": [
      { "value": "es", "name": "Spanish" },
      { "value": "en", "name": "English" },
      { "value": "ca", "name": "Català" },
      { "value": "pt", "name": "Portugues-se" },
      { value: 'fr', name: 'Francès' }
  ],
  },

  simpleDialog: {
    defaultMessages: 'Missatges predeterminats',
    close: 'Tancar'
  },

  saveSearchesTable: {
    actions: 'Accions',
    delete: 'Eliminar',
    title: 'Títol',
    date: 'Data',
    fields: 'Camps de filtrat',
    backToSaveSearches: 'Tornar a cerques guardades'
  },

  pullConfig: {
    title: 'Pull de candidats',
    candidatures: "Candidats notificats",
  },

  pullConfigTable: {
    actions: 'Accions',
    delete: 'Eliminar',
    title: 'Títol',
    is_active: 'Actiu',
    renotify_answered: 'Renotificar',
    interval_in_days: 'Periodicidad',
    days: 'días',
    last_time_execution: 'Últim enviï',
    filters: 'Camps de filtrat',
    from: 'Des de',
    to: 'Fins a',
  },

  reportsTable: {
    actions: 'Accions',
    delete: 'Eliminar',
    title: 'Títol',
    date: 'Data',
    fields: 'Camps de filtrat'
  },

  profileStats: {
    profile: 'Profile',
    skills: 'Skills'
  },

  pda: {
    match: 'Match'
  },

  pdaKeys: {
    r: 'Risc',
    e: 'Extroversió',
    p: 'Paciència',
    n: 'Normes',
    a: 'Autocontrol',
  },

  modalSaveSearch: {
    title: 'Nom per a la cerca guardada',
    back: 'Tornar al filtrat',
    enterName: 'Escriu un nom',
    saveSearch: 'Guardar cerca filtrada'
  },

  planningTable: {
    markAsRead: 'Marcar com llegit',
    quoteCall: 'Citar convocatòria',
    editQuoteCall: 'Editar convocatòria',
    changeState: 'Canviar estat',
    markFavorite: 'Marcar favorit',
    removeFavorites: 'Eliminar favorits',
    exportFavorites: 'Exportar CSV',
    remove: 'Eliminar',
    print: 'Imprimir',
    actions: 'Accions',
    personalData: 'Dades personals',
    fav: 'Favorit',
    export: 'Exportar',
    matching: 'Matching',
    citation: 'Citació',
    interviewer: 'Entrevistador',
    atendees: 'Assistents',
    technical: 'Responsable',
    phase: 'Fase',
    statusPhase: 'Estat',
    citationStatus: 'Resposta',
    status: 'Estat',
    location: 'Localització',
    date: 'Creació Cit.',
    availability: 'Disponibilitat',
    reason: 'Motiu',
    groupal: 'Grupal',
    view: 'Veure',
  },

  peopleTable: {
    markAsRead: 'Marcar com llegit',
    markAsNotRead: 'Marcar com no llegit',
    quoteCall: 'Citar convocatòria',
    editQuoteCall: 'Editar convocatòria',
    changeState: 'Canviar estat',
    markFavorite: 'Marcar favorit',
    removeFavorites: 'Eliminar favorits',
    exportFavorites: 'Exportar CSV',
    remove: 'Eliminar',
    print: 'Imprimir',
    actions: 'Accions',
    candidature: 'Candidature',
    personalData: 'Dades personals',
    fav: 'Favorit',
    export: 'Exportar cv',
    exportCsv: 'Exportar Excel',
    requireDoc: 'Solicitar documentació',
  },

  landingOffer: {
    title: 'Hola',
    body: 'Tenim una nova <b>oferta de treball</b> que podra interesar-te: ',
    offerInfo: "Informació de l'oferta",
    interested: "M'interessa",
    notInterested: "No m'interessa",
    notExist: "No existeix l'oferta",
    expiredDescription: 'Ho sentim, aquesta oferta ja ha finalitzat. Li tindrem en compte per a futures oportunitats. <br>Moltes gràcies pel seu interès.',
    endDescription: 'Perfecte! Et confirmem que hem rebut la teva resposta. <br>Moltes gràcies per participar en el nostre procés de selecció'
  },

  landingPull: {
    title: 'Hola',
    interested: "M'interessa",
    notInterested: "No m'interessa",
    notExist: 'Pàgina no trobada',
    endDescription: 'Perfecte! Et confirmem que hem rebut la teva resposta. <br>Moltes gràcies'
  },

  landingDocRequest: {
    title: 'Adjunta la informació sol·licitada: ',
    limit_date: 'Data límit: ',
    notExist: 'Pàgina no trobada',
    expiredDescription: 'Ho sentim, el termini per a aportar la informació ja ha finalitzat. <br>Moltes gràcies pel seu interès.',
    endDescription: 'Perfecte! Et confirmem que hem rebut la informació. <br>Moltes gràcies!',
  },

  dropzone: {
    dragOrBrowse: "Arrossega fitxers o fes clic per a seleccionar-los",
    addFile: "Afegir fitxers",
    send: "Enviar",
    error: "Error",
    file_too_large: "Arxiu massa gran",
    file_max_size: "Grandària màxima d'arxiuo",
    done: "Enviat",
  },

  observationsTable: {
    today: 'Avui',
    now: 'Ara',
    title: 'Històric de canvis',
    date: 'Data',
    time: 'Hora',
    change: 'Canvi',
    author: 'Autor',
    addPlaceholder: 'Afegir aquí una nova observació',
    add: 'Afegir',
    interviewDate: 'Data entrevista',
    addObservation: 'Afegir observacions',
    deleted_user_name: 'Usuari',
    deleted_user_surname: 'esborrat',
  },

  pushOfferTable: {
    title: 'Ofertes Push',
    name: 'Nom',
    description: 'Descripció',
    userName: 'Usuari',
    createdAt: 'Data creació',
    state: 'Estat',
  },

  lastCitationsTable: {
    title: 'Últimes citacions',
    date: "Data d'enviament",
    interviewDate:'Data entrevista',
    userName: 'Organitzador',
    location: 'Ubicació',
    statusAnswer: 'Resposta',
    editCitation: 'Editar citació',
    attendees: 'Assistents',
    reason: 'Motiu',
  },

  offerCard: {
    title: 'Ofertes Push',
    interested_candidatures: ' candidats interesats',
    update_at: 'Data',
    empty: 'Actualment no hi ha cap oferta push',
    showOffers: 'Mostra només les meves ofertes push',
    delete: {
      success: "L'oferta s'ha esborrat correctament",
      error: "Ha ocorregut un error en esborrar l'oferta"
    }
  },

  //Organism
  appBar: {
    searchPeople: 'Cercar perfils',
    filterPeople: 'Filtrar candidatures',
    filterPlanning: 'Filtrar planificació',
    saveSearchs: 'Guardar cerques',
    saveSearch: 'Guardar cerca',
    cloneSearch: 'Clonar cerca',
    noResults: 'Sense Resultats',
    errorNoTitle: 'El títol no pot estar buit',
    filters: 'Filtrs',
    successSave: 'Cerca guardada'
  },

  profileInfo: {
    profile: 'Perfil',
    availableFrom: 'Disponible des de:',
    createdAt: "Data de l'entrevista",
    updatedAt: 'Última actualizació',
    pullSentAt: 'Enviament de pull',
    zone: 'Zona geogràfica',
    formation: 'Formació acadèmica',
    exp: 'Experiència',
    lastJob: 'Últim lloc de treball',
    city: 'Direcció',
    desiredLocation: 'Dades adicionals',
    desiredZone: 'Zona',
    years: 'anys',
    cv: 'CV',
    video: 'Vídeo',
    attached: 'Adjunt',
    evaluation: 'Avaluació',
    selectionStatus: 'Estat de selecció',
  },

  drawer: {
    jobOffers: 'Jobs',
    inboxPeople: 'Inbox candidatures',
    inbox: 'Inbox',
    savedSearches: 'Cerques guardades',
    planning: 'Planificació',
    favorites: 'Favorits',
    reports: 'Informes',
    statistics: 'Estadístiques',
    dashboard: 'Dashboard',
    modules: 'Mòduls',
    clients: 'Clients',
    employeeLine: 'Línia empleat',
    talent: 'Captació de Talent',
    pushOffer: 'Ofertes Push',
    pullConfig: 'Configuració Pull',
    onboarding: 'Onboarding',
    feedback: 'Suggeriments',
    exit_interview: 'Entrevista de sortida',
    integrations: 'Integracions',
    my_profile: 'El meu perfil',
    userManual: "Manual d'ús",
    phases_states: 'Fases i estats',
    evaluativeTests: 'Video entrevistes',
    user_management: 'Gestió d’usuaris',
    tag_management: 'Gestió d’etiquetes',
    keywords_rpa: 'Fonts de reclutament',
    superadministratorManual: 'Manual Administrador ATS',
    iaEvaluation: 'Avaluació IA',
    templateRequestManager: 'Gestor de petição de planta',
    validatorTemplateRequest: 'Validação de processos'
  },

  statisticsItems: {
    checkAll: 'Marcar tots',
    year: 'Any',
    month: 'Mes',
    january: 'Gener',
    february: 'Febrer',
    march: 'Març',
    april: 'Abril',
    may: 'Maig',
    june: 'Juny',
    july: 'Juliol',
    august: 'Agost',
    september: 'Setembre',
    october: 'Octubre',
    november: 'Novembre',
    december: 'Desembre',
    interviewed: 'Candidats entrevistats',
    suitables: 'Aptes vs entrevisats',
    offer_interested: 'Interesats en ofertes',
    recovered: 'Candidats actualizats',
    recruitmentSource: 'Candidats per font de reclutament',
    activity: 'Activitat de candidats entrevistats',
    startDate: 'Des de',
    endDate: 'Fins a',
    candidatures: 'Candidatures',
    name: 'Nom',
    surname: 'Cognom',
    dni: 'DNI',
    numberIdentity: 'Núm. Emprat',
    hiringDate: 'Data contractació',
    leavingDate: 'Data baixa',
    employeesWithoutPhase1: 'Persones que no han realitzat cap fase del onboarding',
    employeesHaveNotDoneExit: "Empleats que no han realitzat l'entrevista de sortida",
    candidaturesSuitables: 'Candidatures aptes',
    hiredVsSuitables: 'Contratats vs aptes',
    notInterestedVsSuitables: 'No interesats vs aptes',
    tableTitle: 'Posicions',
    positions: 'Posicions',
    onboarding_phases: 'Persones actives en cada fase del onboarding',
    phases: 'Fases',
    alta: "Actualment d'alta",
    baja: "Dat de baixa",
    zone: 'Zona',
    whatsappCounter: {
      title: "Comptadors de WhatsApp",
      bot_starter: "Invitacions entrevista",
      section: "secció",
      citations: "Citacions",
      offers: "Ofertes push",
      pulls: "Pull de candidats",
      sent: "Enviats",
      voucher: "Bo",
    },
  },

  //Page
  inboxPeople: {
    title: 'Inbox candidatures',
    filtersSearch: 'Resultats per a'
  },

  offerCandidates: {
    title: 'Oferta: ',
    offers_sent: 'Ofertes enviades',
    unique_candidatures: 'Candidats únics',
    email: 'Email',
    sms: 'SMS',
    whatsapp: 'WhatsApp'
  },

  favorites: {
    title: 'Favorits'
  },

  planning: {
    title: 'Planificació'
  },

  reports: {
    title: 'Informes',
    reportCreated: 'Informe creat',
    reportDeleted: 'informe esborrat',
    modalConfirmationTitle: 'Generar informe',
    modalConfirmationButton: 'Acceptar',
    modalConfirmationButtonCancel: 'Cancel·lar',
    reportReceiversTitle: "Introdueix els destinataris de l'informe. Aquest procés pot demorar-se uns minuts. T'enviarem un correu quan estigui llest. Pots afegir diversos destinataris separant-los per <b>;</b>'"
  },

  savedSearches: {
    title: 'Cerques guardadas'
  },

  exitInterview: {
    title: 'Entrevistes de Sortida'
  },

  statistic: {
    title: 'Estadstiques',
    monday: 'Dilluns',
    tuesday: 'Dimarts',
    wednesday: 'Dimecres',
    thursday: 'Dijous',
    friday: 'Divendres',
    saturday: 'Dissabte',
    sunday: 'Diumenge'
  },

  errorPage: {
    title: 'Uuuups! Pàgina no trobada'
  },

  login: {
    login: 'Login',
    sigin: 'Accedir',
    email: 'Correu electrònic',
    password: 'Contrasenya',
    recover: 'Recuperar',
    password_recover: 'Recuperar contrasenya',
    password_recover_success: "S'ha enviat una contrasenya de recuperació a l'email indicat",
    password_recover_error: "Hi ha hagut un error en l'enviament de l'email de recuperació",
    mandatoryField: 'Camp obligatori',
    invalidEmail: 'Email invàlid',
    noValidRole: 'Usuari invàlid',
    noValidCredentials: 'Usuari o contrasenya incorrectes'
  },

  tableLocalization: {
    pagination: {
      labelDisplayedRows: '{from}-{to} de {count}',
      labelRowsSelect: 'elements',
      labelRowsPerPage: 'Elements per pàgina',
      firstAriaLabel: 'Principi',
      firstTooltip: 'Ir al inici',
      previousAriaLabel: 'Anterior',
      previousTooltip: 'Anterior',
      nextAriaLabel: 'Següent',
      nextTooltip: 'Següent',
      lastAriaLabel: 'Final',
      lastTooltip: 'Anar al final'
    },
    body: {
      emptyDataSourceMessage: 'Sense dades'
    },
    toolbar: {
      searchTooltip: 'Cercar',
      searchPlaceholder: 'Cercar',
    }
  },

  savedSearchesItemTableLocalization: {
    pagination: {
      labelDisplayedRows: '{from}-{to} de {count}',
      labelRowsSelect: 'elements',
      labelRowsPerPage: 'Elements per pàgina',
      firstAriaLabel: 'Principi',
      firstTooltip: 'Ir al inici',
      previousAriaLabel: 'Anterior',
      previousTooltip: 'Anterior',
      nextAriaLabel: 'Següent',
      nextTooltip: 'Següent',
      lastAriaLabel: 'Final',
      lastTooltip: 'Anar al final'
    },
    body: {
      emptyDataSourceMessage: "No s'ha trobat cap registre per a aquesta cerca guardada"
    },
    toolbar: {
      searchTooltip: 'Cercar',
      searchPlaceholder: 'Cercar',
    }
  },

  errors: {
    internalServerError: "No s'ha pogut completar l'operació."
  },

  landingEmail: {
    title: 'Ha ocorregut un error',
    body: "No s'ha pogut completar l'operació",
    loading: 'Carregant',
    hi: 'Hola',
    citation_header: "Volem informar-te que, després d'avaluar el teu perfil, ens agradaria avançar amb tu a la següent fase del procés de selecció per a la posició de",
    citation_info: "Informació de la convocatòria",
    address: "Adreça:",
    contact_person: "Persona de contacte:",
    interview_date: "Data de l'entrevista:",
    interview_hour: "Hora de l'entrevista:",
    give_answer: 'Si us plau, envia\'ns la teva resposta a aquesta convocatòria seleccionant una de les següents opcions:',
    confirmed: 'Confirmo la meva assistència',
    reschedule: 'No puc assistir, necessito una altra cita',
    not_interested: "No m'interessa",
    citation_not_exist: "No existeix la citació",
  },

  landingReschedule: {
    title: 'En quina franja horària podries acudir a una entrevista?',
    noDates: 'No hi ha dates disponibles',
    successSend: "La teva sol·licitud s'ha enviat correctament",
    putAvailability: 'Introdueix disponibilitat',
    send: 'Enviar',
    reasonPlaceholder: 'Motiu'
  },

  landingNotInterested: {
    title: "Podries indicar-nos per què no t'interessa?",
    error: 'Ha ocorregut un error',
    successSend: "La teva sol·licitud s'ha enviat correctament",
    putReason: 'Introdueix el motiu',
    send: 'Enviar'
  },


  peopleTableFilters: {
    toManage: 'Per gestionar',
    inProgress: 'En procés',
    finalists: 'Finalistes',
    others: 'Altres'
  },

  noCvInfo: 'Els candidats seleccionats no tenen cv associats',

  exitInterviewStatisticsItems: {
    satisfactionSurvey: {
      exitFactors: 'Factors de sortida',
      superAgree: "Molt d'acord",
      agree: "D'acord",
      littleDisagree: "Poc d'acord",
      disagree: "Gens d'acord",
      clima1: "Bon clima en l'empresa",
      clima2: "Bon clima amb el teu equip",
      concilia1: "Permet conciliar amb la família",
      concilia2: "Concilia amb els estudis",
      desarrollo1: "Remunerat concorde al treball",
      desarrollo2: "Ofereix desenvolupament professional",
    },
    exits: "Baixes",
    exit_completed: "Finalitzades",
    recommends: "Sí, recomana",
    reason: "Motius de sortida",
  },

  processesStatisticsItems: {
    processesTransaccionalRecived: 'Processos transaccionals rebuts',
    suitables: 'Tramitats vs total',
    numberOfPolls: 'Enquestes respostes',
    averagePolls: 'NPS',
    processesTransaccionalGroup: 'Processos transaccionals per tipus',
    processesConsultivoGroup: 'Processos consultius per tipus',
    processesCollectiveAgreementGroup: 'Processos per conveni col·lectiu',
    startDate: 'Des de',
    endDate: 'Fins a',
    candidatures: 'Candidatures',
    name: 'Nom',
    processes: 'Processos',
    recived: 'Rebuts',
    total: 'Total',
    suitablesVsRecibidos: 'En procés vs total',
    tableTitle: 'Processos transaccionals'
  },
  tableFilters: {
    toManage: 'Per gestionar',
    inProgress: 'En procés',
    finalists: 'Finalises',
    others: 'Altres'
  },
  onboardingInbox: {
    title: 'Inbox Onboarding'
  },
  onboarding: {
    deleteSuccess: "L'element s'ha eliminat amb èxit",
    notesTitle: 'Notes'
  },
  status: {
    received: 'Rebut',
    canceled: 'Cancel·lat',
    in_process: 'En procé',
    pending_info: "Pendent d'info",
    processed: 'Tramitat',
    accepted: 'Acceptat',
    rejected: 'Rebutjat',
    declined: 'Rebutjat',
    none: 'Sense resposta',
    pending: 'Sense resposta',
    tentative: 'Provisional',
    postponed: 'Ajornat',
    developing: 'En desenvolupament',
    implemented: 'Implantat',
    1: 'Apte',
    2: 'No apte',
    3: 'Per confirmar',
    4: 'Confirmat',
    5: 'No interessat',
    6: 'Reprogramar',
    7: 'Contractat',
    8: 'Reserva',
    9: 'No assisteix',
    10: 'Citat fase 2',
    11: 'Descartat',
    12: 'Descartat fase 2',
    13: 'No interessat fase 2',
    14: 'No assisteix fase 2',
    15: 'En procès',
    16: 'Doc sol·licitada',
    17: 'Doc recibuda',
    18: 'Actualizació pendent',
    19: 'Citat'
  },
  evaluativeConfig: {
    'pending': "Pendent",
    'in_review': "En revisió",
    'suitable_in_other_process': "Apte en altre procés",
    'suitable': "Apte",
    'not_suitable': "Rebutjat",
    'resignation': "Baixa voluntària",
    'no_show': "No assisteix",
  },
  dialog: {
    success: 'Acceptar',
    decline: 'Cancelar',
    save: 'Guardar'
  },

  feedbackStatisticsItems: {
    Received: 'Suggeriments rebuts',
    suitables: 'Suggeriments en curs vs total',
    category: 'Suggeriments per categories',
    department: 'Suggeriments per àrees',
    centerType: 'Suggeriments per unitat',
    statesTypes: 'Suggeriments per estats',
    endDate: 'Fins a',
    name: 'Nomb',
    recived: 'Rebuts',
    total: 'Total',
  },

  onboardingStatisticsItems: {
    leaving: 'Baxes',
    hiring: 'Incorporacions',
    inprogress: 'Onboardings en progrés',
    ratio: 'Ràtio',
    phase: 'Fase',
    hot_issues_title: "Hot issues",
    hot_issues: {
      formacion_online: "Formació online",
      uniforme: "Uniforme disponible",
      epi: "EPI disponible",
      contrato: "Contracte lliurat",
      rm: "Reconeixement mèdic",
      relacion_equipo: "Mala relació amb l'equip",
      formacion_riesprof_hito3: "Formació prevenció de riscos",
      mutua_mc_mutual_hito3: "Protocol d'actuació en cas d'accidents",
      formacion_compliance_hito3: "Formació Compliance",
      reconocimiento_medico_hito3: "Reconeixement mèdic",
      welcomepack_hito3: "Welcome Pack",
      employeeapp_hito3: "Employee App",
      protocol_bajamed_hito4: "Protocol baixa metgessa",
      reunion_manager_hito5: "Reunió Feedback",
    }
  },

  offerStates: {
    unanswered: 'Sense contestar',
    interested: 'Interessat',
    not_interested: 'No interessat',
  },

  talent_acquisition: {
    inbox: {
      title: 'Inbox candidatures',
    },
    favorites: {
      title: 'Favorits'
    },
    'saved-searches': {
      title: 'Cerques guardades',
    },
    'saved-searches-item': {
      title: 'Cerques guardades',
    },
    'push-offer': {
      title: 'Ofertes Push',
    },
    'offer-candidatures': {
      title: 'Ofertes Push',
    },
    'pull-config': {
      title: 'Pull de candidats',
    },
    planning: {
      title: 'Planificació',
    },
    reports: {
      title: 'Informes',
    },
    statistics: {
      title: 'Estadístiques',
    },
  },

  on_board: {
    inbox: {
      title: '',
    }
  },

  employee_line: {
    inbox: {
      title: '',
    },
    'saved-searches': {
      title: 'Cerques guardades',
    },
    'saved-searches-item': {
      title: 'Cerques guardades',
    }
  },

  feedback: {
    inbox: {
      title: '',
    },
    title: 'Inbox Items',
    filter: 'Filtrar Items',
    date: 'Data',
    questions: 'Preguntes',
    proposal: 'Item',
    category: 'Categoria',
    email: 'Email',
    dni: 'DNI',
    phone: 'Telèfon',
  },

  exit_interview: {
    inbox: {
      title: 'Exit interview',
    },
    exit_reasons: "Motius de sortida",
    exit_factors: "Factors de sortida",
    other_data: "Altres dades",
    position: "Lloc",
    leaving_date: "Data de sortida",
    male_or_female: "Sexe",
    male: "Home",
    female: "Dona",
    category: "Categora",
    email: "Email",
    dni: "DNI",
    phone: "Tel",
    Motivo1: 'Motius de sortida',
    Motivo2: 'Submotiu de sortida',
    Motivo3: 'Observacions motiu de sortida',
    Clima1: "La relació amb el responsable ha estat",
    Clima2: "El clima de treball ha estat satisfactori",
    Concilia1: "M'han donat facilitats de canvi de torns?",
    Concilia2: "Com creus que podríem millorar?",
    Desarrollo1: "L'empresa li ha donat opcions de desenvolupament?",
    Desarrollo2: "La formació rebuda ha complert les seves expectatives?",
  },

  toast_msg: {
    observation: {
      success: 'Observació agregada correctament',
      error: 'Ha ocorregut un error',
    },
    requireDoc: {
      success: 'Sol·licitud de documentació enviada',
      error: 'Error en sol·licitar documentació',
    },
    changeStatus: {
      success: 'Estat canviat correctament',
      error: 'Ha ocorregut un error',
    }
  },

  integrations: {
    outlook: "El meu calendari de Outlook",
    google: "El meu Google Calendar",
    connect: "Connectar",
    disconnect: "Desvincular",
  },

  userManual: {
    title: "Manual d'ús",
  },

  superadministratorManual: {
    title: "Manual d'administrador ATS",
  },

  phases_states: {
    descriptionPhasesStates: "Des d'aquí podreu crear, editar o eliminar les fases i els estats de les candidatures.",
    titlePhase: "Fases",
    titleState: "Estats",
    descriptionPhase: "Les fases són cadascun dels passos del procés de selecció pels quals va passant el candidat.",
    descriptionState: "L'estat d'una fase indica si s'han fet accions en aquesta etapa del procés del candidat, facilitant la identificació del seu progrés dins aquesta fase.",
    labelPhase: "Fase",
    labelState: "Estat",
    labelCurrentState: "Estat actual",
    labelNewState: "Estat nou",
    errorOnDeleteStatus: "No es pot eliminar un estat que està en ús, te {candidatures} candidatures que haurà de moure abans a un altre estat.",
    placeholderPhase: "Introdueix el nom de la fase",
    placeholderState: "Introduïu el nom de l'estat",
    placeholderSelectPhase: "Selecciona una fase",
    titleButtonPhase: "Afegir fase",
    titleButtonState: "Afegir estat",
    assignStateToPhase: "Assignar estat a fase",
    buttonSave: "Guardar",
    buttonAllSave: "Guardar canvis",
    buttonCancel: "Cancel·lar",
    buttonMove: "Moure",
    titleFunnel: "Il·lustració del Flux",
    subtitleFunnel: "Fases i Estats del procés de selecció",
    titlePhaseFunnel: "Fases",
    buttonDelete: "Eliminar",
    selectionFunnel: "Funnel de Selecció",
    currentState: "Estat actual",
    labelColor: "Assignar un color",
    placeholderDescriptionStatus: "Introdueix un missatge",
    feedbackMessage: "Missatge de feedback",
    feedbackMessageRegistered: "<p>Agraïm sincerament el teu interès a unir-te al nostre equip, i valorem el temps i esforç que has invertit a presentar el teu perfil. </p><p><br></p><p>Volem que sàpigues que la teva candidatura ha estat registrada al nostre sistema i que estem revisant acuradament totes les aplicacions rebudes.</p><p><br></p><p>En aquests moments, estem avaluant totes les candidatures i buscant la persona ideal que s'ajusti als requisits de la posició ia la nostra cultura empresarial Aquest procés pot trigar algun temps, ja que estem compromesos a fer una selecció minuciosa.</p><p><br></p><p>Et garantim que et mantindrem al corrent sobre els passos següents en el procés de selecció tan aviat com tinguem novetats pel que fa a una oferta que s'alineï amb el teu perfil i motivació.</p><p><br></p><p> La nostra intenció és ser transparents i proporcionar-te tota la informació necessària perquè puguis seguir el desenvolupament de la teva candidatura. Moltes gràcies per la teva paciència i comprensió durant aquest procés. </p><p><br></p><p>Apreciem el teu interès a formar part del nostre equip i esperem tenir l'oportunitat de conèixer-te millor en les següents etapes del procés de selecció.</p>",
    feedbackMessageHired: "<p>Felicitats! Ens complau informar-te que has estat seleccionat per formar part del nostre equip. </p><p><br></p><p>Durant el procés de selecció, el teu exercici i habilitats van destacar, i estem convençuts que la teva experiència i contribucions seran molt valuoses per a la nostra companyia.</p><p><br></p><p>En els propers dies, ens posarem en contacte amb tu per compartir-te els detalls finals del contracte, la documentació necessària, així com per proporcionar-te informació sobre l'orientació i qualsevol preparació necessària pel teu primer dia.</p><p><br></p><p>Et donem l'enhorabona una vegada més i esperem el millor de tu en aquesta nova etapa a la teva vida professional.</p><p><br></p><p>Et donem la benvinguda a l'equip!</p>",    feedbackMessageDefaultCustom: "Estem revisant la teva candidatura, ens posarem en contacte amb tu aviat.",
    selectionStatus: "Selecciona un estat",
    questionChangeStatus: "Vols fer un canvi d'estat als candidats esmentats?",
    changeStatusCheckApplication: "Per canviar l'estat del candidat, selecciona el job i l'estat a canviar",
    changeStatus: "Canviar estat",
    modalEditPhaseTitle: "Editar fase",
    modalEditStateTitle: "Editar estat",
    modalDeletePhaseTitle: "Eliminar fase",
    modalDeleteStateTitle: "Eliminar estat",
    chipUnAssigned: "Sense assignar",
    errorOrderPhasesUpdate: "Error en actualitzar l'ordre de les fases",
    errorOrderStatusUpdate: "Error en actualitzar l'ordre dels estats",
    changesSavedSuccessfully: "Canvis desats correctament",
    phaseReceived: "Rebuts",
    phaseHired: "Contractats",
    alertCannotAddMoreEightPhases: "No es pot afegir més de 12 fases",
    alertCreatedPhase: "Fase creada correctament",
    alertPhaseExists: "Ja existeix una fase amb aquest nom",
    alertErrorCreatePhase: "Error en crear la fase",
    alertAddNamePhase: "Has d'afegir un nom a la fase",
    alertCreateStatus: "Estat creat correctament",
    alertErrorGetPhases: "Error en obtenir les fases",
    alertStatusExists: "Ja existeix un estat amb aquest nom",
    alertErrorCreateStatus: "Error en crear l'estat",
    alertErrorGetStatus: "Error en obtenir els estats",
    alertSelectPhase: "Seleccioneu una fase abans d'agregar un estat",
    alertTitleCannotDeletePhase: "No pots eliminar aquesta fase",
    messageAlertCannotDeletePhase: "Tens estats associats a aquesta fase, els has de moure a una altra fase existent o esborrar-los per eliminar aquesta fase.",
    alertThereAreNoStatusesInPhase: "No hi ha estats associats a aquesta fase",
    stateInscribed: "Inscrit",
    stateHired: "Contractat",
    updatedPhase: "Fase actualitzada correctament",
    errorUpdatedPhase: "Error en actualitzar la fase",
    updatedStatus: "Estat actualitzat correctament",
    errorUpdatedStatus: "Error en actualitzar l'estat",
    alertMessageFeedback: "S'ha d'afegir un missatge de comentari",
    alertStateNameRequired: "Debes afegir un nom a l'estat",
    questionNotified: "Vols que s'enviï un missatge alertant del canvi a la candidatura a les persones que passin a aquest estat?",
    selectNotificationMethods: "Trieu el canal pel qual voleu enviar les notificacions.",
    infoSelectNotificationMethods: "Recorda que has de tenir una plantilla dissenyada per a aquesta comunicació.",
    smsMethod: "SMS",
    emailMethod: "Email",
    whatsapMethod: "WhatsApp",
  },

  evaluativeTests: {
    title: "Video entrevistes",
    descriptionSection: "Aquí trobaràs totes les teves entrevistes de vídeo activas i inactivas.",
    titleNewTest: "Nueva video entrevista",
    titleEditTest: "Editar video entrevista",
    subtitleNewTest: "Configuració de la video entrevista",
    descriptionNewTest: "Realitza la configuració, segons les necessitats del procés de selecció",
    labelPickerStartDate: "Fetxa d'inici",
    labelPickerEndDate: "Fecha de fin",
    nameTest: "Nombre de la video entrevista",
    placeholderNameTest: "Introduce el nombre de la video entrevista",
    urlBot: "URL del bot",
    placeholderUrl: "https://...",
    buttonGenerateUrl: "URL general",
    selectionPreguntes: "Preguntas de selección",
    subtitleTest: "Realitza un filtre més complet dels candidats.",
    subtitleTest2: "Crea a menys una pregunta, l'assignatura un temps màxim de resposta i centrat en els candidats que van respondre correctament.",
    placeholderInputQuestion: "Pregunta N°1",
    buttonAddQuestion: "Añadir pregunta",
    buttonSave: "Guardar",
    buttonCancel: "Cancel·lar",
    buttonRegenerateUrl: "Regenera l'URL",
    inactive: "(desactivada)",
    noResults: "No he trobat resultats per a la data seleccionada.",
    errorFetchTests: "Ha ocorregut un error en obtenir les entrevistes de vídeo",
    buttonCreateTest: "Crea una entrevista de vídeo",
    singleQuestion: "Pregunta",
    multiplePreguntes: "Preguntas",
    successDeleteTest: "Vídeo entrevista eliminada correctament",
    successDuplicateTest: "Vídeo entrevista duplicat correctament",
    successCreateTest: "Vídeo entrevista creat correctament",
    successUpdateTest: "Vídeo entrevista actualitzat correctament",
    useSampleQuestion: "Usar pregunta d'exemple",
    activesTest: "Actives",
    inactivesTest: "Totes",
    titleBoxProfileInbox: "Vídeo entrevistes",
    statusVideoInterview: "Estado de la videoentrevista",
    infoUploadVideo: "Candidato no ha subido video",
    titleTemplate: "Plantillas de bot",
    successChangeStatus: "S'ha canviat correctament",
    errorFieldRequired: "Este camp és obligatori",
    errorFieldInvalid: "Este camp és invàlid",
    errorFieldQuestion: "Debes afegir al menys una pregunta",
    placeholderSelectTemplate: "Selecciona una plantilla",
    placeholderTimeLimit: "Tempo màx.",
    successRegenerateUrl: "Ja s'ha regenerat la url i el candidat pot accedir de nou",
    errorRegenerateUrl: "Ha ocorregut un error al regenerar la url",
    inactiveCard: "Inactiva",
    menuEdit: "Edita",
    menuAddCsv: "Importar candidats",
    menuDuplicate: "Duplicar",
    menuDelete: "Eliminar",
    placeholderQuestionOne: "Explica'ns en 30 segons el més rellevant de la teva experiència laboral i com es relaciona amb el lloc al que estàs aplicant.",
    placeholderQuestionTwo: "Com et comuniques efectivament amb diferents parts interessades (clients, companys de feina, proveïdors, etc.)?",
    placeholderQuestionThree: "Quines habilitats consideres més importants per a aquest treball?",
    placeholderQuestionFour: "Com contribueixes al treball en equip ia la col·laboració en un entorn laboral?",
    placeholderQuestionFive: "Descriu una situació en què vas superar un desafiament a la feina",
    placeholderQuestionSix: "Quines són les teves metes a mitjà/llarg termini i com aquest lloc s'hi alinea?",
    titleQuestion: "Pregunta",
    csvUpload: {
      editCta: "Importar candidats",
      modalCheckCandidates: "S'importaran %ok% candidats al fitxer.",
      modalImportCandidates: "Vols continuar? Rebran una notificació per Whatsapp/email.",
      candidatesImported: "Candidats importats correctament (%ok% / %total%)",
      candidatesNotifiedError: "Error en notificar els candidats"
    }
  },

 inboxColumns: {
     'Favoritos': 'Preferits',
     'Nombre y apellidos':'Nom i cognom',
     'País': 'País ',
     'DNI':  'DNI',
     'Última videoentrevista': 'Última vídeo entrevista',
     'Estado videoentrevista':  'Estat vídeo entrevista',
     'Fecha de envío':  "Data d'enviament",
   },

   filterFields: {
    'Nombre': 'Nom',
    'DNI': 'DNI',
    'Email':  'Email',
    'Fecha de entrevista': "Data d'entrevista",
    'Tel\u00e9fono': 'Telèfon',
    'Video entrevista': 'Vídeo entrevista',
    'Estado': 'Estat',
  },

  filterBlocks: {
    'Video entrevista': 'Vídeo entrevista',
    'Datos personales': 'Dades personals',
    'Evaluaci\u00f3n': 'Avaluació',
  },

  user_management: {
    titleSection: "Administració d'usuaris",
    searchLabel: "Cercador",
    filterProfile: "Filtrar per perfil",
    placeholderFilter: "Selecciona un perfil",
    descriptionSection: "Aquí podràs controlar i personalitzar els permisos i accessos dels administradors de l'ATS. Afegeix, modifica o elimina comptes d'usuari, assigna rols específics i garanteix una gestió eficient del sistema.",
    activeUsers: "Actius",
    inactiveUsers: "Inactius",
    createUser: "Crear usuari",
    username: "Nom",
    lastname: "Cognom",
    email: "Email",
    profile: "Perfil",
    phone: "Telèfon",
    modalTitleCreate: "Crear usuari",
    modalTitleUpdate: "Modificar usuari",
    labelShowDefaultFiltersTalent: "Configurar Filtres per defecte del mòdul de Talent",
    labelName: "Nom",
    labelLastName: "Cognom",
    labelEmail: "Email",
    labelModules: "Mòduls",
    labelProfile: "Perfil",
    labelPassword: "Contrasenya provisional",
    buttonCreateUser: "Crear usuari",
    buttonCancel: "Cancel·lar",
    buttonUpdateUser: "Actualitzar usuari",
    placeholderName: "Introdueix el nom",
    placeholderLastName: "Introdueix el cognom",
    placeholderEmail: "Introdueix l'email",
    placeholderPassword: "Introduïu la contrasenya",
    placeholderProfile: "Selecciona un perfil",
    disableUsers: "Desactivar usuaris",
    disableUser: "Desactivar usuari",
    inputEmailUser: "Introdueix el correu electrònic de l'usuari",
    confirmDeactivation: "Ens confirmes que vols desactivar l'usuari {name} {surname}?",
    confirmActivation: "Ens confirmes que vols activar l'usuari {name} {surname}?",
    buttonConfirmDisable: "Sí, desactivar usuari",
    buttonConfirmEnable: "Sí, activar usuari",
    buttonConfirmDisableUsers: "Sí, desactivar usuaris",
    buttonConfirmEnableUsers: "Sí, activar usuaris",
    atsAdminUser: "Súper Administrador",
    supervisorUser: "Supervisor",
    basicUser: "Bàsic",
    customUser: "Personalitzat",
    readOnlyUser: "Només lectura",
    createdUser: "Usuari creat correctament",
    editedUser: "Usuari modificat correctament",
    errorCreateUser: "Error en crear l'usuari",
    errorEditUser: "Error en modificar l'usuari",
    nameRequired: "Has d'afegir un nom",
    surnameRequired: "Has d'afegir un cognom",
    emailRequired: "Has d'afegir un email",
    emailInvalid: "Email invàlid",
    passwordRequired: "Has d'afegir una contrasenya",
    moduleRequired: "Has de seleccionar un mòdul",
    profileRequired: "Has de seleccionar un perfil",
    confirmDisableUsers: "Ens confirmes que vols desactivar els usuaris següents?",
    confirmEnableUsers: "Ens confirmes que vols activar els usuaris següents?",
    userDisabledSuccessfully: "Usuari desactivat correctament",
    usersDisabledSuccessfully: "Usuaris desactivats correctament",
    userEnabledSuccessfully: "Usuari activat correctament",
    usersEnabledSuccessfully: "Usuaris activats correctament",
    userDisabledError: "Error en desactivar l'usuari",
    usersDisabledError: "Error en desactivar els usuaris",
    userEnabledError: "Error en activar l'usuari",
    usersEnabledError: "Error en activar els usuaris",
    passwordRequirements: "La contrasenya ha de tenir com a mínim 12 caràcters i ha d'incloure una combinació de majúscules, minúscules, números i caràcters especials.",
    passwordInvalid: "La contrasenya és invàlida",
    passwordValid: "La contrasenya és vàlida",
    userAlreadyExists: "L'usuari ja existeix!",
    placeholderModules: "Selecciona els mòduls per a aquest usuari",
    labelAuthorizedSections: "Seccions",
    placeholderAuthorizedSections: "Selecciona les seccions per a aquest perfil",
    authorizedSectionsRequired: "Has de donar-li accés a alguna secció",
    inbox: "Inbox",
    push_offer: "Ofertes push",
    pull_config: "Configuració *pull",
    saved_searches: "Cerques guardades",
    planning: "Planificació",
    favorites: "Favorits",
    reports: "Informes",
    statistics: "Estadístiques",
    statistics_dashboard: "Dashboard estadístiques",
    rpa_config: "Fonts de reclutament",
    evaluative_tests: "Vídeo entrevistes",
    template_requestor: "Gestor de sollicitud de plantilla",
    template_validator: "Validació de processos",
    module: 'Módulo',
  },

  ia_evaluation: {
    titleSection: "Avaluació automatitzada per IAG",
    descriptionSection: "Personalitza i configura el sistema de selecció automatitzada per IAG. En aquesta primera etapa crea i edita les teves avaluacions d'acord amb els llocs configurats.",
    createEvaluation: "Crear nova avaluació",
    searchLabel: "Cercador",
    placeholderInputTerm: "Nom",
    active: "Actius",
    inactive: "Inactius",
    disable: "Desactivar avaluació",
    enable: "Activar avaluació",
    name: "Nom",
    job: "Lloc",
    family: "Familia",
    criteria: {
      experience: "Experiència",
      education_training: "Formació",
      salary_expectation: "Expectatives Sal.",
      employment_status: "Situació laboral",
      location: "Ubicació",
      mobility: "Mobilitat",
      availability: "Disponibilitat",
    },
    relevance_level: {
      relevant: "Rellevant",
      desired: "Desitjable",
      mandatory: "Descarta",
      ignore: "No avaluar",
      others: 'Informatiu'
    },
    buttonConfirmDisable: "Sí, desactivar avaluació",
    buttonConfirmEnable: "Sí, activar avaluació",
    confirmDeactivation: "Ens confirmes que desitges desactivar l'avaluació {name}?",
    confirmActivation: "Ens confirmes que desitges activar l'avaluació {name}?",
    evaluationDisabledSuccessfully: "Avaluació desactivada correctament",
    evaluationEnabledSuccessfully: "Avaluació activada correctament",
    evaluationDisabledError: "Error en desactivar l'avaluació",
    evaluationEnabledError: "Error en activar l'avaluació",
    wizard: {
      title_create: "Crear nova avaluació",
      title_edit: "Editar avaluació",
      subtitle: "Criteris d'avaluació",
      description: "Les criteris d'avaluació poden incloure aspectes com a experiència, formació, ubicació, entre altres. Aquesta selecció estableix les bases per a l'avaluació inicial dels candidats i és un pas fonamental per a garantir que el procés de selecció estigui alineat amb les característiques específiques de l'avaluació que s'està configurant.",
      name: "Nom",
      name_placeholder: "Escriu el nom del perfil",
      family: "Família de llocs",
      family_placeholder: "Selecciona una família de llocs",
      job: "Llocs",
      job_placeholder: "Selecciona un lloc",
      rpa_credentials: "Credencials de font de reclutament",
      rpa_credentials_placeholder: "Selecciona una font de reclutament",
      info_text: "Puesdes establir ajustos en l'avaluació per al lloc de:",
      info_text_values: "<strong>{job}</strong> de la família <strong>{family}</strong>.",
      tableHeader: "Tria els items als quals vols donar un major pes en l'avaluació:",
      save: "Guardar ajustos",
      cancel: "Cancellar",
      save_confirmation_title: "*Confima la {mode} de l'avaluació?",
      save_confirmation_button: "Confirmo {mode}",
      create_mode: "creació",
      edit_mode: "edició",
      successCreateEvaluation: "Avaluació creada correctament",
      errorCreateEvaluation: "Error en crear l'avaluació",
      successUpdateEvaluation: "Avaluació actualitzada correctament",
      errorUpdateEvaluation: "Error en actualitzar l'avaluació",
    },
  },
  job_offers: {
    titleSection: "Jobs",
    descriptionSection: "Què podràs veure aquí? En aquesta vista trobaràs el llistat de les teves ofertes publicades en les fonts de reclutament configurades, així com les candidatures espontànies i també els processos que creïs manualment. Accedeix a cadascuna d'elles per visualitzar els candidats i gestionar els teus processos de selecció.",
    filterJobs: "Filtrar jobs",
    createJob: "Crear job",
    name: "Job",
    createdBy: "Creador",
    keyword: "Keyword",
    not_available: 'No disponible',
    candidature_name: 'Posició',
    profile: "Posició",
    location: "Localització",
    createdAt: "Data de creació",
    endDate: "Data de finalització",
    recruitmentSource: "Font de reclutament",
    newCandidates: "Candidats nous",
    totalCandidates: "Candidats totals",
    candidatesNewVsTotal: "Candidats\n nous / totals",
    infojobs: "Infojobs",
    infojobsPriority: "Infojobs",
    epreselec: "ePreselec",
    epreselecPriority: "ePreselec",
    pandape: "Pandapé",
    pandapePriority: "Pandapé",
    turijobs: "Turijobs",
    turijobsPriority: "Turijobs",
    computrabajo: "Computrabajo",
    computrabajoPriority: "Computrabajo",
    talentclue: 'Talent Clue',
    bizneo: 'Bizneo',
    indeed: 'Indeed',
    manual: "Manual",
    spontaneous: 'Espontània',
    active_offers: "jobs actius",
    inactive_offers: "jobs inactius",
    filter_offers: "Filtrar jobs",
    offer_status: "Estat del job",
    active_label: "Actius",
    inactive_label: "Inactius",
    wizard: {
      title_create: "Crear nou job",
      title_edit: "Editar job",
      // subtitle: "Criteris d'avaluació",
      description: "En aquest modal podràs crear un job nou. Li has de donar un nom, assignar-li una ubicació, i els detalls que consideris, és clar. Un cop l'hagis creat, podràs assignar candidats des de la Safata d'Entrada a aquest job, per tenir-los agrupats en un mateix procés i gestionar cada candidatura dins d'aquest job.",
      name: "Nom",
      name_placeholder: "Introdueix el nom del job",
      description_value: "Descripció",
      description_value_placeholder: "Introdueix una descripció",
      evaluation_profile: "Flux de valoració",
      evaluation_profile_placeholder: "Selecciona un flux de valoració",
      location: "Localització",
      location_placeholder: "Introdueix la localització",
      recruitment_source: "Font de reclutament",
      recruitment_source_placeholder: "Selecciona una font de reclutament",
      created_at: "Data de creació",
      created_at_placeholder: "Selecciona la data de creació",
      end_at: "Data de finalització",
      end_at_placeholder: "Selecciona la data de finalització",
      save: "Guardar",
      save_confirmation_title: "Confirma la {mode} del job?",
      save_confirmation_button: "Confirmo {mode}",
      create_mode: "creació",
      edit_mode: "edició",
      successCreateJobOffer: "Job creat correctament",
      errorCreateJobOffer: "Error en crear el job",
      successUpdateJobOffer: "Job actualitzat correctament",
      errorUpdateJobOffer: "Error en actualitzar el job"
    },
  },
  job_offer_applications: {
    backToJobOffers: "Tornar a jobs",
    filter: "Filtrar candidats",
    is_favorite: "Favorits",
    name_and_surname: "Nom i cognoms",
    rating: "Puntuació del candidat",
    level_one: "Puntuació nivell 1",
    level_two: "Puntuació nivell 2",
    level_three: "Puntuació nivell 3",
    phase: "Fase",
    selection_status: "Estat",
    location: "Ubicació",
    created_at: "Data",
    last_job: "Últim lloc",
    ia_matching: "Puntuació IA",
    match: "Concordança",
    bot_matching_pending: "Pendent",
    formation: "Formació",
    cv: "CV"
  },
  tag_management: {
    titleSection: "Administració d'etiquetes",
    searchLabel: "Cercador",
    filterModule: "Filtrar per mòdul",
    placeholderFilter: "Selecciona un mòdul",
    descriptionSection: "Aquí podràs controlar i personalitzar les etiquetes. Afegeix, modifica o elimina etiquetes per a tenir un flux de treball eficient.",
    createTag: "Crear etiqueta",
    name: "Nom",
    description: "Descripció",
    color: "Color",
    module: "Mòdul",
    modalTitleCreate: "Crear etiqueta",
    modalTitleUpdate: "Modificar etiqueta",
    labelName: "Nom",
    labelDescription: "Descripció",
    labelColor: "Color",
    labelModule: "Mòdul",
    labelMatchingMin: "Coincidència mínima",
    labelMatchingMax: "Coincidència màxima",
    buttonCreateTag: "Crear etiqueta",
    buttonCancel: "Cancellar",
    buttonUpdateTag: "Actualitzar etiqueta",
    placeholderName: "Introdueix el nom",
    placeholderDescription: "Introdueix la descripció",
    placeholderColor: "Introdueix el color",
    placeholderModule: "Selecciona un mòdul",
    placeholderMatchingMin: "Selecciona la puntuació mínima de coincidència per a l'autoassignació",
    placeholderMatchingMax: "Selecciona la puntuació màxima de coincidència per a l'autoassignació",
    nameRequired: "Has d'afegir un nom",
    descriptionRequired: "Has d'afegir una descripció",
    colorRequired: "Has d'afegir un color",
    moduleRequired: "Has de seleccionar un mòdul",
    dateUpdated: "Data d'actualització",
    errorCreateTag: "Error en crear l'etiqueta",
    errorUpdateTag: "Error en actualitzar l'etiqueta",
    deleteTagError: "Error en eliminar l'etiqueta",
    createdTag: "Etiqueta creada correctament",
    editedTag: "Etiqueta modificada correctament",
    removeTag: "Eliminar etiqueta",
    remove_tag_confirmation: "Ens confirmes que desitges eliminar l'etiqueta {tag_name}?",
    remove_confirmed: "Sí, eliminar etiqueta",
    deleteTagSuccess: "Etiqueta eliminada correctament",
    tag_in_use: "Aquesta etiqueta està actualment assignada a un o més candidats. Tens dues opcions: Esborrar directament l'etiqueta quedant aquests candidats sense etiquetar, o assignar-los una altra de les disponibles. Què desitges fer?",
    remove_anyways: "Eliminar de totes maneres",
    labelCurrentTag: "Etiqueta actual",
    labelNewTag: "Etiqueta nova",
    moveToEmpty: "Has de seleccionar a quina etiqueta moure-ho.",
  },

  keywords_rpa: {
    titleSection: "Integracions de fonts de reclutament",
    descriptionSection: "Aquí podràs controlar i personalitzar els permisos i accessos dels administradors de la plataforma. Afegeix, modifica o elimina comptes d'usuari, assigna rols específics i garanteix una gestió eficient del sistema.",
    newIntegration: "Nova integració",
    searchLabel: "Cercador",
    filterSource: "Filtrar per font",
    placeholderFilter: "Selecciona una font",
    sourceInfojobs: "Infojobs",
    sourceEpreselec: "ePreselec",
    sourceComputrabaix: "Computrabajo",
    sourcePandape: "Pandapé",
    sourceTurijobs: "Turijobs",
    activeIntegrations: "Actives",
    inactiveIntegrations: "Inactives",
    inactiveIntegration: "Integració inactiva",
    position: "Posició",
    source: "Font de reclutament",
    user: "Usuari",
    keyword: "Keyword",
    lastExecution: "Última execució",
    connectionStatus: "Estat de connexió",
    connectionStatuses: {
      "pending": "Pendent d'executar",
      "ok": "Connexió establerta",
      "error_login": "Credencials no vàlides",
      "error_generic": "Error"
    },
    labelPosition: "Posició",
    labelSource: "Font de reclutament",
    labelAdvancedConfig: "Configuració avançada",
    labelConcatUrl: "Paràmetres de la url",
    labelUser: "Usuari",
    labelPassword: "Contrasenya",
    labelUrl: "URL",
    placeholderPosition: "Selecciona la posició",
    placeholderSource: "Selecciona una font",
    placeholderConcatUrl: "Introdueix els paràmetres de la url",
    placeholderUser: "Introdueix l'usuari",
    placeholderPassword: "Introduïu la contrasenya",
    placeholderUrl: "Nom de client",
    placeholderKeyword: "Keyword generada",
    buttonGenerateKeyword: "Generar keyword",
    modalTitleNewIntegration: "Nova integració",
    modalTitleEditIntegration: "Editar integració",
    buttonCancel: "Cancel·lar",
    buttonSaveIntegration: "Guardar integració",
    buttonUpdateIntegration: "Actualitzar integració",
    messageGenerateKeyword: "Ara per completar la integració necessitem assignar una keyword que hauràs d'incloure en totes ofertes que publiquis en aquesta font de reclutament per a aquesta posició. Si us plau, clica el botó perquè se t'indiqui aquesta keyword",
    positionRequired: "Has de seleccionar una posició",
    sourceRequired: "Has de seleccionar una font",
    usertRequired: "Has d'afegir un usuari",
    passwordRequired: "Has d'afegir una contrasenya",
    urlRequired: "Has d'afegir una URL",
    generatedKeywordRequired: "Has de generar la *keyword per a poder continuar",
    messageConfirmData: "Aquestes són les dades de la integració que acabes de donar d'alta. Són correctes?",
    messageConfirmDataEdit: "Aquestes són les dades de la integració que acabes de modificar. Són correctes?",
    buttonConfirmIntegration: "Sí, desar integració",
    buttonCancelIntegration: "No, cancel·lar",
    recoverPasswordEmail: "Recuperar contrasenya",
    buttonConfirmDisabledIntegration: "Sí, desactivar integració",
    buttonConfirmEnabledIntegration: "Sí, activar integració",
    confirmDisabledIntegration: "Ens confirmes que vols desactivar la integració {position} {provider}?",
    confirmEnabledIntegration: "Ens confirmes que vols activar la integració {position} {provider}?",
    successCreateIntegration: "Integració creada correctament",
    errorCreateIntegration: "Error en crear la integració",
    errorUpdateIntegration: "Error en actualitzar la integració",
    successUpdateIntegration: "Integració actualitzada correctament",
    integrationAlreadyExists: "La integració ja existeix!",
    successSendPasswordEmail: "Contrasenya enviada correctament",
    errorSendPasswordEmail: "Error en enviar la contrasenya",
    titlePosition: "Posició:",
    titleSource: "Font de reclutament:",
    titleConcatUrl: "Paràmetres de la url:",
    titleUser: "Usuari:",
    titleKeyword: "Keyword:",
    integrationDisabledSuccessfully: "Integració desactivada correctament",
    integrationEnabledSuccessfully: "Integració activada correctament",
    urlRequirements: "La URL ha de tenir aquest format: https://client.admin.epreselec.com/es/vacantes, substituir 'client' pel nom del teu client",
    invalidUrl: "URL invàlida",
    emailLabel: "Email",
    passwordLabel: "Contrasenya",
    verifyPasswordUserPlatformToEditeIntegration: "Per editar la integració, si us plau, verifiqueu la contrasenya de l'usuari de la plataforma.",
    verifyButton: "Verificar",
    errorPasswordIncorrect: "Contrasenya incorrecta",
    successPasswordVerified: "Contrasenya verificada correctament",
    prefixUrl: "https://",
    sufixUrl: ".admin.epreselec.com/es/vacantes",
    sourceInfojobsPriority: "Infojobs",
    sourceEpreselecPriority: "ePreselec",
    sourceComputrabajoPriority: "Computrabajo",
    sourcePandapePriority: "Pandapé",
    sourceTurijobsPriority: "Turijobs",
  },

  dashboard: {
    no_data_available: "No hi ha dades disponibles pel rang seleccionat.",
    filters: {
      division: 'Divisió',
      area: 'Àrea',
      subarea: 'Subàrea',
      center: 'Centre',
      candidature: 'Posició',
    },
    home: {
      title: "Panells d'estadístiques",
      create: "Crear nou tauler",
      editDashboard: "Editar tauler",
      saveDashboard: "Guardar panell",
      removeDashboard: "Eliminar panell",
      removeDashboardSuccess: "El panell s'ha eliminat correctament",
      removeDashboardError: "Error en eliminar panell",
      changes_not_saved: "Tens canvis en el panell. Guarda per a no perdre'ls",
      successfully_saved: "Canvis guardats correctament",
      error_saving: "Error en guardar els canvis",
      successfully_deleted: "Giny esborrat correctament",
      error_deleting: "Error en esborrar el giny",
      deleteWidgetDialogTitle: "Segur que vols eliminar aquest giny?",
      deleteCancel: "Cancellar",
      deleteConfirm: "Confirmar",
      no_dashboards_created: "No hi ha panells d'estadístiques creats al teu compte. Un cop el teu administrador creï els panells, podràs visualitzar-los des d'aquesta secció."
    },
    "weekdays": {
      "2": "Dilluns",
      "3": "Dimarts",
      "4": "Dimecres",
      "5": "Dijous",
      "6": "Divendres",
      "7": "Dissabte",
      "1": "Diumenge"
    },
    pie: {
      accepted: "Acceptades",
      not_accepted: "No acceptades",
      rejected: "Rebutjades",
      pending: "Pendents",
      replan: "replanificades",
      tentative: "Provisional",
      total: "Total",
      suitable: "Aptes",
      not_suitable: "No aptes",

      gpp_manual: 'Manual',
      gpp_auto: 'Automàtic',
      gpp_gap: 'GAP',
      gpp_replacement: 'Substitució',
      gpp_pending: 'Pendents',
      gpp_cancelled: 'Cancel·lats',
      gpp_rejected: 'Rebutjats',
      gpp_active: 'En procés',
      gpp_empty: 'Pendent preselecció manual',
      gpp_inactive: 'Expirats',
      gpp_closed: 'Tancats',
      gender_male: 'Homes',
      gender_female: 'Dones',
      gender_undefined: 'Sense determinar'
    },
    barchart: {
      hour_of_day: ":00h",
      interviews: " entrevistes",
    },
    funnel: {
      applied: "Inscrits",
      sent: "Enviats",
      loaded: "Oberts",
      interviewed: "Entrevistats",
    },
    whatsapp: {
      bot_starter: "Invitacions d'entrevista",
      citations: "Citacions",
      offers: "Ofertes push",
      pulls: "Pull de candidats"
    },
    features: {
      push_offer: "Ofertes push",
      doc_request: "Sol·licitud de documentació",
      video_analysis: "Vídeo anàlisi",
      pull_config: "*Pull de candidats",
      edit_fields: "Edició de camps",
      phases_status: "Fases i estats",
      evaluative_tests: "Tests d'avaluació",
      vacancy_request: 'Gestió de sol·licituds de personal (GPP)',
      job_offer: 'Jobs'
    },
    tooltip: {
      ats_access_count: 'La sessió d’usuari té una durada d’un dia',
      candidate_experience_cnps: "El CNPS es calcula sobre la base dels resultats de l'enquesta de satisfacció dels candidats",
      average_time_to_hire: "Estableix la mitjana de temps en dies (:value:) en la qual es contracta un candidat, indicant també el valor mínim i màxim (:max:) de dies",
      gpp_average_time_to_hire: 'Estableix la mitjana de temps en dies (:value:) en què es tanca un procés, indicant també el valor mínim i màxim (:max:) de dies'
  },
    edit: {
      title: "Edició de panell:",
      save: "Guardar panell",
      saving: "Guardant...",
      saved: "Panell guardat correctament",
      renamed: "Panell actualitzat correctament",
      removed: "Panell eliminat correctament",
      disable: "Eliminar panell",
      viewDashboard: "Visualitzar panell",
      renameDashboard: "Canviar de nom panell",
      renameConfirm: "Guardar",
      removeDialogTitle: "Segur que vols eliminar aquest panell?",
      removeConfirm: "Confirmar",
      cancel: "Cancel·lar",
      errorRemoving: "Error en eliminar panell",
      errorRenaming: "Error en canviar de nom panell",
      placeholderRename: "Nom del panell",
      findWidgets: "Cercar widgets",
      addWidgets: "Selecciona els widgets pel teu panell",
      alertHasUnsavedChanges: "Panell modificat: recorda desar per no perdre els teus canvis"
    },
    wizard: {
      title: "Creador de panells",
      new_from_scratch: "Començar des de zero",
      placeholder_custom_dashboard: "Escriu el nom del teu panell",
      create: "Crear",
      viewDashboards: "Tornar",
      error_name: "Introdueix un nom per al nou panell",
      error_creating: "Ha ocorregut un error al crear el panell",
      copy_select_preconfigured_dashboard: "Tria un dels panells d'estadístiques dissenyats des de hr bot factory:",
      copy_select_custom_dashboard: "O si ho prefereixes, crea un personalitzat amb els ginys que més t'interessin:",
      name: {
        virtual_evaluation_asistant: "Avaluació Assistents Virtuals",
        selection_pipeline: "Pipeline de Selecció",
        recruitment_source: "Fonts de Reclutament",
        candidates_comunication: "Comunicació a candidats",
        hired_services: "Serveis contractats",
        conversion: "Conversió",
        citation: "Citació",
        user_activities: "Activitat d'usuaris",
      },
      description: {
        virtual_evaluation_asistant: "Visió detallada de diverses mètriques i estadístiques clau relacionades amb la interacció i eficàcia",
        selection_pipeline: "Representació visual de totes les etapes del procés de contractació",
        recruitment_source: "Dashboard que proporciona una visió detallada de les diverses fonts de reclutament utilitzades, des de portals d'ocupació fins a xarxes socials i referències internes",
        candidates_comunication: "Ofereix una visió completa de totes les interaccions i comunicacions amb els candidats",
        hired_services: "Informació sobre serveis contractats i funcionalitats actives",
        conversion: "Detall de conversions i altes de candidats en l'ATS",
        citation: "Visió sobre citacions i percentatges de resposta i assistència de candidats",
        user_activities: "Mètriques internes sobre estadístiques i activitat d'usuaris en l'ATS",
      }
    },
    widget_groups: {
      status_phases: 'Pipeline de Selecció',
      virtual_assistant_evaluation: 'Avaluació Assistents Virtuals',
      recruitment_sources: 'Fonts de Reclutament',
      candidates_communication: 'Comunicació a candidats',
      features_enabled: 'Serveis contractats',
      conversion: 'Conversió',
      citation: 'Citació',
      user_activity: 'Activitat d\'usuaris',
      ai_generative_evaluation: 'Avaluació IA generativa',
      publish_offers: 'Publicació d\'ofertes',
      workflow: 'Workflow',
      vacant_management: 'Gestió de vacants',
      all: 'Veure tot',
    },
    widgets: {
      interviewed_candidates: 'Nº d’aplicacions rebudes',
      candidates_by_recruitment_source: 'Candidats avaluats per font de reclutament',
      candidates_per_state: 'Nº de candidats per estat',
      citations_accepted_percentage_per_position: 'Cites acceptades per lloc',
      candidates_per_region: 'Distribució geogràfica dels candidats',
      candidates_by_recruitment_source_absolute: 'Candidats avaluats per font de reclutament',
      average_time_to_hire: 'Temps mitjà de contractació',
      suitable_vs_interviewed: "Aptes vs Entrevistats",
      interested_offer_candidates: "Interessats en ofertes",
      updated_candidates: "Candidats actualitzats",
      candidates_by_week_activity: "Activitat de candidats entrevistats",
      candidatures: "Candidatures",
      whatsapp_consume_by_service: "Consum de WhatsApp per servei",
      whatsapp_consumption: "Ús de WhatsApp",
      candidates_per_phase: "Candidats per fase",
      applied_vs_hired_per_position: "Candidats inscrits vs. contractats per posició",
      interviewed_candidates_per_position: "Candidats entrevistats per posició",
      push_offers_total: "Ofertes push llançades",
      people_deleted: 'Persones esborrades',
      push_offers_candidates_notified: "Ofertes push candidats notificats",
      pull_candidates_notified: "Pull candidats notificats",
      hired_vs_applied_percentage: "Candidats contractats vs. inscrits",
      total_sms_and_whatsapp_sent: "Núm. de SMS/Whatsapp enviats",
      total_email_sent: "Núm. d'emails enviats",
      candidates_with_citation_sent: "Núm. de candidats citats a entrevista",
      average_time_between_citation_and_interview: "Temps mitjà entre enviament citació i entrevista",
      hired_per_recruitment_source: "Candidats contractats per font de reclutament",
      features_enabled_check: "Funcionalitats contractades",
      suitable_per_recruitment_source: "Aptes a entrevista per font de reclutament",
      communication_methods_enabled: "Canals de comunicació amb candidats",
      candidates_per_region_spain: "Candidats per ciutat (Espanya)",
      registered_vs_suitable_by_recruitment_source: "Conversió apte vs. entrevistat per F. de reclutament",
      suitable_vs_no_suitable: "Candidats aptes vs. no aptes",
      suitable_vs_registered: "Candidats aptes vs. inscrits",
      citations_response_pct: "Percentatge de resposta a les citacions",
      citations_accepted_pct: "Percentatge de citacions acceptades",
      pull_interested_percentage: "Ofertes pull interessats per oferta",
      push_interested_percentage: "Ofertes push interessats per oferta",
      candidate_experience_cnps: "Candidate experience satisfacció (CNPS)",
      interviews_by_hour_of_day: "Entrevistes per hora del dia",
      interviews_by_hour_of_day_pie: "Entrevistes per hora del dia (circular)",
      average_candidate_experience: "Candidate experience satisfaction (CNPS)",
      average_phase_time: "Temps mitjà per fase",
      ats_access_count: "Nombre d'accessos a l'ATS",
      ats_unique_access_count: "Usuaris amb activitat a l'ATS respecte al total",
      ats_access_count_ranking: "Rànquing d'usuaris amb més accessos",
      ats_most_browsed_sections: "Apartats més visitats",
      ats_status_changed_count: "Canvis d'estat per usuari",
      whatsapp_voucher_consumed: "Bons consumits Whatsapp",
      funnel_applied_sent_opened_interviewed_by_sms_what: "Funnel (Inscrits, enviats, oberts, entrevistats) per Whatsapp/SMS",
      applied_vs_interviewed_conversion_sms_whatsapp: "Número d'Inscrits vs. entrevistats per Whatsapp/SMS",
      funnel_applied_sent_opened_interviewed_by_email: "Funnel (Inscrits, enviats, oberts, entrevistats) per e-mail",
      applied_vs_interviewed_conversion_email: "Número d'Inscrits vs. entrevistats per e-mail",
      funnel_applied_sent_opened_interviewed_by_unique: "Funnel (Inscrits, enviats, oberts, entrevistats) únics",
      applied_vs_interviewed_conversion_unique: "Número d'Inscrits vs. entrevistats únics",
      conversion_by_candidature: "Ràtio de conversió per candidatura",
      suitable_vs_not_suitable_pie: "Aptes vs no aptes",
      conversion_by_recruitment_source: "Ràtio de conversió per font de reclutament",
      people_by_client: 'Candidats únics',
      top_churns_by_chatbot: 'Principals punts de fuga per chatbot',
      gpp_created_process: 'Processos creats',
      gpp_processes_summary: 'Situació dels processos',
      gpp_processes_by_type: 'Processos coberts automàticament i manualment',
      gpp_processes_by_reason: 'Processos sol·licitats per motiu',
      gpp_average_candidates_per_process: 'Candidats suggerits de mitjana per vacant',
      gpp_uncovered_processes_automatically: 'Processos coberts manualment',
      gpp_requests_by_user: 'Peticions per sol·licitant',
      gpp_requests_by_validator: 'Peticions per validador',
      gpp_requests_by_area: 'Peticions per àrea',
      gpp_candidates_by_gender: 'Candidats suggerits per gènere',
      gpp_time_to_hire_global: 'Temps de contractació global',
      gpp_time_to_hire_by_process: 'Temps de contractació per procés',
      gpp_lifetime_processes: 'Temps de gestió de processos',
      gpp_average_management_time_by_user: 'Temps mitjà de gestió per sol·licitant',
      hired_candidates_by_gender: "Candidats contractats per gènere",
      female_candidates_per_phase: "Candidats dones per fase",
      female_candidates_per_selection_status: "Candidats dones per estat",
      male_candidates_per_phase: "Candidats homes per fase",
      male_candidates_per_selection_status: "Candidats homes per estat",
      average_positions_per_candidate: "Mitjana de candidatures per persona",
      tableColumns: {
        phase: 'Fase',
        state: 'Estat',
        offer: 'Oferta',
        notified: 'Notificats',
        interested: 'Interessats',
        position: "Lloc",
        hired: "Contractats",
        applied: "Inscrits",
        feature: "Funcionalitat",
        enabled: "Actiu",
        candidates: "Candidats",
        recruitment_source: "Font",
        activity: "Activitat",
        interviews: "Entrevistes",
        hours: "Hora del dia",
        method: "Mètode",
        accepted: "Acceptats",
        percentage: "%",
        email: "Usuari",
        total: "Accessos",
        chatbot: "Chatbot",
        chatbot_node: "Node",
        chatbot_node_text: "Pregunta",
        sessions: "Sessions totals",
        churn: "Nombre d'abandonaments",
        churn_pct: "Percentatge d'abandonament",

        uncovered_position: 'Posició',
        uncovered_center: 'Centre',
        num_uncovered: 'Processos sense cobrir',
        num_covered_manually: 'Nombre de processos',

        gpp_solicitor: 'Sol·licitant',
        gpp_solicitor_name: 'Sol·licitant',
        gpp_cp_name: 'Centre de treball',
        gpp_cs_position: 'Posició',
        gpp_vr_name: 'Nom del procés',
        gpp_vr_closed_time: 'Dies de gestió',
        gpp_vr_avg_closed_time: 'Mitjana de gestió (dies)',
        gpp_num_requests: 'Processos creats',
        gpp_pending_pct: 'Pendents aprovació',
        gpp_cancelled_pct: 'Cancel·lats',
        gpp_rejected_pct: 'Rebutjats',
        gpp_active_pct: 'En procés',
        gpp_empty_pct: 'Pendent preselecció manual',
        gpp_inactive_pct: 'Expirats',
        gpp_closed_pct: 'Tancats',

        gpp_pending: 'Pendents aprovació',
        gpp_cancelled: 'Cancel·lats',
        gpp_rejected: 'Rebutjats',
        gpp_active: 'En procés',
        gpp_empty: 'Pendent preselecció manual',
        gpp_inactive: 'Expirats',
        gpp_closed: 'Tancats',

        gpp_validator: 'Validor',
        gpp_num_approved: 'Aprovats',
        gpp_num_rejected: 'Rebutjats',

        gpp_division: 'Divisió',
        gpp_area: 'Àrea',
        gpp_subarea: 'Subàrea',
        gpp_unit: 'Centre',
        gpp_position: 'Posició',
        time_to_hire: 'Nombre de dies per tancar el procés'
      },
    },
  },
  templateRequestManager: {
    titleSection: "Gestor de petició de plantilla",
    descriptionSection: "Crea, centralitza y organitza les peticions del centre de treball de manera àgil, ràpida i ordenada per gestionar les necessitats de selecció.",
    createProcess: "Crear procés",
    filterProcess: "Filtrar procés",
    unit: "Unitat",
    dateCreated: "Data de creació",
    dateIncorporation: "Data d'incorporació",
    position: "Lloc",
    shift: "Torn",
    typeContract: "Tipus de contracte",
    vacances: "Vacants",
    reason: 'Motiu',
    email: 'Email',
    address: 'Direcció',
    lastDisplayDateAt: 'Última visualització',
    lastCandidatureChangeStatusDateAt: 'Darrer canvi d\'estat',
    proposedCandidates: "Candidats proposats",
    interviewedCandidates: "Candidats entrevistats",
    statusProcess: "Estat del procés",
    processOpened: "Processos",
    buttonOpenedProcess: "Oberts",
    buttonClosedProcess: "Tancats",
    newProcess: "Nou procés",
    descriptionNewProcess: "Comença un nou procés. Personalitza aspectes crucials com a jornada, requisits i tipus de contracte, entre d'altres. Defineix criteris que ajudaran a identificar els candidats adequats per a aquest procés de selecció.",
    backToTemplateRequestManager: "Tornar a gestor de petició de plantilla",
    placeholderPosition: "Selecciona el lloc",
    placeholderTypeContract: "Selecciona el tipus de contracte",
    placeholderWorkplace: "Selecciona el centre de treball",
    placeholderShift: "Selecciona el torn",
    placeholderRequirementsRequired: "Indica els requisits obligatoris",
    placeholderRequirementsDesirable: 'Indica els requisits desitjables',
    placeholderObservations: 'Indica les teves observacions',
    workplace: "Centre de treball",
    requeriments: "Requisits",
    desirableRequirements: 'Requisits desitjables',
    mandatoryRequirements: 'Requisits imprescindibles',
    observations: "Observacions",
    saveProcess: "Guardar",
    numberVacances: "Núm. de vacants",
    titleSectionValidator: "Validació de processos",
    descriptionSectionValidator: "Valida els processos de selecció creats pels gestors de petició de plantilla. Revisa els detalls de cada procés i aprova o rebutja la sol·licitud.",
    statusProcessValidator: "Estat del procés",
    typeContractIndefinite: "Indefinit",
    typeContractTemporary: "Temporal",
    typeContractInterim: "Interinitat",
    workingDayFullTime: "Completa",
    workingDayPartTime: "Parcial",
    workingDayHourly: "Per hores",
    shiftMorning: "Demà",
    shiftAfternoon: "Tarda",
    shiftNight: "Nit",
    shiftWeekends: "Caps de setmana",
    vacancyStates: {
      cancelled: 'Procés anul·lat',
      rejected: 'Procés rebutjat',
      active: 'Procés aprovat',
      closed: 'Procés tancat',
      pending: 'Pendent d\'aprovació',
      empty: 'Pendent preselecció manual',
      inactive: 'Procés expirat'
    },
    vacancyReasons: {
      gap: 'GAP',
      replacement: 'Substitució',
    },
    vacancyContract: {
      1: 'Contracte indefinit',
      2: 'Contracte temporal'
    },
    workingDayLabel: "Jornada",
    placeholderWorkingDay: "Selecciona la jornada",
    workingDay: {
      full_time: "Jornada completa",
      part_time: "Jornada parcial",
      weekend: "Caps de setmana"
    },
    workShiftLabel: "Torn",
    placeholderWorkShift: "Selecciona el torn",
    workShift: {
      morning_shift: "Matí",
      swing_shift: "Tarda",
      night_shift: "Nit",
      rotating_shift: "Rotatiu",
      split_shift: "Partit",
      full_shift: "Completo",
      intensive_shift: "Intensiu",
    },
    contractHoursLabel: "Hores/setmana",
    placeholderContractHours: "Selecciona les hores per setmana",
    contractHours: {
      40: "40 hores setmanals",
      37: "37 hores setmanals",
      35: "35 hores setmanals",
      30: "30 hores setmanals",
      20: "20 hores setmanals",
      16: "16 hores setmanals",
      15: "15 hores setmanals",
      12: "12 hores setmanals",
      10: "10 hores setmanals",
      8: "8 hores setmanals"
    },
    searchMode: 'Vinculació',
    searchModes: {
      direct: 'Assignació directa',
      algorithm: 'Algorisme',
      repush: 'Rebutjar'
    },
    actions: 'Accions',
    acceptProcess: 'Acceptar procés',
    rejectProcess: 'Rebutjar procés',
    cancelProcess: 'Anul·lar procés',
    cancelProcessTooltip: 'Si no vols continuar amb el procés, anul·lar-lo farà que els candidats siguin alliberats i el tancament del mateix.',
    closeProcess: 'Finalitzar procés',
    closeProcessTooltip: 'Si el procés ha arribat a la fi aconseguint les contractacions desitjades, finalitzar el procés farà que aquest conclogui i no es pugui reobrir.',
    repushCandidatesTooltip: 'Emplenar el procés amb nous candidats trobats fins a completar el procés.',
    repushCandidates: 'Omplir candidats',
    repushCandidatesSuccess: 'La sol·licitud de cerca de nous candidats s\'ha executat correctament',
    repushCandidatesFullfilled: 'La petició de plantilla ja es troba amb el nombre màxim de candidats permesos.'
  },

  "IAGen": {
    "criterias": {
      "experience": "Experiència",
      "EXPERIENCIA": "Experiència",

      "education_training": "Estudis / Formació",
      "ESTUDIOS": "Estudis / Formació",

      "salary_expectation": "Expectatives salarials",
      "SALARIO": "Expectatives salarials",

      "employment_status": "Situació laboral",
      "SITUACION_LABORAL": "Situació laboral",

      "location": "Ubicació",
      "UBICACION": "Ubicació",

      "mobility": "Mobilitat",
      "MOVILIDAD": "Mobilitat",

      "availability": "Disponibilitat",
      "DISPONIBILIDAD": "Disponibilitat",

      "others": "Altres",

      "not_evaluated": "Sense avaluar"
    }
  }
}
