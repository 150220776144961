import React, { useEffect, useState } from 'react'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import Grid from '@material-ui/core/Grid'
import { ModalStyled } from './styled'
import Modal from '@material-ui/core/Modal'
import { CustomAlert, InputField, ModalHeaderProcess, RegularButton, RegularButtonOutline } from '../../atoms'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { useMutation } from '@tanstack/react-query'
import { patchUser, postUsers } from '../../../api/index'
import {MODULE_BY_USER_TYPE, MODULES, MODULES_ARRAY, USERS_ROLES} from '../../../config/constants'
import { CircularProgress, Tooltip } from '@material-ui/core'
import { AuthUtils } from '../../../redux/auth'
import Select from 'react-select'
import { InputStyled } from '../filter-form-query/styled'
import _, { find, isArray, isObject } from 'lodash'
import { FilterFormQuery } from '../index'
import Switch from '@material-ui/core/Switch'
import * as FiltersUtils from '../../../utils/filters'

const customStyles = {
  overflow: 'scroll'
}

const ModalCreateUser = ({ open, handleClose, i18n, isEdit, editingUser, onDataUpdate, clientSections, moduleOptions,
                           customInboxFilters, isLoadingInboxFilters, phases, status }) => {

  const STARTING_SHOW_DEFAULT_FILTERS = {
    [MODULES.ID_TALENT_ACQUISITION]: false,
    [MODULES.ID_ON_BOARDING]: false,
    [MODULES.ID_EXIT_INTERVIEW]: false,
    [MODULES.ID_EMPLOYEE_LINE]: false,
    [MODULES.ID_FEEDBACK]: false,
    [MODULES.ID_ABSENTEEISM]: false,
    [MODULES.ID_WELCOME_PACK]: false,
    [MODULES.ID_SURVEYS]: false,
    [MODULES.ID_VISUAL_TUTOR]: false,
  };
  const STARTING_TEMP_FILTERS = {
    [MODULES.ID_TALENT_ACQUISITION]: [],
    [MODULES.ID_ON_BOARDING]: [],
    [MODULES.ID_EXIT_INTERVIEW]: [],
    [MODULES.ID_EMPLOYEE_LINE]: [],
    [MODULES.ID_FEEDBACK]: [],
    [MODULES.ID_ABSENTEEISM]: [],
    [MODULES.ID_WELCOME_PACK]: [],
    [MODULES.ID_SURVEYS]: [],
    [MODULES.ID_VISUAL_TUTOR]: [],
  };

  const [nameUser, setNameUser] = useState('');
  const [surnameUser, setSurnameUser] = useState('');
  const [emailUser, setEmailUser] = useState('');
  const [passwordUser, setPasswordUser] = useState('');
  const [selectedModules, setSelectedModules] = useState(null);
  const [formattedModuleOptions, setFormattedModuleOptions] = useState([]);
  const [profileUser, setProfileUser] = useState('');
  const [authorizedSections, setAuthorizedSections] = useState(null);
  const [authorizedSectionsOptions, setAuthorizedSectionsOptions] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState({ message: '', severity: '' });
  const [isOpen, setIsOpen] = useState(false);
  const [isDisableButton, setIsDisableButton] = useState(false);
  const [isCustomProfile, setIsCustomProfile] = useState(false);
  const [showDefaultFilters, setShowDefaultFilters] = useState(STARTING_SHOW_DEFAULT_FILTERS);
  const [tempFilters, setTempFilters] = useState(STARTING_TEMP_FILTERS);

  useEffect(() => {
      if (editingUser) {
        if (editingUser.hasOwnProperty('user_authorized_sections')) {
          let currentOptions = null;
          if (editingUser.user_authorized_sections.length > 0) {
            currentOptions = editingUser.user_authorized_sections.map(item => {
              return {value: item.section, label: i18n.user_management[item.section]};
            });
          }
          setAuthorizedSections(currentOptions);
        }

        MODULES_ARRAY.map(moduleKey => {
          let userType = find(MODULE_BY_USER_TYPE, {module: moduleKey});
          userType = userType && userType.type ? userType.type : null;
          if (editingUser.hasOwnProperty(userType) && editingUser[userType] && editingUser[userType].hasOwnProperty('filters_default') && editingUser[userType].filters_default) {
            let filters = JSON.parse(editingUser[userType].filters_default);
            if (filters) {
              filters = FiltersUtils.parseFiltersFromApiToFrontFormatNoRedux(filters, customInboxFilters[moduleKey], {phases, status});
              setTempFilters(prevState => {
                return {...prevState, [moduleKey]: filters}
              });
              setShowDefaultFilters(prevState => {
                return {...prevState, [moduleKey]: true}
              });
            }
          } else {
            setTempFilters(prevState => {return {...prevState, [moduleKey]: null}});
            setShowDefaultFilters(prevState => {return {...prevState, [moduleKey]: false}});
          }
        })

        if (editingUser.hasOwnProperty('module')) {
          let currentModules = null;
          if (editingUser.module.length > 0) {
            currentModules = editingUser.module.map(module => {
              return {value: module, label: i18n.module_names[module]};
            });
            if (formattedModuleOptions && formattedModuleOptions.length < currentModules.length) {
              setFormattedModuleOptions(currentModules);
            }
            setSelectedModules(currentModules);
          }
        }
    } else {
      setShowDefaultFilters(STARTING_SHOW_DEFAULT_FILTERS);
      setTempFilters(STARTING_TEMP_FILTERS);
    }
  }, [editingUser]);

  useEffect(() => {
    if (open) {
      setIsOpen(true);
      resetModalValues();
    }
  }, [open]);

  useEffect(() => {
    if (alert.message) {
      setShowAlert(true);
      const time = alert.time || 3000;
      if(time > 0) {
        const timer = setTimeout(() => {
          setShowAlert(false);
          setAlert({ message: '', severity: '' });
        }, time);
        return () => clearTimeout(timer);
      }
    }
  }, [alert]);

  useEffect(() => {
    if (clientSections) {
      let sections = clientSections.map((section) => {
        return {value: section, label: i18n.user_management[section]};
      });
      setAuthorizedSectionsOptions(sections);
    }
  }, [clientSections]);

  useEffect(() => {
    if (moduleOptions && moduleOptions.length > 0) {
      setFormattedModuleOptions(moduleOptions.map(row => ({value: row.id, label: row.name})));
    }
  }, [moduleOptions]);

  useEffect(() => {
    if (isObject(profileUser) && [USERS_ROLES.BASIC_USER, USERS_ROLES.SUPERVISOR_USER].includes(profileUser.id)) {
      setIsCustomProfile(true);
    } else {
      setIsCustomProfile(false);
      setAuthorizedSections([]);
    }
  }, [profileUser]);

  const handleChangeModules = (value) => {
    setSelectedModules(value);
  }
  const handleChangeProfile = (value) => {
    setProfileUser(value);
  }
  const handleAuthorizedSectionsChange = (value) => {
    setAuthorizedSections(value)
  }

  const options = [
    { id: USERS_ROLES.ATS_ADMIN_USER, name: i18n.user_management.atsAdminUser },
    { id: USERS_ROLES.BASIC_USER, name: i18n.user_management.customUser },
    { id: USERS_ROLES.READ_ONLY_USER, name: i18n.user_management.readOnlyUser },
  ];

  const createUser = async (data) => {
    try {
      return await postUsers(data);
    } catch (error) {
      console.error("Error while creating/updating user:", error);
      setIsDisableButton(false);

      const errors = (error.msg.errors || []).map(error => i18n.user_management.errors[error] || error)
      setAlert({ message: errors.length > 0 ? errors : i18n.user_management.errorCreateUser, severity: 'error', time: -1 });
      throw error;
    }
  }

  const updateUser = async (data) => {
    try {
      return await patchUser(data.id, data);
    } catch (error) {
      setIsDisableButton(false);
      setAlert({ message: i18n.user_management.errorUpdateUser, severity: 'error' });
      throw error;
    }
  }

  const mutationCreateUser = useMutation(createUser);
  const mutationUpdateUser = useMutation(updateUser);

  const isValidPassword = (password) => {
    let validatePass = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$€%^&*(),.?\":{}|<>_-])(?=.{12,})");
    return password.match(validatePass);
};

  const handleCreateUser = async (e) => {
    e.preventDefault();

    if (nameUser.trim() === '') {
      setAlert({ message: i18n.user_management.nameRequired, severity: 'info' });
      return;
    }

    if (surnameUser.trim() === '') {
      setAlert({ message: i18n.user_management.surnameRequired, severity: 'info' });
      return;
    }

    if (emailUser.trim() === '' || !AuthUtils.isValidEmail(emailUser)) {
      setAlert({
        message:
          emailUser.trim() === ''
            ? i18n.user_management.emailRequired
            : i18n.user_management.emailInvalid,
        severity: 'info',
      });
      return;
    }

    if (!isEdit && (passwordUser.trim() === '' || !isValidPassword(passwordUser))) {
      setAlert({ message: i18n.user_management.passwordInvalid, severity: 'info' });
      return;
    }

    if (!profileUser || (typeof profileUser === 'object' && Object.keys(profileUser).length === 0)) {
      setAlert({ message: i18n.user_management.profileRequired, severity: 'info' });
      return;
    }

    if (formattedModuleOptions && formattedModuleOptions.length > 0
    && (!selectedModules || (typeof selectedModules === 'object' && Object.keys(profileUser).length === 0))) {
      setAlert({ message: i18n.user_management.moduleRequired, severity: 'info' });
      return;
    }

    if (isCustomProfile && (!authorizedSections || (isArray(authorizedSections) && authorizedSections.length === 0))) {
      setAlert({ message: i18n.user_management.authorizedSectionsRequired, severity: 'info' });
      return;
    }

    const profileUserId = profileUser.id;

    try {
      let data;
      let sections = [];
      if (isCustomProfile && isArray(authorizedSections) && authorizedSections.length > 0) {
        sections = authorizedSections.map(section => section.value);
      }
      let modules = [];
      if (isArray(selectedModules) && selectedModules.length > 0) {
        modules = selectedModules.map(module => module.value);
      }

      let defaultFilters = {};
      if (isObject(tempFilters) && Object.keys(tempFilters).length > 0) {
        MODULES_ARRAY.map(moduleKey => {
          if (isObject(tempFilters[moduleKey]) && Object.keys(tempFilters[moduleKey]).length > 0) {
            if (showDefaultFilters[moduleKey]) {
              defaultFilters[moduleKey] = FiltersUtils.parseFiltersFromFrontToApiFormat(tempFilters[moduleKey]);
              defaultFilters[moduleKey] = defaultFilters[moduleKey] ? JSON.stringify(defaultFilters[moduleKey]) : ''
            } else {
              defaultFilters[moduleKey] = null;
            }
          }
        });
      }

      if (editingUser?.id) {
        data = {
          name: nameUser,
          surname: surnameUser,
          email: emailUser,
          modules: modules,
          role: profileUserId,
          user_authorized_sections: sections,
          [`${MODULES.ID_TALENT_ACQUISITION}_filters_default`]: defaultFilters[MODULES.ID_TALENT_ACQUISITION],
          [`${MODULES.ID_ON_BOARDING}_filters_default`]: defaultFilters[MODULES.ID_ON_BOARDING],
          [`${MODULES.ID_EMPLOYEE_LINE}_filters_default`]: defaultFilters[MODULES.ID_EMPLOYEE_LINE],
          [`${MODULES.ID_EXIT_INTERVIEW}_filters_default`]: defaultFilters[MODULES.ID_EXIT_INTERVIEW],
          [`${MODULES.ID_FEEDBACK}_filters_default`]: defaultFilters[MODULES.ID_FEEDBACK],
          [`${MODULES.ID_ABSENTEEISM}_filters_default`]: defaultFilters[MODULES.ID_ABSENTEEISM],
          [`${MODULES.ID_SURVEYS}_filters_default`]: defaultFilters[MODULES.ID_SURVEYS],
          [`${MODULES.ID_WELCOME_PACK}_filters_default`]: defaultFilters[MODULES.ID_WELCOME_PACK],
          [`${MODULES.ID_VISUAL_TUTOR}_filters_default`]: defaultFilters[MODULES.ID_VISUAL_TUTOR],
        };
        setIsDisableButton(true);

        await mutationUpdateUser.mutateAsync({
          id: editingUser?.id,
          ...data,
        });

        setAlert({ message: i18n.user_management.editedUser, severity: 'success' });
        setShowAlert(true);
        onDataUpdate();

      } else {
        data = {
          name: nameUser,
          surname: surnameUser,
          email: emailUser,
          password: passwordUser,
          modules: modules,
          role: profileUserId,
          is_active: true,
          user_authorized_sections: sections,
          [`${MODULES.ID_TALENT_ACQUISITION}_filters_default`]: defaultFilters[MODULES.ID_TALENT_ACQUISITION],
          [`${MODULES.ID_ON_BOARDING}_filters_default`]: defaultFilters[MODULES.ID_ON_BOARDING],
          [`${MODULES.ID_EMPLOYEE_LINE}_filters_default`]: defaultFilters[MODULES.ID_EMPLOYEE_LINE],
          [`${MODULES.ID_EXIT_INTERVIEW}_filters_default`]: defaultFilters[MODULES.ID_EXIT_INTERVIEW],
          [`${MODULES.ID_FEEDBACK}_filters_default`]: defaultFilters[MODULES.ID_FEEDBACK],
          [`${MODULES.ID_ABSENTEEISM}_filters_default`]: defaultFilters[MODULES.ID_ABSENTEEISM],
          [`${MODULES.ID_SURVEYS}_filters_default`]: defaultFilters[MODULES.ID_SURVEYS],
          [`${MODULES.ID_WELCOME_PACK}_filters_default`]: defaultFilters[MODULES.ID_WELCOME_PACK],
          [`${MODULES.ID_VISUAL_TUTOR}_filters_default`]: defaultFilters[MODULES.ID_VISUAL_TUTOR],
        };
        setIsDisableButton(true);

        await mutationCreateUser.mutateAsync(data);
        setAlert({ message: i18n.user_management.createdUser, severity: 'success' });
        setShowAlert(true);
        onDataUpdate();

      }
      setTimeout(() => {
        handleClose();
      }, 1000);
    } catch (error) {
      console.error("Error while creating/updating user:", error);
    }
  };

  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;
    if (fieldName === 'name') {
      setNameUser(value);
    }

    if (fieldName === 'surname') {
      setSurnameUser(value);
    }

    if (fieldName === 'email') {
      setEmailUser(value);
    }

    if (fieldName === 'password') {
      setPasswordUser(value);
      const passwordValid = isValidPassword(value);
      setAlert({ message: passwordValid ? i18n.user_management.passwordValid : i18n.user_management.passwordInvalid, severity: passwordValid ? 'info' : 'error' });
    }
  }

  useEffect(() => {
    if (isEdit && editingUser) {
      setNameUser(editingUser.name || '');
      setSurnameUser(editingUser.surname || '');
      setEmailUser(editingUser.email || '');
      const profileOption = options.find(option => {
        if ([USERS_ROLES.BASIC_USER, USERS_ROLES.SUPERVISOR_USER].includes(editingUser.role)) {
          return [USERS_ROLES.BASIC_USER, USERS_ROLES.SUPERVISOR_USER].includes(option.id);
        }
        return option.id === editingUser.role
      });
      setProfileUser(profileOption || '');
      setIsDisableButton(false);
    }
  }, [isEdit, editingUser]);

  const resetModalValues = () => {
    if (isOpen && !isEdit) {
      setNameUser('');
      setSurnameUser('');
      setEmailUser('');
      setPasswordUser('');
      setProfileUser('');
      setAuthorizedSections(null);
      setSelectedModules(null);
      setIsDisableButton(false);
    }
  }

  const updateTempFilters = (arrObjSelected, name, targetModule = null) => {
    setTempFilters(tempFilters => {
      const resultTempFilters = {...tempFilters[targetModule]};
      if ( !arrObjSelected || _.isEmpty(arrObjSelected) || (arrObjSelected.length === 1 && (
        _.get(arrObjSelected, '[0].value', null) === null
        || !_.get(arrObjSelected, '[0].value', null)
        || (arrObjSelected[0].value.hasOwnProperty('from') && !_.get(arrObjSelected, '[0].value.from', null) && !_.get(arrObjSelected, '[0].value.to', null))
      ))) {
        _.unset(resultTempFilters, name);
      } else {
        _.assign(resultTempFilters, { [name]: arrObjSelected });
      }

      return {...tempFilters, [targetModule]: resultTempFilters};
    })
  }

  return (
    <>
      <Modal
        style={customStyles}
        className="modal-wrapper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={() => {
          handleClose();
          resetModalValues();
        }}
      >
        <ModalStyled>
          <ModalHeaderProcess title={isEdit ? i18n.user_management.modalTitleUpdate : i18n.user_management.modalTitleCreate} closeClick={() => handleClose()} hasCloseIcon={true} />
          <div className="modal-body">
            <form>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12}>
                  <label>{i18n.user_management.labelName}</label>
                  <InputField placeholder={i18n.user_management.placeholderName}
                    value={nameUser}
                    onChange={(e) => handleInputChange(e, "name")} />
                  <label>{i18n.user_management.labelLastName}</label>
                  <InputField placeholder={i18n.user_management.placeholderLastName}
                    value={surnameUser}
                    onChange={(e) => handleInputChange(e, "surname")}
                  />
                  <label>{i18n.user_management.labelEmail}</label>
                  <InputField placeholder={i18n.user_management.placeholderEmail}
                    value={emailUser}
                    onChange={(e) => handleInputChange(e, "email")}
                  />

                  {!isEdit ? (
                    <>
                      <Tooltip title={i18n.user_management.passwordRequirements} placement="right" arrow>
                        <label>{i18n.user_management.labelPassword}</label>
                      </Tooltip>

                      <InputField
                        placeholder={i18n.user_management.placeholderPassword}
                        value={passwordUser}
                        onChange={(e) => handleInputChange(e, "password")}
                      />
                    </>
                  ) : null}

                </Grid>
                <Grid item xs={12} sm={12}>
                  <label>{i18n.user_management.labelProfile}</label>
                  <Autocomplete
                    className='autocomplete-custom'
                    value={profileUser}
                    options={options}
                    onChange={(event, value) => handleChangeProfile(value)}
                    getOptionLabel={(option) => option.name}
                    defaultValue={[]}
                    renderInput={(params) => (
                      <TextField {...params} placeholder={i18n.user_management.placeholderProfile} variant="outlined" value={profileUser ? profileUser.name : ''} />
                    )}
                    sx={{ width: '500px' }}
                  />
                </Grid>
                {isCustomProfile && <Grid item xs={12} sm={12}>
                  <label>{i18n.user_management.labelAuthorizedSections}</label>
                  <InputStyled>
                    <Select
                      defaultValue={[]}
                      value={authorizedSections}
                      isMulti={true}
                      options={authorizedSectionsOptions}
                      isSearchable={true}
                      isClearable={true}
                      placeholder={i18n.user_management.placeholderAuthorizedSections}
                      onChange={e => handleAuthorizedSectionsChange(e)}
                      styles={customStyles}
                    />
                  </InputStyled>
                </Grid>}
              </Grid>
              {moduleOptions && moduleOptions.length > 0 && <Grid item xs={12} sm={12}>
                <label>{i18n.user_management.labelModules}</label>
                <InputStyled>
                  <Select
                    defaultValue={[]}
                    value={selectedModules}
                    isMulti={true}
                    options={formattedModuleOptions}
                    isSearchable={true}
                    isClearable={true}
                    placeholder={i18n.user_management.placeholderModules}
                    onChange={e => handleChangeModules(e)}
                    styles={customStyles}
                  />
                </InputStyled>
              </Grid>}

              {MODULES_ARRAY.map((moduleKey, index) => {
                if (moduleOptions && find(moduleOptions, {id: moduleKey})
                && (!isEdit || editingUser && (editingUser.technical_selection_user || editingUser.user_on_boarding || editingUser.rrhh_user || editingUser.user_exit_interview || editingUser.user_feedback))) {
                  return (
                    <Grid item xs={12} sm={12} key={index}>
                      <label>{i18n.user_management.labelShowDefaultFilters.replace('%module%', i18n.module_names[moduleKey])}</label>
                      <Switch
                        style={{ display: "block" }}
                        checked={showDefaultFilters[moduleKey]}
                        onChange={(e) => {
                          setShowDefaultFilters(prevState => {return {...prevState, [moduleKey]: e.target.checked}});
                        }}
                        name="defaultFilters"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    {showDefaultFilters[moduleKey] && !isLoadingInboxFilters && customInboxFilters && customInboxFilters[moduleKey] &&
                      <Grid item xs={12} sm={12}>
                        <FilterFormQuery module={moduleKey}
                        filters={customInboxFilters[moduleKey]}
                        filtersValues={tempFilters[moduleKey]}
                        updateFiltersValues={updateTempFilters}
                        targetModule={moduleKey}
                        />
                      </Grid>}
                    </Grid>
                  )
                }
              })}

              <div className="button-wrapper">
                {showAlert && <CustomAlert severity={alert.severity} message={alert.message} />}
                {mutationCreateUser.isLoading && <CircularProgress />}
                {mutationUpdateUser.isLoading && <CircularProgress />}
                <RegularButtonOutline title={i18n.user_management.buttonCancel} handleOnClick={handleClose} />
                <RegularButton className="button-custom" title={isEdit ? i18n.user_management.buttonUpdateUser : i18n.user_management.buttonCreateUser} onClick={handleCreateUser} disabled={isDisableButton} />
              </div>
            </form>
          </div>
        </ModalStyled>
      </Modal>
    </>
  )
}

const enharce = compose(withI18n)

export default enharce(ModalCreateUser)
